import {Component, OnDestroy, OnInit} from '@angular/core';
import {Project} from '../../_models/project';
import {Proposition} from '../../_models/proposition';
import {Map} from '../../_models/map';
import {AbilityService} from '../../_services/ability.service';
import {MapService} from '../../_services/map.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MapArea} from '../../_models/map-area';

@AutoUnsubscribe()
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
  project: Project;
  propositions = [];
  currentProposition: Proposition;
  mapIsLoaded = false;
  mapUploaded = true;

  constructor(public abilitySvc: AbilityService,
              private mapSvc: MapService) {
  }

  ngOnInit() {
    this.abilitySvc.project.subscribe((p) => {
      this.project = p;
      this.mapSvc.get(p.id).refetch().then((e: any) => {
        this.project.fromQuery(e.data.project);
        this.currentProposition = p.propositions[0];
        this.mapIsLoaded = true;
        this.setPropositions();
      });
    }).unsubscribe();
  }

  setPropositions() {
    this.propositions = this.project.propositions.map((p) => {
      return {proposition: p, display: !!p.map};
    });
  }


  ngOnDestroy(): void {
  }

  createMap(data: any) {
    this.mapUploaded = false;
    this.mapSvc.create(this.currentProposition.id, data.file).subscribe((v: any) => {
      this.currentProposition.map = new Map().fromQuery(v.data.createMap);
      this.abilitySvc.resolveProject();
      this.setPropositions();
      this.mapUploaded = true;
    });
  }

  updateMap(proposition: Proposition, map: Map) {
    proposition.map = map;
    this.abilitySvc.resolveProject();
  }

  deleteMapArea(mapArea: MapArea, map: Map) {
    map.mapAreas.splice(map.mapAreas.indexOf(mapArea), 1);
    this.abilitySvc.resolveProject();
  }

  scrollToMapArea(mapArea) {
    (document.getElementById('map-area-' + mapArea.id) as HTMLInputElement).scrollIntoView({behavior: 'smooth'});
  }

  deleteMap(map: Map) {
    this.currentProposition.map = null;
  }
}
