import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Organization} from '../../_models/organization';
import {ProjectService} from '../../_services/project.service';
import {FormBuilder, Validators} from '@angular/forms';
import {Project} from '../../_models/project';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss']
})
export class EditProjectComponent implements OnInit {
  projectForm;
  project: Project;

  constructor(public dialogRef: MatDialogRef<EditProjectComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: Project,
              private projectSvc: ProjectService,
              private formBuilder: FormBuilder) {
    this.project = data;
  }

  ngOnInit(): void {
    console.log(this.project);
    this.projectForm = this.formBuilder.group({
      id: [this.project.id],
      name: [this.project.name, Validators.required],
      logisticianPercentage: [this.project.logisticianPercentage, Validators.required],
      showConcept: [this.project.showConcept],
      showMaps: [this.project.showMaps],
      showProducts: [this.project.showProducts],
      address: this.formBuilder.group({
        address_line_1: [this.project.address.addressLine1],
        address_line_2: [this.project.address.addressLine2],
        city: [this.project.address.city],
        postalCode: [this.project.address.postalCode],
      })
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.projectForm.valid) {
      this.projectSvc.update(this.projectForm.value).subscribe((e: any) => {
        this.dialogRef.close({project: new Project().fromQuery(e.data.createProject)});
      });
    }
  }

}
