import {Injectable} from '@angular/core';
import gql from 'graphql-tag';
import {Apollo} from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class AmbienceService {

  constructor(private apollo: Apollo) {
  }

  create(propositionId, name) {
    return this.apollo.mutate({
      mutation: gql`
        mutation createAmbience ($propositionId: ID!, $name: String!) {
            createAmbience (propositionId: $propositionId, name: $name) {
              id
              name
              moodboardPath
            }
        }
      `,
      variables: {
        propositionId,
        name
      }
    });
  }

  get(id) {
    return this.apollo
      .watchQuery({
        query: gql`
        query ambience ($id: ID!) {
            ambience (id: $id) {
                id
                name
                moodboardPath
                comment
                clusters {
                  id
                  name
                  position
                  suggestion
                  product {
                    id
                  }
                  products {
                    id
                    label
                    quantity
                    logisticianPrice
                    logisticianPercentage
                    brand
                    bapId
                    suggestion
                    sellerLabel
                    sellerReference
                    url
                    width
                    height
                    depth
                    price
                    status
                    images
                    dimensions
                    usersVotes
                    category
                    position
                  }
                }
            }
        }
        `,
        variables: {
          id
        }
      });
  }

  importCart(ambience, cartId) {
    return this.apollo.mutate({
      mutation: gql`
        mutation importCartAmbience ($id: ID!, $cartId: ID!) {
            importCartAmbience (id: $id, cartId: $cartId) {
                id
                clusters {
                  id
                  name
                  position
                  suggestion
                  product {
                    id
                  }
                  products {
                    id
                    label
                    quantity
                    logisticianPrice
                    logisticianPercentage
                    brand
                    sellerLabel
                    sellerReference
                    url
                    width
                    bapId
                    height
                    depth
                    price
                    status
                    images
                    dimensions
                    usersVotes
                    category
                    position
                  }
                }
            }
        }
      `,
      variables: {
        id: ambience.id,
        cartId
      }
    });
  }

  importProduct(ambience, productId) {
    return this.apollo.mutate({
      mutation: gql`
        mutation importProductAmbience ($id: ID!, $productId: ID!) {
            importProductAmbience (id: $id, productId: $productId) {
                id
                clusters {
                  id
                  name
                  position
                  suggestion
                  product {
                    id
                  }
                  products {
                    id
                    label
                    quantity
                    logisticianPrice
                    logisticianPercentage
                    brand
                    bapId
                    sellerLabel
                    sellerReference
                    url
                    width
                    height
                    depth
                    price
                    status
                    images
                    dimensions
                    usersVotes
                    category
                    position
                  }
                }
            }
        }
      `,
      variables: {
        id: ambience.id,
        productId
      }
    });
  }


  update(ambience, image?, moodboard?, comment?, name?) {
    return this.apollo.mutate({
      mutation: gql`
        mutation updateAmbience ($id: ID!, $file: String, $name: String, $moodboard: String, $comment: String) {
            updateAmbience (id: $id, file: $file, name: $name, moodboard: $moodboard, comment: $comment) {
              id
              name
              moodboardPath
              comment
            }
        }
      `,
      variables: {
        id: ambience.id,
        file: image,
        moodboard,
        comment,
        name
      }
    });
  }

  delete(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation destroyAmbience ($id: ID!) {
            destroyAmbience (id: $id) {
              id
            }
        }
      `,
      variables: {id}
    });
  }
}
