import {AfterContentChecked, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Ambience} from '../../../_models/ambience';
import {AmbienceService} from '../../../_services/ambience.service';
import {AmbienceDialogComponent} from '../../ambience-dialog/ambience-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AbilityService} from '../../../_services/ability.service';
import {ConfirmationDialogComponent} from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import {Proposition} from '../../../_models/proposition';

@Component({
  selector: 'app-ambience',
  templateUrl: './ambience.component.html',
  styleUrls: ['./ambience.component.scss']
})
export class AmbienceComponent implements AfterContentChecked {
  @Input() ambience: Ambience;
  @Output() destroy: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() duplicate: EventEmitter<Proposition> = new EventEmitter<Proposition>();

  isAmbienceLoaded = false;
  private loaded = false;

  constructor(private dialog: MatDialog,
              private ambienceSvc: AmbienceService,
              public abilitySvc: AbilityService) {
  }

  ngAfterContentChecked() {
    this.load();
  }

  load() {
    if (this.loaded) {
      return;
    }
    this.loaded = true;
    this.loadFromSvc();
  }

  show() {
    const dialogRef = this.dialog.open(AmbienceDialogComponent, {
        width: '90vw',
        height: '90vh',
        data: {
          ambience: this.ambience
        }
      }
    );
    dialogRef.afterClosed().subscribe(() => {
      this.isAmbienceLoaded = false;
      this.loadFromSvc();
      this.abilitySvc.resolveProject();
    });
  }

  delete() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Voulez-vous vraiment supprimer cette ambience ?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ambienceSvc.delete(this.ambience.id).subscribe(() => {
          delete this.ambience;
          this.destroy.emit(true);
        });
      }
    });
  }

  private loadFromSvc() {
    this.ambienceSvc.get(this.ambience.id).refetch().then((e: any) => {
      this.ambience = this.ambience.fromQuery(e.data.ambience);
      this.isAmbienceLoaded = true;
    });
  }

}
