<mat-card *ngIf="product" [class.inline]="inline" [class.product-card--added-to-card]="('Project' | asyncCan:'administrate' | async) && product.isApproved() && abilitySvc.user.editMode && !static && !inline" class="product-card">
  <mat-card-content class="product-image">
    <app-carousel *ngIf="carousel" [images]="product.images"></app-carousel>
    <img (error)="$event.target.src='assets/images/placeholders/produit.jpg'" *ngIf="!carousel" [alt]="product.label"
         [attr.data-src]="product.images[0]" class="product-img lazyload blur-up" (click)="zoom()"/>
    <div [attr.class]="static ? 'product-link static' : 'product-link'"
         *ngIf="('Project' | asyncCan:'administrate' | async) && abilitySvc.user.editMode && product.url != '' && product.url != null"
    >
      <a [href]="product.url" target="_blank"><mat-icon>link</mat-icon></a>
    </div>
    <div (click)="editProduct()"
         *ngIf="('Project' | asyncCan:'administrate' | async) && abilitySvc.user.editMode && !static"
         class="product-edit">
      <mat-icon>edit</mat-icon>
    </div>
    <div (click)="orderAgain()" class="product-order-again"
        *ngIf="('Project' | asyncCan:'administrate' | async) && abilitySvc.user.editMode && static">
      <mat-icon *ngIf="!orderingAgain && !orderedAgain">autorenew</mat-icon>
      <mat-spinner *ngIf="orderingAgain && !orderedAgain" diameter="18"></mat-spinner>
      <mat-icon *ngIf="orderedAgain && successfulOrderingAgain">done</mat-icon>
      <mat-icon *ngIf="orderedAgain && !successfulOrderingAgain">report_problem</mat-icon>
    </div>
    <div (click)="toggleCart()"
         *ngIf="('Project' | asyncCan:'update' | async) && !static"
         class="product-shopping-cart" [class.inline]="inline">
      <mat-icon *ngIf="!product.isApproved()">
        add_shopping_cart
      </mat-icon>
      <mat-icon *ngIf="product.isApproved()">
        remove_shopping_cart
      </mat-icon>
    </div>
    <div (click)="delete()"
         *ngIf="('Project' | asyncCan:'administrate' | async) && abilitySvc.user.editMode && !static && !inline"
         class="product-destroy">
      <mat-icon>delete</mat-icon>
    </div>
    <div (click)="selectedProduct.emit(true)"
         *ngIf="('Project' | asyncCan:'administrate' | async) && !inline && abilitySvc.user.editMode && !static && star !== null"
         class="select-product">
      <mat-icon>{{star ? 'star' : 'star_border'}}</mat-icon>
    </div>
  </mat-card-content>

  <mat-card-footer *ngIf="!inline" class="product-data" fxLayout="column">
    <h3 class="product-label">
      <app-inline-edit #labelField (fieldUpdate)="updateLabel($event)" [value]="product.label"
                       field="Label"></app-inline-edit>
    </h3>
    <table class="product-table">
      <tr *ngIf="('Project' | asyncCan:'fullRead' | async) && abilitySvc.user.showPrices">
        <td>Prix</td>
        <td>
          <app-inline-edit #priceField (fieldUpdate)="updatePrice($event)"
                           [display]="(project.organization.vatPrices ? product.price * 1.2 : product.price) | currency"
                           [value]="(project.organization.logisticianBreakdown ? (product.price - product.logisticianPrice) : product.price).toFixed(2)"
                           field="Prix"></app-inline-edit>
        </td>
      </tr>
      <tr>
        <td>Quantité</td>
        <td>
          <app-inline-edit #quantityField (fieldUpdate)="updateQuantity($event)" [value]="product.quantity"
                           field="Quantité" [fieldType]="'number'"></app-inline-edit>
        </td>
      </tr>
      <tr>
        <td>Dimensions</td>
        <td>{{product.dimensions}}</td>
      </tr>
      <tr *ngIf="('Project' | asyncCan:'administrate' | async) && abilitySvc.user.editMode">
        <td>BAP</td>
        <td>
          <a href="https://bap.whataniceoffice.com/?product_id={{product.bapId}}" target="_blank">{{product.bapId}}</a>
        </td>
      </tr>
    </table>
    <div fxLayout="row" fxLayoutGap="16">
      <button (click)="like()" [class.active]="product.liked_by(abilitySvc.user)" class="like-button" fxFlex="50"
              mat-button>
        <mat-icon>thumb_up</mat-icon>
        {{product.likes}}</button>
      <button (click)="dislike()" [class.active]="product.disliked_by(abilitySvc.user)" class="dislike-button"
              fxFlex="50"
              mat-button>
        <mat-icon>thumb_down</mat-icon>
        {{product.dislikes}}</button>
    </div>
  </mat-card-footer>

  <mat-card-footer *ngIf="inline" class="product-data">
    <div class="product-infos">
      <p class="product-name">{{product.label}}</p>
      <p *ngIf="product.dimensions">{{product.dimensions}}</p>
      <p *ngIf="organization.logisticianBreakdown">Livraison & Installation comprise</p>
      <p *ngIf="product.installationAt">Date d'installation prévisionnelle : {{product.installationAt}}</p>
    </div>
    <div class="product-quantity">
      <app-inline-edit #inlineQuantityField (fieldUpdate)="updateQuantity($event)" [static]="static" style="margin: 0 10%;"
                       [value]="product.quantity" [fieldType]="'number'" [forceEdit]="('Project' | asyncCan:'update' | async)"></app-inline-edit>
    </div>
    <div *ngIf="organization.vatPrices" [class.blurry]="!abilitySvc.user.showPrices" class="product-price">
      <p>{{product.price * 1.2 * product.quantity | currency}} TTC</p>
      <p>{{product.price * 1.2 | currency}} TTC / unité</p>
    </div>
    <div *ngIf="!organization.vatPrices" [class.blurry]="!abilitySvc.user.showPrices" class="product-price">
      <p>{{product.price * product.quantity | currency}} HT</p>
      <p>{{product.price | currency}} HT / unité</p>
    </div>
    <p class="delay-purchase" *ngIf="('Project' | asyncCan:'update' | async) && !product.delayPurchase && !static" (click)="delayPurchase()">Mettre de côté</p>
    <p class="delay-purchase" *ngIf="('Project' | asyncCan:'update' | async) && product.delayPurchase && !static" (click)="undelayPurchase()">Remettre au devis</p>

  </mat-card-footer>

</mat-card>
