<div class="button-row" fxLayout="row wrap"
     *ngIf="showMenu || (('Project' | asyncCan: 'administrate' | async) && abilitySvc.user.editMode)">
  <div (click)="setCurrentProposition(value.proposition)"
       *ngFor="let value of values"
       [class.active]="value.proposition === currentProposition"
       [class.show]="value.display || (abilitySvc.user.editMode && !static)"
       class="button">
    <span class="title">{{value.proposition.name}}</span>
    <span class="actions-container"
      *ngIf="(('Project' | asyncCan: 'administrate' | async) && abilitySvc.user.editMode) && !static && !value.proposition.ultimate">
      <span (click)="update(value.proposition)">
        <mat-icon>edit</mat-icon>
      </span>
      <span (click)="duplicate(value.proposition)">
        <mat-icon>content_copy</mat-icon>
      </span>
      <span (click)="destroy(value)" class="delete-button">
        <app-spinner *ngIf="isDeleting === value.proposition.id"></app-spinner>
        <mat-icon *ngIf="isDeleting !== value.proposition.id">close</mat-icon>
      </span>
    </span>
  </div>
  <div (click)="createProposition()"
       class="button show"
       *ngIf="(('Project' | asyncCan: 'administrate' | async) && abilitySvc.user.editMode) &&!static">
    <span>Nouvelle Proposition</span>
  </div>
</div>
