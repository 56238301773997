import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, Validators} from '@angular/forms';
import {ProductService} from '../../_services/product.service';
import {Product} from '../../_models/product';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {
  productForm;
  public files: string[] = [];
  private newFiles: string[] = [];
  private filesDeleted: string[] = [];

  constructor(public dialogRef: MatDialogRef<EditProductComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: FormBuilder,
              private productSvc: ProductService) {
    const p: Product = data.product;
    this.productForm = this.formBuilder.group({
      id: [p.id],
      label: [p.label, Validators.required],
      quantity: [p.quantity, Validators.required],
      price: [parseFloat((p.price - p.logisticianPrice).toFixed(2)), Validators.required],
      brand: [p.brand],
      url: [p.url],
      files: [''],
      width: [p.width],
      height: [p.height],
      depth: [p.depth],
      suggestion: [p.suggestion],
      category: [p.category, Validators.required],
      sellerLabel: [p.sellerLabel, Validators.required],
      sellerReference: [p.sellerReference],
      logisticianPercentage: [p.logisticianPercentage, Validators.required],
      installationAt: [p.installationAt],
      filesDeleted: ['']
    });
    this.files = [...p.images];
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addFile(files) {
    files.addedFiles.forEach((f) => {
      this.readFile(f).subscribe((file: string) => {
        this.files.push(file);
        this.newFiles.push(file);
        this.productForm.patchValue({files: this.newFiles});
      });
    });
  }

  deleteFile(file) {
    this.files.splice(this.files.indexOf(file), 1);
    if (this.data.product.images.indexOf(file) >= 0) {
      this.filesDeleted.push(file);
    }
    if (this.newFiles.indexOf(file) >= 0) {
      this.newFiles.splice(this.newFiles.indexOf(file), 1);
    }
    this.productForm.patchValue({files: this.newFiles, filesDeleted: this.filesDeleted});
  }

  onSubmit() {
    if (this.productForm.valid) {
      this.productSvc.update(this.productForm.value).subscribe((e: any) => {
        this.dialogRef.close({product: e.data.updateProduct});
      });
    }
  }

  private readFile(file) {
    return new Observable((obs) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        obs.next(reader.result);
        obs.complete();
      }, false);
      reader.readAsDataURL(file);
    });
  }
}
