export class Base {
  id: number;

  protected assign(data, field, key, object) {
    if (this[field] && data[key] !== undefined) {
      this[field] = Array.from(data[key]).map((val) => new object().fromQuery(val));
    }
  }

  protected sort(field, predicate) {
    if (this[field] && Array.isArray(this[field])) {
      this[field] = this[field].sort(predicate);
    }
  }
}
