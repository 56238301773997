import { Injectable } from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from "graphql-tag";

@Injectable({
  providedIn: 'root'
})
export class ProductOrderService {

  constructor(private apollo: Apollo) { }

  update(input) {
    return this.apollo.mutate({
      mutation: gql`
        mutation updateProductsOrder ($input: ProductsOrderUpdateInput!) {
            updateProductsOrder (input: $input) {
              id
              label
              price
              url
              comment
              bapId
              logisticianPrice
              sellerReference
              orderReference
              invoiceReference
              trackingReference
              sellerLabel
              logisticianName
              status
              ambienceName
              status
              images
              quantity
              installationAt
              deliveryDate
              dimensions
              unitPrice
              internalUnitPrice
              logisticianUnitPrice
              internalLogisticianPrice
              ecotax
              deliveryPrice
            }
        }
      `,
      variables: {input}
    });
  }

  upStatus(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation upStatusProductsOrder ($id: ID!) {
            upStatusProductsOrder (id: $id) {
              status
            }
        }
      `,
      variables: {id}
    });
  }

  destroy(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation destroyProductsOrder ($id: ID!) {
            destroyProductsOrder (id: $id) {
              id
            }
        }
      `,
      variables: {
        id
      }
    });
  }

}
