import {Injectable} from '@angular/core';
import {Project} from '../_models/project';
import {Quotation} from '../_models/quotation';
import {Product} from '../_models/product';
import {ExportToCsv} from 'export-to-csv';
import {DatePipe} from '@angular/common';
import {Proposition} from '../_models/proposition';

@Injectable({
  providedIn: 'root'
})
export class QuotationLogisticCsvService {
  options = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useKeysAsHeaders: true,
    filename: ''
  };

  constructor(private datePipe: DatePipe) {
  }

  generateCsv(proposition: Proposition, products: Product[]) {
    const data = [];


    products.forEach((p: Product) => {
      data.push({
        ambienceName: p.ambienceName,
        label: p.label,
        quantity: p.quantity,
        sellerReference: p.sellerReference,
        sellerLabel: p.sellerLabel,
        installationAt: p.installationAt,
        rawPrice: p.rawPrice.toFixed(2),
        logisticianPrice: p.logisticianPrice.toFixed(2),
        total: p.total.toFixed(2)
      });
    });


    this.options.filename = `${proposition.slug}${this.datePipe.transform(Date.now(), 'dd-MM-yyyy')}`;
    const csvExporter = new ExportToCsv(this.options);
    csvExporter.generateCsv(data);
  }
}
