import {Component, OnDestroy, OnInit} from '@angular/core';
import {Project} from '../../_models/project';
import {Order} from '../../_models/order';
import {OrderService} from '../../_services/order.service';
import {DatePipe} from '@angular/common';
import {AbilityService} from '../../_services/ability.service';
import {ProjectService} from '../../_services/project.service';
import {Router} from '@angular/router';
import {QuotationCsvService} from '../../_exports/quotation-csv.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Quotation} from '../../_models/quotation';
import {Product} from '../../_models/product';
import {ConfirmationDialogComponent} from '../../shared/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@AutoUnsubscribe()
@Component({
  selector: 'app-pre-order',
  templateUrl: './pre-order.component.html',
  styleUrls: ['./pre-order.component.scss']
})
export class PreOrderComponent implements OnInit, OnDestroy {
  project: Project;
  ordersIsLoaded = false;
  orders: Order[];
  menuLoading = false;
  private order$;

  constructor(private orderSvc: OrderService,
              private datePipe: DatePipe,
              public abilitySvc: AbilityService,
              private projectSvc: ProjectService,
              private router: Router,
              private dialog: MatDialog,
              private quotationCsvSvc: QuotationCsvService) {
  }

  ngOnInit(): void {
    this.abilitySvc.resolveProject();
    this.abilitySvc.project.subscribe((p) => {
      this.project = p;
      this.order$ = this.orderSvc.getForProject(this.project.id).refetch().then(({data}: any) => {
        if (data.ordersProject) {
          this.orders = data.ordersProject.map((q) => new Order().fromQuery(q)).filter(order => order.preOrder);
          this.orders = this.orders.sort((a, b) => Date.parse(a.validatedAt) - Date.parse(b.validatedAt));
        }
        this.ordersIsLoaded = true;
      });
    });
  }

  quotationsTotal(quotations) {
    return quotations.reduce((a, b) => a + b.total, 0);
  }

  logisticianTotal(quotations) {
    return quotations.reduce((a, b) => a + b.logisticianTotal, 0);
  }

  generatePdf(order) {
    this.menuLoading = true;
    this.orderSvc.pdf(order.id).subscribe((e: any) => {
      const link = document.createElement('a');
      link.download = `${this.project.slug}${this.datePipe.transform(order.preOrderValidatedAt, 'dd-MM-yyyy')}.pdf`;
      link.href = 'data:application/octet-stream;base64,' + e.data.pdfOrder.blob;
      link.click();
      this.menuLoading = false;
    });
  }

  generateCsv(quotations) {
    this.quotationCsvSvc.generateCsv(this.project, quotations);
  }

  removeProduct(quotation: Quotation, product: Product) {
    quotation.products.splice(quotation.products.indexOf(product), 1);
  }

  confirmOrder(order: Order) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Ce bon de commande sera définitivement validé.'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.menuLoading = true;
        this.orderSvc.validate(order.id).subscribe(() => {
          this.router.navigate(['/project/orders']);
        });
      }
    });
  }

  rollbackOrder(order: Order) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Ce bon de commande sera re-transformé en devis.'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.menuLoading = true;
        this.orderSvc.rollback(order.id).subscribe(() => {
          this.router.navigate(['/project/quotations']);
        });
      }
    });
  }

  ngOnDestroy(): void {
  }

}
