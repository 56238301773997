import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, Subscriber} from 'rxjs';
import {AuthService} from '../_services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
              private authSvc: AuthService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable<any>((observer: Subscriber<any>) => {
      this.authSvc.ping().valueChanges.subscribe((e: any) => {
        if (e.errors) {
          localStorage.removeItem('currentUser');
          this.authSvc.currentUserSubject.next(null);
          observer.next(this.router.navigate(['/sign_in']));
        } else {
          this.authSvc.save(this.authSvc.getCurrentUser().fromQuery(e.data.pingUser));
          observer.next(true);
        }
      });
    });
  }

}
