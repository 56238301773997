import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Project} from '../../_models/project';
import {AbilityService} from '../../_services/ability.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {QuotationCsvService} from '../../_exports/quotation-csv.service';
import {DatePipe} from '@angular/common';
import {ProjectService} from '../../_services/project.service';
import {Router} from '@angular/router';
import {ConfirmationDialogComponent} from '../../shared/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {PropositionService} from '../../_services/proposition.service';
import {Proposition} from '../../_models/proposition';
import {QuotationSummaryComponent} from './quotation-summary/quotation-summary.component';
import {Ambience} from '../../_models/ambience';
import {Quotation} from '../../_models/quotation';
import {Product} from '../../_models/product';

@AutoUnsubscribe()
@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent implements OnInit, OnDestroy {
  project: Project;
  quotationIsLoaded = false;
  currentProposition: Proposition;
  propositions = [];
  menuLoading = false;
  @ViewChild('propositionMenu') propositionMenu;
  private proposition$;

  constructor(private datePipe: DatePipe,
              private quotationCsvSvc: QuotationCsvService,
              public abilitySvc: AbilityService,
              private projectSvc: ProjectService,
              private router: Router,
              private dialog: MatDialog,
              private propositionSvc: PropositionService) {
  }

  get quotationsTotal() {
    return this.currentProposition.quotations.reduce((a, b) => a + b.total, 0);
  }

  get logisticianTotal() {
    return this.currentProposition.quotations.reduce((a, b) => a + b.logisticianTotal, 0);
  }

  ngOnInit() {
    this.abilitySvc.resolveProject();
    this.abilitySvc.project.subscribe((p) => {
      this.project = p;
      this.proposition$ = this.propositionSvc.getWithQuotations(p.id).refetch().then((e: any) => {
        p.fromQuery(e.data.project);
        this.setPropositions();
        this.currentProposition = this.propositions[0];
        this.quotationIsLoaded = true;
      });
    }).unsubscribe();
  }

  ngOnDestroy() {
  }

  removeProduct(quotation: Quotation, product: Product) {
    quotation.products.splice(quotation.products.indexOf(product), 1);
  }

  setPropositions() {
    this.propositions = this.project.propositions.map((p) => {
      return {proposition: p, display: p.quotations.length > 0};
    });
  }

  generatePdf() {
    this.menuLoading = true;
    this.propositionSvc.pdf(this.currentProposition.id).subscribe((e: any) => {
      const link = document.createElement('a');
      link.download = `${this.project.slug}${this.currentProposition.slug}-${this.datePipe.transform(Date.now(), 'dd-MM-yyyy')}.pdf`;
      link.href = 'data:application/octet-stream;base64,' + e.data.pdfProposition.blob;
      link.click();
      this.menuLoading = false;
    });
  }

  generateCsv() {
    this.quotationCsvSvc.generateCsv(this.project, this.currentProposition.quotations);
  }

  showSummary() {
    this.dialog.open(QuotationSummaryComponent, {
      width: '90vw',
      data: this.currentProposition
    });
  }

  confirmOrder() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Ce devis basculera en pré-commande et sera validé par notre équipe (disponibilité & prix).'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.menuLoading = true;
        this.propositionSvc.confirmOrder(this.currentProposition.id).subscribe(() => {
          this.router.navigate(['/project/pre_orders']);
        });
      }
    });
  }

  addPropositionToUltimate(proposition: Proposition) {
    this.addProductsToUltimate(
      [].concat.apply([], proposition.quotations.map((q) => q.products.map((p) => parseInt(String(p.id), 10))))
    );
  }

  addQuotationToUltimate(quotation: Quotation) {
    this.addProductsToUltimate(
      quotation.products.map((p) => parseInt(String(p.id), 10))
    );
  }

  addProductsToUltimate(products: number[]) {
    this.menuLoading = true;
    this.projectSvc.addProductsToUltimateProposition(this.project.id, products)
      .subscribe((p: any) => {
        this.project.fromQuery(p.data.addProductsToUltimatePropositionProject);
        this.setPropositions();
        this.propositionMenu.setCurrentProposition(this.propositions.find((a) => a.proposition.ultimate).proposition);
        this.menuLoading = false;
      });
  }
}
