import { Injectable } from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from "graphql-tag";
import { Event} from '../_models/event';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private apollo: Apollo) { }

  create(event: any) {
    return this.apollo.mutate({
      mutation: gql`
        mutation createEvent ($title: String, $description: String, $startDate: String, $endDate: String, $projectId: ID) {
            createEvent (title: $title, description: $description, startDate: $startDate, endDate: $endDate, projectId: $projectId) {
              id
              title
              description
              startDate
              endDate
            }
        }
      `,
      variables: {
        title: event.title,
        description: event.description,
        startDate: event.startDate,
        endDate: event.endDate,
        projectId: event.projectId
      }
    });
  }

  delete(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation destroyEvent ($id: ID!) {
            destroyEvent (id: $id) {
              id
            }
        }
      `,
      variables: {id}
    });
  }
}
