import {MapArea} from './map-area';
import {Base} from './base';

export class Map extends Base {
  id: number;
  imagePath: string;
  mapAreas: MapArea[] = [];

  fromQuery(response: any) {
    Object.assign(this, response);
    this.assign(response, 'mapAreas', 'mapAreas', MapArea);
    return this;
  }
}
