import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Map} from '../../../_models/map';
import {MapArea} from '../../../_models/map-area';
import {MapAreaService} from '../../../_services/map-area.service';
import {AbilityService} from '../../../_services/ability.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../../../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-map-areas',
  templateUrl: './map-areas.component.html',
  styleUrls: ['./map-areas.component.scss']
})
export class MapAreasComponent implements OnInit {
  @Input() map: Map;
  @Input() mapArea: MapArea;
  @Output() change: EventEmitter<Map> = new EventEmitter<Map>();
  @Output() delete: EventEmitter<MapArea> = new EventEmitter<MapArea>();
  @Output() deleteMap: EventEmitter<Map> = new EventEmitter<Map>();
  @Output() selected: EventEmitter<MapArea> = new EventEmitter<MapArea>();
  isDeleting = false;
  isLoading = false;


  constructor(private mapAreaSvc: MapAreaService,
              private dialog: MatDialog,
              public abilitySvc: AbilityService) {
  }

  ngOnInit() {
  }

  updateImage(data: any) {
    this.isLoading = true;
    this.mapAreaSvc.update(this.mapArea, data.file).subscribe((e: any) => {
      this.mapArea.fromQuery(e.data.updateMapArea);
      this.isLoading = false;
    });
  }

  deleteImage(url) {
    this.isLoading = true;
    this.mapAreaSvc.update(this.mapArea, null, url).subscribe((e: any) => {
      this.mapArea.fromQuery(e.data.updateMapArea);
      this.isLoading = false;
    });
  }

  addMapArea() {
    this.change.next(this.map);
  }

  destroy() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Voulez-vous vraiment supprimer cette zone ?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isDeleting = true;
        this.mapAreaSvc.delete(this.mapArea.id).subscribe(() => {
          this.delete.next(this.mapArea);
        });
      }
    });
  }

  onDeleteMap(map) {
    this.deleteMap.next(map);
  }
}
