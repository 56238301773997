import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../_services/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../_models/user';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MustMatch} from '../_validators/must-match';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  signUpForm: FormGroup;
  private token: string;

  constructor(private route: ActivatedRoute,
              private authSvc: AuthService,
              private formBuilder: FormBuilder,
              private router: Router,
              private snackBar: MatSnackBar) {
  }

  get f() {
    return this.signUpForm.controls;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.token = params.get('invitationToken');

      this.authSvc.invitation(this.token).refetch().then((e: any) => {
        if (e.data === undefined) {
          this.router.navigate(['/']);
        }
        this.signUpForm = this.formBuilder.group({
          email: e.data.invitationUser.email,
          password: ['', [Validators.minLength(6), Validators.required]],
          passwordConfirmation: ['', Validators.required],
          firstName: ['', Validators.required],
          lastName: ['', Validators.required]
        }, {
          validators: [MustMatch('password', 'passwordConfirmation')]
        });
      });
    });

    if (this.authSvc.getCurrentUser().id) {
      this.router.navigate(['/project']);
    }
  }

  onSubmit() {
    if (this.signUpForm.valid) {
      const data = this.signUpForm.value;
      delete data.email;
      data.invitationToken = this.token;
      this.authSvc.signUp(data).subscribe((e: any) => {
        if (e.data.signUpUser) {
          const user = new User().fromQuery((e.data.signUpUser));
          if (user.isAdmin()) {
            user.editMode = true;
            user.showPrices = true;
          }
          this.authSvc.save(user);
          this.router.navigate(['/project']).then(() => location.reload());
        } else {
          this.snackBar.open('Une erreur est survenue');
        }
      });
    }
  }

}
