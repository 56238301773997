import {Base} from './base';
import {Cluster} from './cluster';

export class Ambience extends Base {
  id: number;
  name: string;
  comment: string;
  moodboardPath: { low: string, high: string };
  clusters: Cluster[] = [];

  fromQuery(response: any) {
    Object.assign(this, response);
    this.assign(response, 'clusters', 'clusters', Cluster);
    this.clusters = this.clusters.sort((a, b) => a.position - b.position);
    return this;
  }
}
