<div mat-dialog-content>
  <span [mat-dialog-close]="true" class="close-button">
    <mat-icon>close</mat-icon>
  </span>

  <div class="carousel">
    <ng-container *ngFor="let image of data; let i = index">
      <img
        (error)="$event.target.src='assets/images/placeholders/produit.jpg'"
        *ngIf="currentSlide == i"
        [attr.data-src]="image"
        class="slide lazyload blur-up"
      />
    </ng-container>

    <div *ngIf="data.length > 1">
      <button (click)="onPreviousClick()" class="control prev">
        <span class="arrow left"></span>
      </button>
      <button (click)="onNextClick()" class="control next">
        <span class="arrow right"></span>
      </button>
    </div>
  </div>
</div>
