<app-spinner *ngIf="!order"></app-spinner>
<mat-card *ngIf="order">
  <mat-card-header>
    <mat-card-title>{{order.name}} - {{order.validatedAt | date:'short'}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="row wrap">
      <div fxFlex>
        <button mat-button color="primary"
                (click)="exporter.exportTable('xls', { fileName: order.slug + '-' + order.validatedAt })">
          Export XLS
        </button>
        <button mat-button color="primary"
                (click)="exporter.exportTable('csv',  { fileName: order.slug + '-' + order.validatedAt })">
          Export CSV
        </button>
      </div>
    </div>
    <div fxLayout="row wrap">
      <form style="display: flex" [formGroup]="filterForm" fxFlex fxLayout="row wrap">
        <mat-form-field fxFlex>
          <mat-label>Produit</mat-label>
          <input matInput formControlName="product">
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-label>Zone</mat-label>
          <mat-select formControlName="ambienceName">
            <mat-option>Aucune</mat-option>
            <mat-option *ngFor="let ambience of ambiences" [value]="ambience">{{ambience}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-label>Marchand</mat-label>
          <mat-select formControlName="sellerLabel">
            <mat-option>Aucun</mat-option>
            <mat-option *ngFor="let seller of sellers" [value]="seller">{{seller}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-label>Status</mat-label>
          <mat-select formControlName="status">
            <mat-option>Aucun</mat-option>
            <mat-option *ngFor="let item of status | keyvalue:statusSort" [value]="item.key">{{item.value}}</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <mat-table [dataSource]="table"
               mat-table
               matTableExporter
               [hiddenColumns]="[0, 13]"
               matSort
               (matSortChange)="sort($event)"
               style="width: 100%"
               #exporter="matTableExporter">
      <ng-container *ngFor="let col of cols" [matColumnDef]="col.field">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{col.title}}</th>
        <td *matCellDef="let element"
            mat-cell>
          <span *ngIf="col.display === undefined">{{element[col.field]}}</span>
          <span *ngIf="col.display === 'boolean'"><mat-icon>{{element[col.field] ? 'done' : 'clear'}}</mat-icon></span>
          <span *ngIf="col.display === 'date'">{{element[col.field] | date}}</span>
          <span *ngIf="col.display === 'image'"><img class="image-thumb mat-elevation-z1"
                                                     (error)="$event.target.src='assets/images/placeholders/produit.jpg'"
                                                     [src]="element[col.field]"></span>
          <span *ngIf="col.display === 'price'">{{element[col.field] | currency}}</span>
          <span *ngIf="col.display === 'status'">
            {{element['humanStatus']}}
            <mat-icon
              class="up-status"
              (click)="upStatus(element)"
              *ngIf="element.status !== 'completed' && !element.status.includes('css') && element.status !== 'canceled'"
            >upgrade
            </mat-icon>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell>Actions</th>
        <td *matCellDef="let element" mat-cell>
          <div class="button-row" fxLayout="row">
            <button mat-button (click)="edit(element)" *ngIf="element.comment" [matTooltip]="element.comment">
              <mat-icon>feedback</mat-icon>
            </button>
            <button mat-button (click)="goToLink(element.url)">
              <mat-icon>link</mat-icon>
            </button>
            <button mat-button (click)="edit(element)" class="edit-button">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <tr *matHeaderRowDef="colsNames" mat-header-row></tr>
      <tr *matRowDef="let row; columns: colsNames;" mat-row [class.red]="row.priceWarning"></tr>
      {{colsNames}}
    </mat-table>
  </mat-card-content>
</mat-card>
