import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private apollo: Apollo) {
  }

  getAll() {
    return this.apollo
      .watchQuery({
        query: gql`
        query organizations {
          organizations {
            id
            name
            logistician
            logisticianBreakdown
            vatPrices
            address {
              id
              addressLine1
              addressLine2
              city
              postalCode
            }
          }
        }
        `
      });
  }

  create(input) {
    return this.apollo.mutate({
      mutation: gql`
        mutation createOrganization ($input: OrganizationCreateInput!) {
            createOrganization (input: $input) {
              id
              name
              logistician
              logisticianBreakdown
              vatPrices
              address {
                id
                addressLine1
                addressLine2
                city
                postalCode
              }
            }
        }
      `,
      variables: {
        input
      }
    });
  }

  update(input) {
    return this.apollo.mutate({
      mutation: gql`
        mutation updateOrganization ($input: OrganizationUpdateInput!) {
            updateOrganization (input: $input) {
              id
              name
              logistician
              logisticianBreakdown
              vatPrices
              address {
                id
                addressLine1
                addressLine2
                city
                postalCode
              }
            }
        }
      `,
      variables: {
        input
      }
    });
  }

  delete(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation destroyOrganization ($id: ID!) {
            destroyOrganization (id: $id) {
              id
            }
        }
      `,
      variables: {id}
    });
  }

}
