import {Injectable} from '@angular/core';
import {Project} from '../_models/project';
import {Quotation} from '../_models/quotation';
import {Product} from '../_models/product';
import {ExportToCsv} from 'export-to-csv';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class QuotationCsvService {
  options = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useKeysAsHeaders: true,
    filename: ''
  };

  constructor(private datePipe: DatePipe) {
  }

  generateCsv(project: Project, quotations: Quotation[]) {
    const data = [];

    quotations.forEach((q: Quotation) => {
      q.validatedProducts.forEach((p: Product) => {
        data.push({
          name: p.label,
          quantity: p.quantity,
          price: p.price.toFixed(2),
          installation: p.installationAt,
          ambience: q.ambienceName,
          priceTotal: (p.price * p.quantity).toFixed(2)
        });
      });
    });

    this.options.filename = `${project.slug}${this.datePipe.transform(Date.now(), 'dd-MM-yyyy')}`;
    const csvExporter = new ExportToCsv(this.options);
    csvExporter.generateCsv(data);
  }
}
