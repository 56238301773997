import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private apollo: Apollo) {
  }

  get() {
    return this.apollo
      .watchQuery({
        query: gql`
        query carts {
            carts {
                id
                label
                productCount
            }
        }
        `
      });
  }
}
