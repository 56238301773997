<form (ngSubmit)="onSubmit()" [formGroup]="productOrderForm">
  <div mat-dialog-content fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="center" fxLayoutGap="8px">
    <div fxFlex="20" fxFlex.lt-xl="40">
      <div fxLayout="column" fxLayout.lt-xl="row" fxLayout.lt-md="column" fxLayoutGap="8px">
        <mat-card fxFlex="100">
          <img mat-card-image [src]="product.image"
               (error)="$event.target.src='assets/images/placeholders/produit.jpg'">
          <mat-card-footer>
            {{ product.bapId ? 'BAP ID : ' + product.bapId : 'Produit local' }}
          </mat-card-footer>
        </mat-card>
        <mat-form-field fxFlex>
          <mat-label>Commentaire</mat-label>
          <textarea formControlName="comment" matInput
                    type="text" rows="4" style="resize: none;" ></textarea>
        </mat-form-field>
      </div>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Produit</mat-label>
        <input matInput disabled [value]="product.label">
      </mat-form-field>

      <div fxLayout="row" fxLayout.lt-md="row wrap" fxLayoutGap="8px" fxLayoutGap.lt-lg="0">
        <mat-form-field fxFlex fxFlex.lt-lg="50">
          <mat-label>PU HT Client</mat-label>
          <input matInput formControlName="unitPrice" type="number" min="0" step="0.01">
        </mat-form-field>

        <mat-form-field fxFlex fxFlex.lt-lg="50">
          <mat-label>PU Log. HT Client</mat-label>
          <input matInput formControlName="logisticianUnitPrice" type="number" min="0" step="0.01">
        </mat-form-field>

        <mat-form-field fxFlex fxFlex.lt-lg="50">
          <mat-label>PU HT WANO</mat-label>
          <input matInput formControlName="internalUnitPrice" type="number" min="0" step="0.01">
        </mat-form-field>
        <!--
        <mat-form-field fxFlex fxFlex.lt-lg="50">
          <mat-label>PU Log. HT WANO</mat-label>
          <input matInput formControlName="internalLogisticianPrice" type="number" min="0" step="0.01">
        </mat-form-field>
        -->
        <mat-form-field fxFlex fxFlex.lt-lg="50">
          <mat-label>Quantité</mat-label>
          <input matInput formControlName="quantity" type="number" min="0" step="0.01">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.lt-md="row wrap" fxLayoutGap="8px" fxLayoutGap.lt-lg="0">
<!--        <mat-form-field fxFlex fxFlex.lt-lg="50">
          <mat-label>Frais livraison HT</mat-label>
          <input matInput formControlName="deliveryPrice" type="number" min="0" step="0.01">
        </mat-form-field>-->

<!--        <mat-form-field fxFlex fxFlex.lt-lg="50">
          <mat-label>Ecotaxe</mat-label>
          <input matInput formControlName="ecotax" type="number" min="0" step="0.01">
        </mat-form-field>-->

        <mat-form-field fxFlex fxFlex.lt-lg="50">
          <mat-select formControlName="status">
            <mat-option
              *ngFor="let item of status | keyvalue:statusSort" [value]="item.key">
              {{item.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex fxFlex.lt-lg="50">
          <mat-label>Marque</mat-label>
          <input matInput formControlName="sellerLabel">
        </mat-form-field>

        <mat-form-field fxFlex fxFlex.lt-lg="50">
          <mat-label>URL</mat-label>
          <input matInput formControlName="url">
        </mat-form-field>

        <mat-form-field fxFlex fxFlex.lt-lg="50">
          <mat-label>Date de livraison</mat-label>
          <input matInput formControlName="deliveryDate" type="date">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayoutGap="8px" fxLayoutGap.lt-lg="0">
        <mat-form-field fxFlex fxFlex.lt-lg="50">
          <mat-label>Réf. Produit</mat-label>
          <input matInput formControlName="sellerReference">
        </mat-form-field>

 <!--       <mat-form-field fxFlex fxFlex.lt-lg="50">
          <mat-label>Réf. Commande</mat-label>
          <input matInput formControlName="orderReference">
        </mat-form-field>-->

        <mat-form-field fxFlex fxFlex.lt-md="50">
          <mat-label>Réf. Facture</mat-label>
          <input matInput formControlName="invoiceReference">
        </mat-form-field>

        <mat-form-field fxFlex fxFlex.lt-md="50">
          <mat-label>Réf. Tracking</mat-label>
          <input matInput formControlName="trackingReference">
        </mat-form-field>

        <mat-form-field fxFlex fxFlex.lt-lg="50">
          <mat-label>Date d'installation</mat-label>
          <input matInput formControlName="installationAt" type="date">
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button type="submit">Valider</button>
    <button (click)="onNoClick()" mat-button>Annuler</button>
  </div>
</form>
