import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbilityService} from '../../_services/ability.service';
import {AttachmentService} from '../../_services/attachment.service';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent {
  @Input() content: string;
  @Input() small = false;
  @Output() update: EventEmitter<string> = new EventEmitter<string>();

  private nextSave;

  public tinyMCEInit = {
    height: '100%',
    menubar: true,
    language: 'fr_FR',
    plugins: [
      'advlist autolink lists link image imagetools charmap print',
      'preview anchor searchreplace visualblocks',
      'fullscreen insertdatetime media table paste',
      'help wordcount save'
    ],
    toolbar:
      'save | undo redo |' +
      ' formatselect |' +
      ' bold italic |' +
      ' alignleft aligncenter alignright alignjustify |' +
      ' bullist numlist outdent indent |' +
      ' image table',
    image_advtab: true,
    images_upload_handler: (blob, success, failure) => {
      this.attachmentSvc.create(blob.base64(), blob.filename()).subscribe(
        (e: any) => success(e.data.createAttachment.filePath),
        (e: any) => failure(e));
    },
    save_onsavecallback: (editor) => this.update.next(editor.getContent()),
    setup: (editor) => {
      editor.on('input', () => {
        clearTimeout(this.nextSave);
        this.nextSave = setTimeout(() => editor.execCommand('mceSave'), 5000);
      });
    }
  };

  constructor(public abilitySvc: AbilityService,
              private attachmentSvc: AttachmentService) {
  }
}
