import {Product} from './product';
import {Base} from './base';

export class Cluster extends Base {
  id: number;
  name: string;
  products: Product[] = [];
  selectedProduct: Product;
  suggestion: boolean;
  position: number;

  fromQuery(response: any) {
    Object.assign(this, response);
    this.selectedProduct = new Product().fromQuery(response.product);
    this.assign(response, 'products', 'products', Product);
    this.products = this.products.sort((a, b) => a.position - b.position);
    return this;
  }
}
