<mat-card>
  <mat-card-header>
    <mat-card-title>{{title}}</mat-card-title>
    <span class="expand-spacer"></span>
    <button (click)="create.next(undefined)" *ngIf="showCreate" mat-button>Nouveau</button>
  </mat-card-header>
  <mat-card-content>
    <app-spinner *ngIf="!isLoaded"></app-spinner>
    <mat-form-field *ngIf="filterPredicate">
      <mat-label>Recherche</mat-label>
      <input (keyup)="filter($event)" matInput placeholder="Recherche">
    </mat-form-field>
    <table [dataSource]="table" mat-table style="width: 100%">
      <ng-container matColumnDef="show">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let element" mat-cell>
          <button (click)="select.next(element)" *ngIf="showSelect && (!this.selected || element.id != this.selected.id)"
                  mat-flat-button>
            <mat-icon>visibility</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container *ngFor="let col of cols" [matColumnDef]="col.field">
        <th *matHeaderCellDef mat-header-cell>{{col.title}}</th>
        <td *matCellDef="let element"
            mat-cell>
          <span *ngIf="col.display === undefined">{{element[col.field]}}</span>
          <span *ngIf="col.display === 'boolean'"><mat-icon>{{element[col.field] ? 'done' : 'clear'}}</mat-icon></span>
          <span *ngIf="col.display === 'date'">{{element[col.field] | date:'short'}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell>Actions</th>
        <td *matCellDef="let element" mat-cell class="button-row">
          <button (click)="publish.next(element)" *ngIf="showPublish && !element.published" color="accent" mat-flat-button>Publier</button>
          <button (click)="update.next(element)" *ngIf="showUpdate" color="accent" mat-flat-button>Modifier</button>
          <button (click)="delete.next(element)" *ngIf="showDelete" color="accent" mat-flat-button>
            <mat-icon>close</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr *matHeaderRowDef="colsNames" mat-header-row></tr>
      <tr *matRowDef="let row; columns: colsNames;" mat-row></tr>
    </table>
  </mat-card-content>
  <mat-card-actions>
    <mat-paginator #paginator
                   [length]="table.data.length"
                   [pageSize]="pageSize">
    </mat-paginator>
  </mat-card-actions>
</mat-card>
