import {Component, OnDestroy, OnInit} from '@angular/core';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {OrderService} from '../_services/order.service';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {Order} from '../_models/order';
import {MatTableDataSource} from '@angular/material/table';
import {ProductOrder} from '../_models/product-order';
import {MatDialog} from '@angular/material/dialog';
import {ProductOrderComponent} from './product-order/product-order.component';
import {ProductOrderService} from '../_services/product-order.service';
import {AbstractControl, FormBuilder} from '@angular/forms';
import {DatePipe} from '@angular/common';

@AutoUnsubscribe()
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy {
  order: Order;
  products: ProductOrder[];
  table: MatTableDataSource<ProductOrder> = new MatTableDataSource<ProductOrder>();
  pdfLoading = false;
  cols: { field: string, title: string, display?: string }[] = [
    {field: 'image', title: '', display: 'image'},
    {field: 'ambienceName', title: 'Zone'},
    {field: 'label', title: 'Produit'},
    {field: 'quantity', title: 'Qté'},
    {field: 'sellerReference', title: 'Réf. Produit'},
    {field: 'dimensions', title: 'Dimensions'},
    //{field: 'orderReference', title: 'Réf. Commande'},
    {field: 'sellerLabel', title: 'Marchand'},
    {field: 'status', title: 'Status', display: 'status'},
    //{field: 'logisticianName', title: 'Log.'},
    {field: 'deliveryDate', title: 'Date livraison', display: 'date'},
    //{field: 'installationAt', title: 'Date installation', display: 'date'},
    {field: 'unitPrice', title: 'PU HT Client', display: 'price'},
    {field: 'internalUnitPrice', title: 'PU HT WANO', display: 'price'},
    {field: 'logisticianPrice', title: 'PU Log Client', display: 'price'},
    //{field: 'internalLogisticianPrice', title: 'PU Log WANO', display: 'price'},
    //{field: 'ecotax', title: 'Ecotaxe', display: 'price'},
    //{field: 'deliveryPrice', title: 'Frais de Livraison', display: 'price'},
    {field: 'customerPrice', title: 'Total HT Client', display: 'price'},
    {field: 'wanoPrice', title: 'Total HT WANO', display: 'price'}
  ];
  colsNames = this.cols.map((e) => e.field);
  ambiences: string[];
  sellers: string[];
  status = ProductOrder.STATUS;
  filterForm: AbstractControl;
  private order$;
  private param$;

  constructor(private orderSvc: OrderService,
              private productOrderSvc: ProductOrderService,
              private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
              private form: FormBuilder,
              private datePipe: DatePipe) {
    this.colsNames.push('actions');
  }

  statusSort = () => 0;

  ngOnInit(): void {
    console.log(this.route);
    this.param$ = this.route.paramMap.pipe(
      switchMap(params => {
        return params.getAll('id') || [0];
      })
    ).subscribe((order) => {
      if (order && order !== 0) {
        this.order$ = this.orderSvc.get(order).refetch().then((e: any) => {
          this.order = new Order().fromQuery(e.data.order);
          this.products = this.order.productsOrders;
          this.ambiences = [...new Set(this.products.map(p => p.ambienceName))];
          this.sellers = [...new Set(this.products.map(p => p.sellerLabel))];
          this.sort();
          this.filterForm = this.form.group({
            ambienceName: '',
            sellerLabel: '',
            status: '',
            product: ''
          });

          this.table.filterPredicate = ((data: ProductOrder, filter: any) => {
            return (!filter.ambienceName || data.ambienceName === filter.ambienceName)
              && (!filter.sellerLabel || data.sellerLabel === filter.sellerLabel)
              && (!filter.status || data.status === filter.status)
              && (!filter.product || data.label.toLowerCase().includes(filter.product.toLowerCase()));
          });

          this.filterForm.valueChanges.subscribe(value => this.table.filter = value);
        });
      } else {
        this.router.navigate(['/project']);
      }
    });
  }

  ngOnDestroy(): void {
  }

  edit(product) {
    const dialogRef = this.dialog.open(ProductOrderComponent, {
      width: '60vw',
      data: product
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.order.productsOrders[this.order.productsOrders.indexOf(product)] = result.productOrder;
        this.sort();
      }
    });
  }

  upStatus(product) {
    this.productOrderSvc.upStatus(product.id).subscribe((p: any) => {
      product.fromQuery(p.data.upStatusProductsOrder);
      this.sort();
    });
  }

  sort(e?) {
    this.products = this.products.sort(this.predicateFor(e && e.direction ? e : {active: 'label', direction: 'asc'}));
    this.table.data = this.products;
  }

  generatePdf() {

  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  private predicateFor(e) {
    return e.direction === 'asc' ?
      typeof this.products[0][e.active] === 'string' ?
        (a, b) => a[e.active].localeCompare(b[e.active])
        : (a, b) => a[e.active] - b[e.active]
      : typeof this.products[0][e.active] === 'string' ?
        (a, b) => b[e.active].localeCompare(a[e.active])
        : (a, b) => b[e.active] - a[e.active];
  }
}
