<div class="container" fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.lt-sm="center top">
  <mat-card class="mat-elevation-z4"
            fxFlex="30"
            fxFlex.lt-md="50"
            fxFlex.lt-sm="100">
    <mat-card-title>
      <img class="logo" src="../../assets/images/logo.svg">
    </mat-card-title>
    <mat-card-content>
      <form [formGroup]="signInForm">
        <mat-form-field>
          <mat-label>E-mail</mat-label>
          <input formControlName="email" matInput placeholder="E-mail" type="text"/>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Mot de passe</mat-label>
          <input formControlName="password" matInput placeholder="Mot de passe" type="password"/>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
          <button (click)="onSubmit(signInForm.value)" color="accent" fxFlex mat-flat-button>Connexion</button>
          <button color="accent" (click)="resetPassword()" fxFlex mat-flat-button>Mot de passe oublié</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
