import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbilityService} from '../../_services/ability.service';

@Component({
  selector: 'app-inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss']
})
export class InlineEditComponent implements OnInit {
  @Input() value: any;
  @Input() required = true;
  @Input() static = false;
  @Input() display = undefined;
  @Input() field: any;
  @Input() forceEdit = false;
  @Input() fieldType = 'text';
  @Input() textarea = false;

  @Output() fieldUpdate = new EventEmitter();

  public saved = true;
  public saving = false;
  history: Map<number, any> = new Map();
  private nextSave;

  constructor(public abilitySvc: AbilityService) {
  }

  ngOnInit() {
    this.history.set(Date.now(), this.value);
  }

  save(value) {
    console.log(value);
    this.saved = false;
    clearTimeout(this.nextSave);
    this.nextSave = setTimeout(() => {
      this.saving = true;
      this.fieldUpdate.next(value);
      this.history.set(Date.now(), value);
    }, 1000);
  }

  setValue(value) {
    this.value = value;
    this.save(value);
  }

  hasSaved() {
    this.saving = false;
    this.saved = true;
  }

}
