<div fxLayout="row" fxLayoutAlign="center top" fxLayoutGap="16px" fxLayout.lt-md="column">
  <div fxLayout="column" fxLayoutAlign="center top" fxLayoutGap="16px" fxFlex="49">
    <app-spinner *ngIf="!projectIsLoaded"></app-spinner>
    <mat-card fxFlex>
      <mat-card-content>
        <div *ngIf="sameAddress; else dualAddress">
          <h2>Adresse de facturation et d'installation</h2>
          <p>{{project.address.addressLine1}}</p>
          <p>{{project.address.addressLine2}}</p>
          <p>{{project.address.city}} {{project.address.postalCode}}</p>
        </div>

        <ng-template #dualAddress>
          <div fxFlex>
            <div fxFlex="50">
              <h2>Adresse de facturation</h2>
              <p>{{project.organization.address.addressLine1}}</p>
              <p>{{project.organization.address.addressLine2}}</p>
              <p>{{project.organization.address.city}} {{project.organization.address.postalCode}}</p>
            </div>
            <div fxFlex="50">
              <h2>Adresse de livraison</h2>
              <p>{{project.address.addressLine1}}</p>
              <p>{{project.address.addressLine2}}</p>
              <p>{{project.address.city}} {{project.address.postalCode}}</p>
            </div>
          </div>
        </ng-template>
      </mat-card-content>
    </mat-card>
    <mat-card *ngIf="projectIsLoaded" fxFlex joyrideStep="team.list">
      <mat-card-content>
        <table [dataSource]="teamData" mat-table style="width: 100%">
          <ng-container matColumnDef="badges">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let element" mat-cell style="width: 25%">
              <mat-chip-list>
                <mat-chip *ngIf="element.referent">
                  Référent
                </mat-chip>
                <mat-chip *ngIf="element.subReferent">
                  Co-Référent
                </mat-chip>
              </mat-chip-list>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th *matHeaderCellDef mat-header-cell>Nom</th>
            <td *matCellDef="let element"
                mat-cell> {{element.user.completeName != '' ? element.user.completeName : element.user.email}} </td>
          </ng-container>

          <ng-container matColumnDef="lastSeenAt">
            <th *matHeaderCellDef mat-header-cell>Dernière connexion</th>
            <td *matCellDef="let element"
                mat-cell>{{element.user.lastSeenAt || 'Jamais'}}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let element" mat-cell>
              <div *ngIf="'Project' | asyncCan:'update' | async">
                <mat-menu #actionMenu="matMenu">
                  <button (click)="setReferent(element)"
                          *ngIf="!element.referent"
                          mat-menu-item>
                    Passer référent
                  </button>
                  <button (click)="setSubReferent(element, true)"
                          *ngIf="!element.referent && !element.subReferent"
                          mat-menu-item>
                    Passer co-référent
                  </button>
                  <button (click)="setSubReferent(element, false)"
                          *ngIf="!element.referent && element.subReferent"
                          mat-menu-item>
                    Passer collaborateur
                  </button>
                </mat-menu>
                <button *ngIf="('Project' | asyncCan:'administrate' | async) && !element.referent"
                        [matMenuTriggerFor]="actionMenu"
                        mat-flat-button>
                  Permissions
                </button>
                <button (click)="deleteUser(element)" *ngIf="!element.referent" color="warn" mat-flat-button>
                  Supprimer
                </button>
              </div>
            </td>
          </ng-container>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
        </table>
        <h2 *ngIf="teamData.length === 0" style="text-align: center">Aucun participant</h2>
      </mat-card-content>
      <mat-card-actions>
        <mat-paginator (page)="pagination($event)"
                       [length]="project.projectUsers.length"
                       [pageSize]="pageSize">
        </mat-paginator>
      </mat-card-actions>
    </mat-card>

    <mat-card *ngIf="'Project' | asyncCan:'update' | async"
              content="Indiquez les adresses e-mail de vos collaborateurs (1 par ligne)" fxFlex
              joyrideStep="team.invite" title="Ajouter vos collaborateurs">
      <mat-card-title>Inviter mes collaborateurs</mat-card-title>
      <mat-card-content>
        <form (ngSubmit)="onSubmit(teamForm.value)" [formGroup]="teamForm">
          <mat-form-field>
          <textarea cdkTextareaAutosize
                    formControlName="email" matInput
                    placeholder="E-mail (1 par ligne)"
                    type="text"></textarea>
          </mat-form-field>
          <button color="accent" mat-flat-button type="submit">Inviter</button>
        </form>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="('Project' | asyncCan: 'administrate' | async) && abilitySvc.user.editMode">
      <mat-card-title>Notes (privées)</mat-card-title>
      <mat-card-content>
          <app-spinner *ngIf="!projectIsLoaded"></app-spinner>
          <app-editor (update)="updateNotes($event)"
                      [content]="project.notes" [small]="true"></app-editor>
      </mat-card-content>
    </mat-card>
  </div>

  <div fxFlex="49">
    <app-timeline></app-timeline>
  </div>
</div>
