import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../_services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MustMatch} from '../_validators/must-match';
import {User} from '../_models/user';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {


  resetForm: FormGroup;
  private token: string;

  constructor(private route: ActivatedRoute,
              private authSvc: AuthService,
              private formBuilder: FormBuilder,
              private router: Router,
              private snackBar: MatSnackBar) {
  }

  get f() {
    return this.resetForm.controls;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.token = params.get('passwordToken');

      if (this.token === undefined) {
        this.router.navigate(['/']);
      }

      this.resetForm = this.formBuilder.group({
        password: ['', [Validators.minLength(6), Validators.required]],
        passwordConfirmation: ['', Validators.required]
      }, {
        validators: [MustMatch('password', 'passwordConfirmation')]
      });
    });

    if (this.authSvc.getCurrentUser().id) {
      this.router.navigate(['/project']);
    }
  }

  onSubmit() {
    if (this.resetForm.valid) {
      const data = this.resetForm.value;
      data.passwordToken = this.token;

      this.authSvc.newPassword(data).subscribe((e: any) => {
        if (e.data.newPasswordUser) {
          const user = new User().fromQuery((e.data.newPasswordUser));
          if (user.isAdmin()) {
            user.editMode = true;
            user.showPrices = true;
          }
          this.authSvc.save(user);
          this.router.navigate(['/project']).then(() => location.reload());
        } else {
          this.snackBar.open('Une erreur est survenue');
        }
      });
    }
  }

}
