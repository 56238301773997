<h1>Votre Projet</h1>
<app-spinner *ngIf="!projectIsLoaded"></app-spinner>
<div fxLayout="row wrap">
  <app-proposition-menu (changed)="currentProposition = $event" (duplicated)="addProposition($event)" [project]="project" [propositions]="propositions"
                        fxFlex="100"></app-proposition-menu>
  <div fxFlex="90" fxFlex.xs="100" fxFlexOffset="5" fxFlexOffset.xs="0">
    <div *ngIf="currentProposition"
         fxLayout="row wrap"
         fxLayout.xs="column"
         fxLayoutAlign="center" fxLayoutGap="16px grid">
      <app-ambience (destroy)="deleteAmbience(ambience)" *ngFor="let ambience of currentProposition.ambiences"
                    [ambience]="ambience"
                    fxFlex="0 1 calc(25%)"
                    fxFlex.lt-md="0 1 calc(50%)"
                    fxFlex.lt-xl="0 1 calc(33%)"
                    fxFlex.xs="0 1 calc(100%)"
                    fxLayoutAlign="stretch"></app-ambience>
      <div (click)="createAmbience()" *ngIf="abilitySvc.user.editMode"
           fxFlex="0 1 calc(25%)"
           fxFlex.lt-md="0 1 calc(50%)"
           fxFlex.lt-xl="0 1 calc(33%)"
           fxFlex.xs="0 1 calc(100%)"
           fxLayoutAlign="stretch">
        <mat-card class="new-ambience" fxLayout="column" fxLayoutAlign="center center">
          <mat-card-content>
            <mat-icon fxFlex>add</mat-icon>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
