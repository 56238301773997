<div #mapContainer *ngIf="map && !mapArea" fxLayout="row">
  <div fxFlex="25" fxFlex.lt-md="100">
    <p *ngIf="abilitySvc.user.isAdmin()" class="info" fxHide.lt-md>
      Pour ajouter une zone, cliquez sur le plan et glissez le curseur autour de la zone a placer, comme en utilisant un crayon.
      Une fois les zones délimitées cliquez sur la dénomination du texte pour charger la vue 3D.
    </p>
    <ul class="legend">
      <li (click)="selected.next(mapArea)" *ngFor="let mapArea of map.mapAreas" class="legend-item">
        <span [style.background]="mapArea.color" class="legend-color"></span>
        {{mapArea.name}}
      </li>
    </ul>
  </div>
  <app-map-render (change)="addMapArea()" (delete)="onDeleteMap($event)" [mapContainer]="mapContainer" [map]="map" fxFlex="75"
                  fxHide.lt-md></app-map-render>
</div>

<mat-card *ngIf="map && mapArea" fxFlex="100">
  <mat-card-title>
    {{mapArea.name}}
    <div *ngIf="('Project' | asyncCan:'administrate' | async) && abilitySvc.user.editMode" class="file-select">
      <app-file-select (fileSubmited)="updateImage($event)"></app-file-select>
    </div>
    <button (click)="destroy()" *ngIf="abilitySvc.user.editMode" class="delete-button" mat-flat-button>
      <app-spinner *ngIf="isDeleting"></app-spinner>
      <mat-icon *ngIf="!isDeleting">close</mat-icon>
    </button>
  </mat-card-title>
  <mat-card-content fxLayout="row">
    <div class="map-area-container">
      <div class="map-area-img-container" fxFlex="80">
        <app-spinner *ngIf="isLoading"></app-spinner>
        <app-carousel [images]="mapArea.images" *ngIf="mapArea.images.length > 0" [showDelete]="true" (deleted)="deleteImage($event)"></app-carousel>
        <img class="lazyload blur-up" src='assets/images/placeholders/moodboard.jpg' *ngIf="mapArea.images.length == 0"/>
      </div>
      <div #mapContainer class="map-area-map-container" fxFlex="20">
        <app-map-render [mapArea]="mapArea" [mapContainer]="mapContainer" [map]="map"
                        [staticDisplay]="true"></app-map-render>
      </div>
    </div>
  </mat-card-content>
</mat-card>
