import {Component, OnDestroy, OnInit} from '@angular/core';
import {Project} from '../../_models/project';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {AbilityService} from '../../_services/ability.service';
import {ProjectService} from '../../_services/project.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@AutoUnsubscribe()
@Component({
  selector: 'app-concept',
  templateUrl: './concept.component.html',
  styleUrls: ['./concept.component.scss']
})
export class ConceptComponent implements OnInit, OnDestroy {
  project: Project;
  projectIsLoaded = false;
  private project$;

  constructor(public abilitySvc: AbilityService,
              private projectSvc: ProjectService,
              private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.project$ = this.abilitySvc.project.subscribe((p: Project) => {
      this.project = p;
      this.projectIsLoaded = true;
    });
  }

  ngOnDestroy() {
  }

  updateProject(data: any) {
    this.snackBar.open('Sauvegarde en cours', '', {duration: 4000});
    this.projectSvc.update({id: this.project.id, concept: data}).subscribe((e: any) => {
      this.project.fromQuery(e.data.updateProject);
      this.abilitySvc.saveProject(this.project);
      this.snackBar.open('Sauvegarde effectuée', '', {duration: 4000});
    });
  }
}
