import {Base} from './base';

export class Event extends Base {
  id: number;
  title: string;
  description: string;
  startDate: string;
  endDate: string;

  get outdated() {
    if (this.endDate) {
      return new Date(this.endDate).getTime() < new Date().getTime();
    } else {
      return new Date(this.startDate).getTime() < new Date().getTime();
    }
  }

  fromQuery(response: any) {
    Object.assign(this, response);
    return this;
  }
}
