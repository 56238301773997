<form [formGroup]="promptForm">
  <div mat-dialog-content>
    <span *ngIf="config.message">{{config.message}}</span>
    <mat-form-field *ngIf="config.input">
      <input [placeholder]="config.input" formControlName="input" id="input" matInput matNativeControl required
             type="text"/>
    </mat-form-field>
    <mat-form-field *ngIf="config.select.data">
      <mat-label>Sélectionnez une valeur</mat-label>
      <mat-select formControlName="select" id="select">
        <mat-option>Aucun(e)</mat-option>
        <mat-option
          *ngFor="let option of config.select.data" [value]="option[config.select.value]">
          {{option[config.select.label]}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mcc-color-picker (change)="updateColor($event)"
                      *ngIf="config.color"
                      hideButtons="true"
                      hideUsedColors="true"
                      overlay="false"
                      selectedColor="#ffffff"></mcc-color-picker>
  </div>
  <div mat-dialog-actions>
    <button (click)="onNoClick()" mat-button type="button">Annuler</button>
    <button (click)="onSubmit(promptForm.value)" mat-button type="submit">Valider</button>
  </div>
</form>
