export class Cart {
  id: number;
  label: string;
  productCount: number;

  fromQuery(response: any) {
    Object.assign(this, response);
    return this;
  }
}
