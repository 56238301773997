<h2>
  <app-inline-edit [value]="ambience.name" field="Nom" (fieldUpdate)="updateName($event)" [required]="true" #nameField></app-inline-edit>
</h2>
<span [mat-dialog-close]="true" class="close-button">
    <mat-icon>close</mat-icon>
  </span>
<mat-card class="ambience-card" id="card-{{ambience.id}}">
  <app-spinner *ngIf="updatingImage"></app-spinner>
  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="ambience.comment || (('Project' | asyncCan:'administrate' | async) && abilitySvc.user.editMode); else fullWidthImage">
    <img (error)="$event.target.src='assets/images/placeholders/planche.jpg'" [src]="ambience.moodboardPath.high"
         class="moodboard-img lazyload blur-up" mat-card-image (click)="zoom(ambience.moodboardPath.high)" fxFlex="80">
    <p fxFlex="20">
      <app-inline-edit [value]="ambience.comment" [textarea]="true" field="Commentaire" (fieldUpdate)="updateComment($event)" [required]="false" #commentField></app-inline-edit>
    </p>
  </div>
  <ng-template #fullWidthImage>
    <div fxLayout="row">
      <img (error)="$event.target.src='assets/images/placeholders/planche.jpg'" [src]="ambience.moodboardPath.high"
           class="moodboard-img lazyload blur-up" mat-card-image (click)="zoom(ambience.moodboardPath.high)" fxFlex="100">
    </div>
  </ng-template>
  <mat-card-actions *ngIf="abilitySvc.user.editMode" class="moodboard-form">
    <app-file-select (fileSubmited)="updateImage($event)"></app-file-select>
  </mat-card-actions>
  <mat-card-content>
    <h2 #breadcrumb class="breadcrumb">
      <span (click)="setCluster(null)">NOTRE SELECTION</span>
      <span *ngIf="currentCluster && currentCluster.id"> > {{currentCluster.name}}</span>
    </h2>
    <div [dragulaModel]="ambience.clusters"
         [dragula]="!currentCluster.id && abilitySvc.user.editMode ? dragCluster : null"
         fxLayout="row wrap"
         fxLayout.xs="column"
         fxLayoutAlign="center"
         fxLayoutGap="16px grid">
      <div *ngFor="let cluster of majorClusters"
           [class.full-width]="currentCluster.id === cluster.id"
           [class.hidden]="(currentCluster.id && currentCluster.id !== cluster.id || cluster.products.length === 0) || cluster.suggestion"
           [attr.data-cluster-id]="cluster.id"
           fxFlex="0 1 calc(25%)"
           fxFlex.lt-md="0 1 calc(50%)"
           fxFlex.lt-sm="0 1 calc(100%)">
        <app-cluster (deleted)="removeCluster(cluster)"
                     (selected)="setCluster($event)"
                     (updated)="updateCluster(cluster)"
                     [cluster]="cluster"
                     [collapsed]="currentCluster.id !== cluster.id">
        </app-cluster>
      </div>
      <mat-card *ngIf="abilitySvc.user.editMode && !currentCluster.id"
                class="no-drag"
                fxFlex="0 1 calc(25%)"
                fxFlex.lt-md="0 1 calc(50%)"
                fxFlex.lt-sm="0 1 calc(100%)">
        <mat-card-title>Ajouter des produits</mat-card-title>
        <mat-card-content>
          <app-spinner *ngIf="addingProduct"></app-spinner>
          <form [formGroup]="cartForm">
            <mat-form-field *ngIf="carts">
              <mat-label>Sélectionnez un panier</mat-label>
              <mat-select formControlName="cart" id="cart">
                <mat-option>Aucun</mat-option>
                <mat-option *ngFor="let cart of carts" [value]="cart.id">{{cart.label}}</mat-option>
              </mat-select>
            </mat-form-field>
            <button (click)="importCart(cartForm.value)" color="accent" mat-flat-button type="submit">Importer
            </button>
          </form>
          <form [formGroup]="productForm">
            <mat-form-field>
              <mat-label>BAP ID</mat-label>
              <input formControlName="product" matInput matNativeControl/>
            </mat-form-field>
            <button (click)="importProduct(productForm.value)" color="accent" mat-flat-button type="submit">
              Importer
            </button>
          </form>
          <button (click)="createProduct()" color="accent" mat-flat-button>Créer un produit local</button>
        </mat-card-content>
      </mat-card>
    </div>
    <h2 style="text-align: left; margin-top: 1em;" *ngIf="suggestionClusters.length > 0">
      <span>PRODUITS ALTERNATIFS</span>
    </h2>
    <div [dragulaModel]="ambience.clusters"
         [dragula]="!currentCluster.id && abilitySvc.user.editMode ? dragCluster : null"
         *ngIf="suggestionClusters.length > 0"
         fxLayout="row wrap"
         fxLayout.xs="column"
         fxLayoutAlign="center"
         fxLayoutGap="16px grid">
      <div *ngFor="let cluster of suggestionClusters"
           [class.full-width]="currentCluster.id === cluster.id"
           [class.hidden]="(currentCluster.id && currentCluster.id !== cluster.id || cluster.products.length === 0) || !cluster.suggestion"
           class="no-drag"
           [attr.data-cluster-id]="cluster.id"
           fxFlex="0 1 calc(25%)"
           fxFlex.lt-md="0 1 calc(50%)"
           fxFlex.lt-sm="0 1 calc(100%)">
        <app-cluster (deleted)="removeCluster(cluster)"
                     (selected)="setCluster($event)"
                     (updated)="updateCluster(cluster)"
                     [cluster]="cluster"
                     [collapsed]="currentCluster.id !== cluster.id">
        </app-cluster>
      </div>
    </div>
  </mat-card-content>
</mat-card>

