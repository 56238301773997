import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, Validators} from '@angular/forms';
import {ProductService} from '../../_services/product.service';
import {Cluster} from '../../_models/cluster';
import {Observable} from 'rxjs';
import {AbilityService} from '../../_services/ability.service';

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.scss']
})
export class NewProductComponent implements OnInit {
  productForm;
  public files: string[] = [];

  constructor(public dialogRef: MatDialogRef<NewProductComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: FormBuilder,
              private productSvc: ProductService,
              public abilitySvc: AbilityService) {
    this.abilitySvc.project.subscribe(p => {
      this.productForm = this.formBuilder.group({
        label: ['', Validators.required],
        quantity: [1, Validators.required],
        price: ['', Validators.required],
        brand: [''],
        url: [''],
        files: [''],
        width: [0],
        height: [0],
        depth: [0],
        suggestion: [false],
        category: ['', Validators.required],
        sellerLabel: ['', Validators.required],
        sellerReference: [''],
        logisticianPercentage: [p.logisticianPercentage, Validators.required],
        installationAt: [''],
        ambienceId: [data.ambience.id]
      });
    });
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addFile(files) {
    files.addedFiles.forEach((f) => {
      this.readFile(f).subscribe((file: string) => {
        this.files.push(file);
        this.productForm.patchValue({files: this.files});
      });
    });
  }

  deleteFile(file) {
    this.files.splice(this.files.indexOf(file), 1);
    this.productForm.patchValue({files: this.files});
  }

  onSubmit() {
    if (this.productForm.valid && this.files.length > 0) {
      this.productSvc.create(this.productForm.value).subscribe((e: any) => {
        this.dialogRef.close({cluster: new Cluster().fromQuery(e.data.createProduct)});
      });
    }
  }

  private readFile(file) {
    return new Observable((obs) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        obs.next(reader.result);
        obs.complete();
      }, false);
      reader.readAsDataURL(file);
    });
  }
}
