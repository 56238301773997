import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {formatCurrency} from '@angular/common';

@Pipe({
  name: 'vat'
})
export class VatPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {
  }

  transform(value: number, vat: boolean): string {
    return formatCurrency(vat ? value * 1.2 : value, this.locale, '€') + ' ' + (vat ? 'TTC' : 'HT');
  }

}
