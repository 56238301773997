import {Base} from './base';
import {Quotation} from './quotation';
import {Product} from './product';
import {ProductOrder} from './product-order';

export class Order extends Base {
  id: number;
  validatedAt: string;
  preOrderValidatedAt: string;
  name: string;
  preOrder: boolean;
  quotations: Quotation[];
  productsOrders: ProductOrder[];


  fromQuery(response: any) {
    Object.assign(this, response);
    this.assign(response, 'quotations', 'quotations', Quotation);
    this.assign(response, 'productsOrders', 'productsOrders', ProductOrder);
    return this;
  }

  get slug() {
    return this.name.replace(/[A-Z][a-z]*/g, (match) => `${match.toLowerCase()}-`);
  }
}
