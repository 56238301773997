import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apollo: Apollo) {
  }

  update(input) {
    return this.apollo.mutate({
      mutation: gql`
        mutation updateUser ($input: UserUpdateInput!) {
            updateUser (input: $input) {
            id
            completeName
            lastSeenAt
            role
            }
        }
      `,
      variables: {input}
    });
  }

  getAll() {
    return this.apollo
      .watchQuery({
        query: gql`
        query users {
          users {
            id
            completeName
            lastSeenAt
            role
          }
        }
        `
      });
  }
}
