<form (ngSubmit)="onSubmit()" [formGroup]="projectForm">
  <div mat-dialog-content>
    <div>
      <div>
      <mat-form-field fxFlex="50">
        <mat-label>Organisation</mat-label>
        <mat-select formControlName="organizationId">
          <mat-option
            *ngFor="let organization of data.organizations" [value]="organization.id">
            {{organization.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
        <button mat-flat-button fxFlex="50" (click)="createOrganization()">Créer une organisation</button>
      </div>

      <mat-form-field>
        <mat-label>Nom</mat-label>
        <input autofocus formControlName="name" matInput matNativeControl required="true" type="text"/>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Pourcentage logistique</mat-label>
        <input autofocus formControlName="logisticianPercentage" matInput matNativeControl required="true" type="number"/>
        <mat-hint>Cette valeur sera utilisée par défaut pour tous les produits</mat-hint>
      </mat-form-field>
    </div>
    <span class="expand-spacer"></span>
    <div formGroupName="address">
      <mat-checkbox #sameAddress checked="checked" color="primary">
        L'adresse de livraison est identique a l'adresse de facturation
      </mat-checkbox>
      <div *ngIf="!sameAddress.checked">
        <mat-form-field>
          <mat-label>Adresse de livraison (Ligne 1)</mat-label>
          <input formControlName="address_line_1" matInput matNativeControl type="text"/>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Adresse de livraison (Ligne 2)</mat-label>
          <input formControlName="address_line_2" matInput matNativeControl type="text"/>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Ville de livraison</mat-label>
          <input formControlName="city" matInput matNativeControl type="text"/>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Code Postal de livraison</mat-label>
          <input formControlName="postalCode" matInput matNativeControl type="text"/>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button type="submit">Valider</button>
    <button (click)="onNoClick()" mat-button>Annuler</button>
  </div>
</form>
