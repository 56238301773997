import {Address} from './address';
import {Base} from './base';

export class Organization extends Base {
  id: number;
  name: string;
  logoPath: string;
  logisticianBreakdown: boolean;
  vatPrices: boolean;
  address: Address;

  fromQuery(response: any) {
    Object.assign(this, response);
    return this;
  }
}
