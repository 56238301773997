<mat-toolbar *ngIf="abilitySvc.user && abilitySvc.user.id" color="light" class="mat-elevation-z3">
  <div class="logo-container" fxLayout="row">
    <img (click)="goToHomePage()" class="nav-logo" src="assets/images/logo.svg"/>
    <div *ngIf="currentProject && currentProject.organization && currentProject.organization.logoPath && projectMenu"
         fxLayout="row">
      <mat-icon>clear</mat-icon>
      <img (click)="goToHomePage(currentProject.organization.id)"
           (error)="currentProject.organization.logoPath = undefined"
           [src]="currentProject.organization.logoPath"
           class="nav-logo"/>
    </div>
  </div>
  <div class="menu-toolbar" fxHide.lt-lg="true" fxShow="true">
    <div>
      <span *ngIf="currentProject && projectMenu">
        <a joyrideStep="menu.team" routerLink="/project/team" routerLinkActive="active"
           text="Liste des collaborateurs ayant accès à la plateforme et étapes clés."
           title="Mon projet">
          Projet
        </a>

        <a
          *ngIf="(currentProject.concept.length !== 0 && currentProject.showConcept) || (('Project' | asyncCan: 'administrate' | async) && abilitySvc.user.editMode)"
          joyrideStep="menu.concept"
          routerLink="/project/concept"
          routerLinkActive="active"
          text="Retrouvez ici le concept de votre projet"
          title="Concept">
          Concept
        </a>

        <a
          *ngIf="(currentProject.mapAreas.length !== 0 && currentProject.showMaps) || (('Project' | asyncCan: 'administrate' | async) && abilitySvc.user.editMode)"
          joyrideStep="menu.map"
          routerLink="/project/map"
          routerLinkActive="active"
          text="Retrouvez les plans de votre projet"
          title="Plan">
          Plan
        </a>

        <a
          *ngIf="currentProject.showProducts || ((('Project' | asyncCan: 'administrate' | async) && abilitySvc.user.editMode))"
          joyrideStep="menu.propositions"
          routerLink="/project/propositions"
          routerLinkActive="active"
          title="Nos propositions d'aménagements">
          Propositions
        </a>

        <a
          *ngIf="('Project' | asyncCan:'fullRead' | async) && currentProject.showQuotations && (currentProject.showProducts || (('Project' | asyncCan: 'administrate' | async) && abilitySvc.user.editMode))"
          joyrideStep="menu.quotation"
          routerLink="/project/quotation"
          routerLinkActive="active" title="Retrouvez votre devis">
          Devis
        </a>

        <a
          *ngIf="('Project' | asyncCan: 'fullRead' | async) && currentProject.preOrders.length !== 0 && (currentProject.showProducts || (('Project' | asyncCan: 'administrate' | async) && abilitySvc.user.editMode))"
          joyrideStep="menu.pre_orders" routerLink="/project/pre_orders"
          routerLinkActive="active"
          title="Retrouvez vos pré-commandes">
          Pré-commandes
        </a>

        <a
          *ngIf="('Project' | asyncCan: 'fullRead' | async) && currentProject.completeOrders.length !== 0 && (currentProject.showProducts || (('Project' | asyncCan: 'administrate' | async) && abilitySvc.user.editMode))"
          joyrideStep="menu.orders" routerLink="/project/orders"
          routerLinkActive="active"
          title="Retrouvez vos commandes">
          Commandes
        </a>
      </span>

      <mat-menu #editMenu="matMenu">
        <button (click)="toggleUserDisplay(false, false)"
                *ngIf="abilitySvc.user.editMode || abilitySvc.user.showPrices"
                mat-menu-item>
          Passer en vue <b>sans</b> prix
        </button>
        <button (click)="toggleUserDisplay(false, true)"
                *ngIf="abilitySvc.user.editMode || !abilitySvc.user.showPrices"
                mat-menu-item>
          Passer en vue <b>avec</b> prix
        </button>
        <button (click)="toggleUserDisplay(true, true)"
                *ngIf="!abilitySvc.user.editMode && ('Project' | asyncCan:'administrate' | async)"
                mat-menu-item>
          Passer en vue administrateur
        </button>
      </mat-menu>

      <mat-menu #navMenu="matMenu">
        <button (click)="signOut()" mat-menu-item>Déconnexion</button>
      </mat-menu>

      <button *ngIf="'Project' | asyncCan:'fullRead' | async"
              [color]="(abilitySvc.user.editMode ? 'danger' : 'primary')"
              [matMenuTriggerFor]="editMenu"
              joyrideStep="menu.edit" mat-icon-button
              title="Modifier ma vue">
        <mat-icon>visibility</mat-icon>
      </button>
      <button *ngIf="authSvc.getCurrentUser()" [matMenuTriggerFor]="navMenu" joyrideStep="menu.user" mat-icon-button
              title="Paramètres utilisateurs">
        <mat-icon>account_circle</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="abilitySvc.user.isAdmin() && orders.length > 0 && abilitySvc.user.editMode" class="order-menu">
    <mat-form-field>
      <mat-label>Bons de commande</mat-label>
      <mat-select (selectionChange)="selectOrder($event)">
        <mat-option
          *ngFor="let order of orders" [value]="order.id">
          {{order.name}} - {{order.preOrder ? "Pré-commande" : order.validatedAt | date:'short'}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="(abilitySvc.user.isAdmin() && projects.length > 0 && abilitySvc.user.editMode)" class="project-menu">
    <mat-form-field>
      <mat-label>Projets</mat-label>
      <mat-select (selectionChange)="selectProject($event)" [value]="abilitySvc.user.currentProject">
        <mat-option
          *ngFor="let project of projects" [value]="project.id">
          {{project.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="((!abilitySvc.user.isAdmin() && abilitySvc.user.visibleProjects.length > 1))" class="project-menu">
    <mat-form-field>
      <mat-label>Projets</mat-label>
      <mat-select (selectionChange)="selectProject($event)" [value]="abilitySvc.user.currentProject">
        <mat-option
          *ngFor="let project of abilitySvc.user.visibleProjects" [value]="project.id">
          {{project.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="toggle-menu" fxHide.gt-md="true" fxShow="true">
    <div (click)="sidenav.toggle()"
         [class.is-active]="sidenav.opened"
         class="hamburger hamburger--emphatic">
      <div class="hamburger-box">
        <div class="hamburger-inner"></div>
      </div>
    </div>
  </div>
</mat-toolbar>

<mat-sidenav-container fxFlexFill>
  <mat-sidenav #sidenav fxLayout="column">
    <div *ngIf="authSvc.getCurrentUser() && currentProject"
         fxLayout="column">
      <a routerLink="/project/team" routerLinkActive="active">
        Projet
      </a>

      <a routerLink="/project/concept" routerLinkActive="active">
        Concept
      </a>

      <a routerLink="/project/map" routerLinkActive="active">
        Plan
      </a>

      <a routerLink="/project/propositions" routerLinkActive="active">
        Propositions
      </a>

      <a *ngIf="'Project' | asyncCan:'update' | async" routerLink="/project/quotation" routerLinkActive="active">
        Devis
      </a>

      <span class="expand-spacer"></span>

      <mat-menu #navMenu="matMenu">
        <button mat-menu-item>Paramètres</button>
        <button (click)="signOut()" mat-menu-item>Déconnexion</button>
      </mat-menu>

      <button *ngIf="abilitySvc.user.isAdmin()" [color]="(abilitySvc.user.editMode ? 'primary' : 'danger')"
              mat-icon-button>
        <mat-icon>edit</mat-icon>
      </button>
      <button *ngIf="authSvc.getCurrentUser()" [matMenuTriggerFor]="navMenu" mat-icon-button>
        <mat-icon>account_circle</mat-icon>
      </button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
    <div class="spacer"></div>
  </mat-sidenav-content>
</mat-sidenav-container>
