import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Project} from '../../_models/project';
import {PropositionService} from '../../_services/proposition.service';
import {Proposition} from '../../_models/proposition';
import {AmbienceService} from '../../_services/ambience.service';
import {Ambience} from '../../_models/ambience';
import {MatDialog} from '@angular/material/dialog';
import {MatTabGroup} from '@angular/material/tabs';
import {AbilityService} from '../../_services/ability.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {CreateAmbienceComponent} from './create-ambience/create-ambience.component';

@AutoUnsubscribe()
@Component({
  selector: 'app-proposition',
  templateUrl: './proposition.component.html',
  styleUrls: ['./proposition.component.scss']
})
export class PropositionComponent implements OnInit, OnDestroy {
  @ViewChild('matTabGroup') matTabGroup: MatTabGroup;
  project: Project;
  currentProposition: Proposition;
  projectIsLoaded = false;
  propositions = [];
  private proposition$;

  constructor(public abilitySvc: AbilityService,
              private propositionSvc: PropositionService,
              private ambienceSvc: AmbienceService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.abilitySvc.resolveProject();
    this.abilitySvc.project.subscribe((p) => {
      this.project = p;
      this.proposition$ = this.propositionSvc.get(p.id).refetch().then((e: any) => {
        p.fromQuery(e.data.project);
        this.currentProposition = p.propositions[0];
        this.projectIsLoaded = true;
        this.setPropositions();
      });
    }).unsubscribe();
  }

  ngOnDestroy() {
  }

  setPropositions() {
    this.propositions = this.project.propositions.map((p) => {
      return {proposition: p, display: (p.ambiences.length > 0)};
    });
  }

  createAmbience() {
    const dialogRef = this.dialog.open(CreateAmbienceComponent, {
        width: '350px',
        data: this.project.mapAreas
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.value !== '') {
          this.ambienceSvc.create(this.currentProposition.id, result.value).subscribe((e: any) => {
            this.currentProposition.ambiences.push(new Ambience().fromQuery(e.data.createAmbience));
          });
        }
      }
    });
  }

  deleteAmbience(ambience: Ambience) {
    this.currentProposition.ambiences.splice(this.currentProposition.ambiences.indexOf(ambience), 1);
    this.abilitySvc.resolveProject();
    this.setPropositions();
  }

  addProposition(proposition: Proposition) {
    this.propositions.push(proposition);
  }
}
