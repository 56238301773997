<div class="container" fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.lt-sm="center top">
  <mat-card *ngIf="!abilitySvc.user.isAdmin() && project" class="disclaimer mat-elevation-z4"
            fxFlex="50"
            fxFlex.lt-md="100"
            fxFlex.lt-sm="100">
    <h2>Bonjour !</h2>
    <p *ngIf="project.referent">
      Vous avez été invité(e) par {{project.referent.user.completeName}} sur la plateforme What A Nice Office,
      sur le projet d'aménagement {{project.name}}.
    </p>
    <p *ngIf="!project.referent">
      Vous avez été invité(e) sur la plateforme What A Nice Office, sur le projet d'aménagement {{project.name}}.
    </p>
    <p>
      Cette plateforme vous permettra de participer au projet de construction et de l'aménagement de vos nouveaux
      espaces
      de travail,
      de voir nos recommendations et d'intéragir avec toute l'équipe de What A Nice Office.
    </p>
    <p>
      Nous sommes heureux de vous accompagner sur ce beau projet !
    </p>

<!--   <button (click)="startTour()" mat-button>Visite guidée</button>-->
  </mat-card>
</div>

<div *ngIf="abilitySvc.user.isAdmin()" fxLayout="column" fxLayoutGap="16px">
  <div fxLayout="row wrap"
       fxLayout.lt-md="column" fxLayoutAlign="center stretch" fxLayoutAlign.lt-md="center top" fxLayoutGap="16px grid">
    <app-card-table
      #organizationTable
      (delete)="deleteOrganization($event)"
      (select)="selectOrganization($event.id)"
      (update)="editOrganization($event)"
      [cols]="organizationCols"
      [filterPredicate]="organizationPredicate"
      [mapper]="organizationMapper"
      [selected]="organization"
      [service]="organizationSvc"
      fxFlex="0 1 calc(50%)" fxFlex.lt-md="100" title="Organisations"></app-card-table>
    <app-card-table
      #projectTable
      (create)="createProject()"
      (delete)="deleteProject($event)"
      (select)="selectProject($event.id)"
      (publish)="publishProject($event)"
      (update)="editProject($event)"
      [cols]="projectCols"
      [filterPredicate]="projectPredicate"
      [mapper]="projectMapper"
      [scope]="projectScope"
      [selected]="project"
      [service]="projectSvc"
      fxFlex="0 1 calc(50%)" fxFlex.lt-md="100" title="Projets"></app-card-table>
    <app-card-table
      #orderTable
      (select)="showOrder($event)"
      [cols]="orderCols"
      [filterPredicate]="orderPredicate"
      [mapper]="orderMapper"
      [service]="orderSvc"
      fxFlex="0 1 calc(50%)" fxFlex.lt-md="100" title="Commandes"
    ></app-card-table>
    <app-card-table
      #userTable
      (update)="editUser($event)"
      [cols]="userCols"
      [filterPredicate]="userPredicate"
      [mapper]="userMapper"
      [service]="userSvc"
      fxFlex="0 1 calc(50%)" fxFlex.lt-md="100" title="Utilisateurs"
    ></app-card-table>
  </div>
</div>
