import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {MatDialog} from '@angular/material/dialog';
import {CarouselModalComponent} from './carousel-modal/carousel-modal.component';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        animate('300ms', style({opacity: 1}))
      ]),
      transition('* => void', [
        animate('300ms', style({opacity: 0}))
      ])
    ])
  ]
})
export class CarouselComponent implements OnInit {
  @Input() images: string[];
  @Input() zoomOnClick = true;
  @Input() showDelete = false;
  @Output() deleted: EventEmitter<string> = new EventEmitter<string>();

  currentSlide = 0;

  constructor(private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.preloadImages();
  }

  deleteElement(idx, url) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Voulez-vous vraiment supprimer cette image ?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.images.splice(idx, 1);
        this.currentSlide = 0;
        this.deleted.emit(url);
      }
    });
  }

  preloadImages() {
    let image;
    for (image of this.images) {
      new Image().src = image;
    }
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.images.length - 1 : previous;
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.images.length ? 0 : next;
  }

  zoom() {
    if (this.zoomOnClick) {
      const dialogRef = this.dialog.open(CarouselModalComponent, {
          width: '90vw',
          data: this.images
        }
      );
    }
  }

}
