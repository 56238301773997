<div *ngIf="collapsed" class="mat-elevation-z1">
  <app-product
    (destroy)="removeProduct(displayProduct)"
    [product]="displayProduct"
    (update)="updateProduct($event)"></app-product>
  <div (click)="selected.emit(cluster)"
       *ngIf="cluster.products.length > 1"
       class="more-products">
    Voir plus de produits ({{cluster.products.length - 1}})
  </div>
</div>
<div *ngIf="!collapsed"
     [dragulaModel]="cluster.products"
     [dragula]="abilitySvc.user.editMode ? dragProduct : null"
     class="mat-elevation-z1"
     fxLayout="row wrap"
     fxLayout.xs="column"
     fxLayoutAlign="center" fxLayoutGap="16px grid">
  <app-product (destroy)="removeProduct(product)"
               (selectedProduct)="setSelectedProduct(product)"
               (update)="updateProduct($event)"
               *ngFor="let product of cluster.products"
               [class.no-drag]="cluster.selectedProduct.id === product.id"
               [product]="product"
               [star]="cluster.selectedProduct.id === product.id"
               fxFlex="0 1 calc(25%)"
               fxFlex.lt-md="0 1 calc(50%)"
               fxFlex.lt-sm="0 1 calc(100%)"
               fxFlex.lt-xl="0 1 calc(33%)"
  ></app-product>
</div>
