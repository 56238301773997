import {Injectable, OnDestroy} from '@angular/core';
import {Project} from '../_models/project';
import {AuthService} from './auth.service';
import {ProjectService} from './project.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {defineAbilityFor} from '../_abilities/project.ability';
import {User} from '../_models/user';
import {ReplaySubject} from 'rxjs';
import {Ability} from '@casl/ability';

@AutoUnsubscribe()
@Injectable({
  providedIn: 'root'
})
export class AbilityService implements OnDestroy {
  user: User;
  private project$ = new ReplaySubject<Project>(1);
  private ability$ = new ReplaySubject<Ability>(1);
  private projectResolved = false;

  constructor(private authSvc: AuthService,
              private projectSvc: ProjectService) {
    this.user = this.authSvc.getCurrentUser();
    this.authSvc.currentUserSubject.subscribe((user) => {
      this.user = user;
    });
  }

  get project() {
    if (!this.projectResolved) {
      this.resolveProject();
    }
    return this.project$;
  }

  get ability() {
    return this.ability$;
  }

  ngOnDestroy(): void {
  }

  setProject(projectId) {
    this.user.currentProject = projectId;
    this.resolveProject();
    this.authSvc.save(this.user);
  }

  setEditMode(status: boolean) {
    this.user.editMode = status;
    this.authSvc.save(this.user);
  }

  setShowPrices(status: boolean) {
    this.user.showPrices = status;
    this.authSvc.save(this.user);
  }

  resolveProject() {
    this.projectSvc.get(this.user.currentProject).refetch().then((e: any) => {
      if (e.errors) {
        this.project$.next(undefined);
        this.resolveAbility();
      } else {
        this.project$.next(new Project().fromQuery(e.data.project));
        this.resolveAbility();
      }
      this.projectResolved = true;
    });
  }

  saveProject(project: Project) {
    return new Promise<Project>((resolve) => {
      this.project$.next(project);
      this.resolveAbility();
      resolve(project);
    });
  }

  resolveAbility() {
    this.project.subscribe((project: any) => {
      if (project === undefined) {
        this.ability$.next(undefined);
      } else {
        defineAbilityFor(this.user, project).then((ability) => this.ability$.next(ability));
      }
    });
  }
}
