import {Component, OnDestroy, OnInit} from '@angular/core';
import {Project} from '../../_models/project';
import {FormBuilder} from '@angular/forms';
import {ProjectUserService} from '../../_services/project-user.service';
import {ConfirmationDialogComponent} from '../../shared/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {AbilityService} from '../../_services/ability.service';
import {ProjectUser} from '../../_models/project-user';
import {ProjectService} from '../../_services/project.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit, OnDestroy {
  project: Project = undefined;
  teamForm;
  pageSize = 10;
  teamData: ProjectUser[];
  sameAddress = true;
  displayedColumns: string[] = ['badges', 'name', 'lastSeenAt'];
  projectIsLoaded = false;
  private projectUsers$;

  constructor(private formBuilder: FormBuilder,
              private projectUserSvc: ProjectUserService,
              private projectSvc: ProjectService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              public abilitySvc: AbilityService) {
  }

  onSubmit(data) {
    data.projectId = this.project.id;
    this.projectUserSvc.create(data).subscribe((e: any) => {
      e.data.createProjectUser.map((p) => this.project.projectUsers.push(new ProjectUser().fromQuery(p)));
      this.abilitySvc.saveProject(this.project).then(() => this.pagination({pageIndex: 0}));
    });
  }

  ngOnInit() {
    this.teamForm = this.formBuilder.group({
      email: ''
    });

    this.abilitySvc.project.subscribe((p: Project) => {
      this.project = p;
      this.projectUsers$ = this.projectUserSvc.get(p.id).refetch().then((e: any) => {
        this.project.fromQuery(e.data.project);
        this.projectIsLoaded = true;
        this.pagination({pageIndex: 0});
        this.sameAddress = this.project.address.addressLine1 === this.project.organization.address.addressLine1 &&
          this.project.address.addressLine2 === this.project.organization.address.addressLine2 &&
          this.project.address.city === this.project.organization.address.city &&
          this.project.address.postalCode === this.project.organization.address.postalCode;
      });
    }).unsubscribe();


    this.abilitySvc.ability.subscribe((e) => {
      this.updateColumns(e);
    });
  }

  pagination(event) {
    const startIx = this.pageSize * event.pageIndex;
    let endIx = this.pageSize + startIx;
    if (endIx > event.length) {
      endIx = event.length;
    }
    this.teamData = this.project.projectUsers.slice(startIx, endIx);
  }

  ngOnDestroy() {
  }

  updateColumns(ability) {
    if (ability.can('update', 'Project')) {
      if (!this.displayedColumns.includes('actions')) {
        this.displayedColumns.push('actions');
      }
    } else {
      this.displayedColumns = ['badges', 'name', 'lastSeenAt'];
    }
  }

  setReferent(user: ProjectUser) {
    this.projectUserSvc.setReferent(user.id).subscribe((e: any) => {
      if (this.project.referent) {
        this.project.referent.referent = false;
      }
      user.fromQuery(e.data.setReferentProjectUser);
    });
  }

  setSubReferent(user: ProjectUser, status) {
    this.projectUserSvc.setSubReferent(user.id, status).subscribe((e: any) => {
      user.fromQuery(e.data.setSubReferentProjectUser);
    });
  }

  deleteUser(user) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Voulez-vous vraiment retirer cette personne du projet ?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.projectUserSvc.deleteUser(user.id).subscribe((data: any) => {
          if (data.errors) {
            this.snackBar.open('Une erreur est survenue', 'Oh oh', {duration: 3000});
          } else {
            this.snackBar.open('Utilisateur supprimé !', 'Cool', {duration: 3000});
            this.project.projectUsers.splice(this.project.projectUsers.indexOf(user), 1);
            this.abilitySvc.saveProject(this.project).then(() => this.pagination({pageIndex: 0}));
          }
        });
      }
    });
  }

  updateNotes(data: any) {
    this.snackBar.open('Sauvegarde en cours', '', {duration: 4000});
    this.projectSvc.update({id: this.project.id, notes: data}).subscribe((e: any) => {
      this.project.fromQuery(e.data.updateProject);
      this.abilitySvc.saveProject(this.project);
      this.snackBar.open('Sauvegarde effectuée', '', {duration: 4000});
    });
  }

}
