import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {AuthService} from '../_services/auth.service';
import {Router} from '@angular/router';
import {User} from '../_models/user';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PromptDialogComponent} from '../shared/prompt-dialog/prompt-dialog.component';
import {MapArea} from '../_models/map-area';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  signInForm;

  constructor(private formBuilder: FormBuilder,
              private authSvc: AuthService,
              private router: Router,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) {
    if (this.authSvc.getCurrentUser() && this.authSvc.getCurrentUser().id) {
      this.router.navigate(['/project']);
    }

    this.signInForm = this.formBuilder.group({
      email: '',
      password: ''
    });
  }

  onSubmit(formData) {
    this.authSvc.login(formData.email, formData.password).subscribe((e: any) => {
        if (e.data.signInUser) {
          const user = new User().fromQuery((e.data.signInUser));
          if (user.isAdmin()) {
            user.editMode = true;
            user.showPrices = true;
          }
          this.authSvc.save(user);
          location.reload();
        } else {
          this.snackBar.open('Identifiants incorrect');
        }
      }
    );
  }

  resetPassword() {
    const dialogRef = this.dialog.open(PromptDialogComponent, {
        width: '350px',
        data: {
          message: 'Réinitialisation du mot de passe',
          input: 'E-mail'
        }
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.authSvc.resetPassword(result.input).subscribe((d: any) => {
          this.snackBar.open('Un e-mail avec les instructions pour réinitialiser votre mot de passe à été envoyé.');
        });
      }
    });
  }

  ngOnInit() {
  }

}
