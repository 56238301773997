<form (ngSubmit)="onSubmit()" [formGroup]="userForm">
  <div mat-dialog-content>
    <div>
      <mat-form-field>
        <mat-label>Rôle</mat-label>
        <mat-select formControlName="role">
          <mat-option value="user">Utilisateur</mat-option>
          <mat-option value="admin">Administrateur</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button type="submit">Valider</button>
    <button (click)="onNoClick()" mat-button>Annuler</button>
  </div>
</form>
