import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-file-select',
  templateUrl: './file-select.component.html',
  styleUrls: ['./file-select.component.scss']
})
export class FileSelectComponent implements OnInit {
  @Input() showSubmit = true;
  @Output() fileSubmited = new EventEmitter();
  fileForm;
  file;
  filename = '';

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.fileForm = this.formBuilder.group({
      filename: ''
    });
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.file = reader.result;
        this.filename = event.target.files[0].name;

        if (!this.showSubmit) {
          this.fileSubmited.next(this.file);
        } else {
          this.onSubmit();
        }
      }, false);
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onSubmit() {
    this.fileSubmited.next({file: this.file, filename: this.filename});
    this.file = undefined;
    this.filename = '';
  }

}
