<mat-card (mouseenter)="load()" *ngIf="ambience" class="ambiences-card" id="card-{{ambience.id}}">
  <button (click)="delete()" *ngIf="abilitySvc.user.editMode" class="delete-button" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <app-spinner *ngIf="!isAmbienceLoaded"></app-spinner>
  <img (click)="show()" (error)="$event.target.src='assets/images/placeholders/planche.jpg'" mat-card-image [src]="ambience.moodboardPath.high">
  <mat-card-footer *ngIf="isAmbienceLoaded">
    {{ambience.name}}
  </mat-card-footer>
</mat-card>
