import {ProjectUser} from './project-user';
import {User} from './user';
import {Proposition} from './proposition';
import {MapArea} from './map-area';
import {Organization} from './organization';
import {Base} from './base';
import {Order} from './order';
import {Event} from './event';
import {Address} from './address';

export class Project extends Base {
  id: number;
  name: string;
  mapAreas: MapArea[];
  publishedAt: string;
  projectUsers: ProjectUser[];
  propositions: Proposition[];
  organization: Organization;
  showQuotations: boolean;
  showConcept: boolean;
  showMaps: boolean;
  showProducts: boolean;
  orders: Order[];
  events: Event[];
  address: Address;
  installationAt: string;
  concept: any;
  notes: any;
  logisticianPercentage: number;

  get referent() {
    return this.projectUsers.find((projectUser) => projectUser.referent) || undefined;
  }

  get organizationName() {
    return this.organization.name;
  }

  get published() {
    return !!this.publishedAt;
  }

  get slug() {
    return `${this.name}`.replace(/[A-Z][a-z]*/g, (match) => `${match.toLowerCase()}-`);
  }

  get preOrders() {
    return this.orders.filter((order) => order.preOrder);
  }

  get completeOrders() {
    return this.orders.filter((order) => !order.preOrder);
  }

  isMember(user: User) {
    return this.projectUsers.map((projectUser) => projectUser.user.id).indexOf(user.id) !== -1;
  }

  isReferent(user: User) {
    return this.referent === undefined ? false : this.referent.user.id === user.id;
  }

  isSubReferent(user: User) {
    return this.projectUsers.filter((projectUser) => projectUser.subReferent).map((projectUser) => projectUser.user.id).indexOf(user.id) !== -1;
  }


  fromQuery(response: any) {
    Object.assign(this, response);
    this.assign(response, 'mapAreas', 'mapAreas', MapArea);
    this.assign(response, 'projectUsers', 'projectUsers', ProjectUser);
    this.assign(response, 'propositions', 'propositions', Proposition);
    this.assign(response, 'orders', 'orders', Order);
    this.assign(response, 'events', 'events', Event);
    this.sort('mapAreas', (a, b) => a.fullName.localeCompare(b.fullName));
    return this;
  }
}
