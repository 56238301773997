import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Base} from '../../_models/base';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {QueryRef} from 'apollo-angular';

@AutoUnsubscribe()
@Component({
  selector: 'app-card-table',
  templateUrl: './card-table.component.html',
  styleUrls: ['./card-table.component.scss']
})
export class CardTableComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() pageSize = 10;
  @Input() cols: { field: string, title: string, display: string }[];
  @Input() filterPredicate = undefined;
  @Input() scope = undefined;
  @Input() mapper;
  @Input() selected: Base;
  @Input()
  showCreate = false;
  showDelete = false;
  showUpdate = false;
  showSelect = false;
  showPublish = false;
  colsNames: string[];
  isLoaded = false;
  table: MatTableDataSource<Base> = new MatTableDataSource<Base>();
  @Output() data = this.table.data;
  @Output() create = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() select = new EventEmitter();
  @Output() publish = new EventEmitter();
  @ViewChild('paginator') private paginator: MatPaginator;
  @Input() private service;
  private subscription$: QueryRef<any>;

  constructor() {
  }

  ngOnInit(): void {
    this.showCreate = this.create.observers.length > 0;
    this.showDelete = this.delete.observers.length > 0;
    this.showUpdate = this.update.observers.length > 0;
    this.showSelect = this.select.observers.length > 0;
    this.showPublish = this.publish.observers.length > 0;

    this.table.filterPredicate = this.filterPredicate;
    this.colsNames = this.cols.map((e) => e.field);

    if (this.showSelect) {
      this.colsNames.unshift('show');
    }

    if (this.showDelete || this.showPublish || this.showUpdate) {
      this.colsNames.push('actions');
    }
    this.subscription$ = this.service.getAll();
    this.subscription$.refetch().then(({data}: any) => this.assign(data));
  }

  ngOnDestroy(): void {
  }

  filter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.table.filter = filterValue.trim().toLowerCase();
  }

  refresh() {
    this.subscription$.refetch().then(({data}: any) => this.assign(data));
  }

  private assign(rawData) {
    let data = this.mapper(rawData);
    if (this.scope) {
      data = data.filter(this.scope);
    }

    this.table.data = data;
    this.data = this.table.data;

    this.table.paginator = this.paginator;
    this.isLoaded = true;
  }
}
