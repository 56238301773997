import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class ClusterService {

  constructor(private apollo: Apollo) {
  }

  setPosition(id, position) {
    return this.apollo.mutate({
      mutation: gql`
        mutation setPositionCluster ($id: ID!, $position: Int!) {
            setPositionCluster (id: $id, position: $position) {
              clusters {
                id
                position
              }
            }
        }
      `,
      variables: {
        id, position
      }
    });
  }

  setProduct(id, product) {
    return this.apollo.mutate({
      mutation: gql`
        mutation setProductCluster ($id: ID!, $product: ID!) {
            setProductCluster (id: $id, product: $product) {
              id
              product {
                id
              }
              products {
                id
                position
              }
            }
        }
      `,
      variables: {
        id, product
      }
    });
  }
}
