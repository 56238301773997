import {Injectable} from '@angular/core';
import gql from 'graphql-tag';
import {Apollo} from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class PropositionService {

  constructor(private apollo: Apollo) {
  }

  get(id) {
    return this.apollo
      .watchQuery({
        query: gql`
        query project ($id: ID!) {
            project (id: $id) {
                id
                name
                propositions {
                  id
                  name
                  ultimate
                  mapDisplay
                  ambiences {
                    id
                    name
                    moodboardPath
                  }
                }
            }
        }
        `,
        variables: {
          id
        }
      });
  }

  getWithQuotations(id) {
    return this.apollo
      .watchQuery({
        query: gql`
        query project ($id: ID!) {
            project (id: $id) {
                id
                name
                propositions {
                  id
                  name
                  ultimate
                  mapDisplay
                  quotations {
                    ambienceName
                    products {
                      id
                      label
                      price
                      sellerLabel
                      category
                      sellerReference
                      logisticianPrice
                      logisticianPercentage
                      bapId
                      status
                      images
                      delayPurchase
                      quantity
                      installationAt
                      dimensions
                      url
                    }
                  }
                }
            }
        }
        `,
        variables: {
          id
        }
      });
  }

  create(name, projectId, mapDisplay) {
    return this.apollo.mutate({
      mutation: gql`
        mutation createProposition ($name: String!, $projectId: ID!, $mapDisplay: Boolean) {
            createProposition (name: $name, projectId: $projectId, mapDisplay: $mapDisplay) {
              id
              name
              ultimate
              mapDisplay
            }
        }
      `,
      variables: {
        name,
        projectId,
        mapDisplay
      }
    });
  }

  duplicate(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation duplicateProposition($id: ID!) {
          duplicateProposition(id: $id) {
            id
            name
            ultimate
            mapDisplay
            ambiences {
              id
              name
              moodboardPath
            }
          }
        }
      `,
      variables: {
        id
      }
    });
  }

  delete(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation destroyProposition ($id: ID!) {
            destroyProposition (id: $id) {
              id
            }
        }
      `,
      variables: {id}
    });
  }

  update(input) {
    return this.apollo.mutate({
      mutation: gql`
        mutation updateProposition ($input: PropositionUpdateInput!) {
            updateProposition (input: $input) {
              id
              name
              ultimate
              mapDisplay
              ambiences {
                id
                name
                moodboardPath
              }
            }
        }
      `,
      variables: {
        input
      }
    });
  }

  confirmOrder(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation confirmOrderProposition ($id: ID!) {
            confirmOrderProposition (id: $id) {
                id
            }
        }
      `,
      variables: {
        id
      }
    });
  }

  pdf(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation pdfProposition ($id: ID!) {
            pdfProposition (id: $id) {
              blob
            }
        }
      `,
      variables: {
        id
      }
    });
  }
}
