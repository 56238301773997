import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Organization} from '../../_models/organization';
import {ProjectService} from '../../_services/project.service';
import {FormBuilder, Validators} from '@angular/forms';
import {Project} from '../../_models/project';
import {NewOrganizationComponent} from '../../organizations/new-organization/new-organization.component';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class NewProjectComponent implements OnInit {
  projectForm;

  constructor(public dialogRef: MatDialogRef<NewProjectComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: { organizations: Organization[], currentOrganization: number },
              private projectSvc: ProjectService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.projectForm = this.formBuilder.group({
      name: ['', Validators.required],
      organizationId: [this.data.currentOrganization.toString(), Validators.required],
      logisticianPercentage: [12, Validators.required],
      address: this.formBuilder.group({
        address_line_1: [''],
        address_line_2: [''],
        city: [''],
        postalCode: [''],
      })
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  createOrganization() {
    const dialogRef = this.dialog.open(NewOrganizationComponent, {
      width: '40vw'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.data.organizations.push(result.organization);
        this.projectForm.controls['organizationId'].setValue(result.organization.id);
      }
    });
  }

  onSubmit() {
    if (this.projectForm.valid) {
      this.projectSvc.create(this.projectForm.value).subscribe((e: any) => {
        this.dialogRef.close({project: new Project().fromQuery(e.data.createProject)});
      });
    }
  }

}
