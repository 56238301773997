import {User} from './user';

export class Product {
  id: number;
  label: string;
  bapId: string;
  quantity: number;
  price: number;
  logisticianPrice: number;
  delayPurchase: boolean;
  suggestion: boolean;
  status: string;
  images: string[];
  installationAt: string;
  dimensions: string;
  position: number;
  category: string;
  usersVotes: { likes: number[], dislikes: number[] };
  sellerLabel: string;
  sellerReference: string;
  logisticianPercentage: number;
  brand: string;
  url: string;
  width: number;
  height: number;
  depth: number;
  ambienceName: string;

  get rawPrice() {
    return this.price - this.logisticianPrice;
  }

  get image() {
    return this.images[0];
  }

  get total() {
    return this.price * this.quantity;
  }

  get likes() {
    return this.usersVotes.likes.length || 0;
  }

  get dislikes() {
    return this.usersVotes.dislikes.length || 0;
  }

  isApproved() {
    return this.status === 'approved' || false;
  }

  fromQuery(response: any) {
    Object.assign(this, response);
    return this;
  }

  liked_by(user: User) {
    return this.usersVotes.likes.indexOf(parseInt(String(user.id), 10)) !== -1;
  }

  disliked_by(user: User) {
    return this.usersVotes.dislikes.indexOf(parseInt(String(user.id), 10)) !== -1;
  }
}

