import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable, Subscriber} from 'rxjs';
import {AbilityService} from '../_services/ability.service';
import {Ability} from '@casl/ability';

@Injectable({
  providedIn: 'root'
})
export class ProjectFullReadGuard implements CanActivate {
  constructor(public abilitySvc: AbilityService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Observable<boolean>((observer: Subscriber<boolean>) => {
      this.abilitySvc.ability.subscribe((ability: Ability) => {
        observer.next(ability.can('fullRead', 'Project'));
      });
    });
  }

}
