import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatTabGroup} from '@angular/material/tabs';
import {Project} from '../../_models/project';
import {PromptDialogComponent} from '../prompt-dialog/prompt-dialog.component';
import {Proposition} from '../../_models/proposition';
import {MatDialog} from '@angular/material/dialog';
import {PropositionService} from '../../_services/proposition.service';
import {AbilityService} from '../../_services/ability.service';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-proposition-menu',
  templateUrl: './proposition-menu.component.html',
  styleUrls: ['./proposition-menu.component.scss']
})
export class PropositionMenuComponent implements OnInit {
  values: [{ proposition: Proposition, display: boolean }];
  @ViewChild('matTabGroup') matTabGroup: MatTabGroup;
  @Input() project: Project;
  @Input() static = false;
  @Input() showUltimate = false;
  @Input() mapDisplay = false;
  @Output() changed: EventEmitter<Proposition> = new EventEmitter<Proposition>();
  @Output() duplicated: EventEmitter<Proposition> = new EventEmitter<Proposition>();
  isDeleting = undefined;
  @Input() currentProposition: Proposition;
  showMenu = true;

  constructor(private dialog: MatDialog,
              private propositionSvc: PropositionService,
              public abilitySvc: AbilityService) {
  }

  @Input() set propositions(propositions: [{ proposition: Proposition, display: boolean }]) {
    this.values = propositions.sort((a, b) => a.proposition.name.localeCompare(b.proposition.name))
      .sort((a, b) => Number(a.proposition.ultimate) - Number(b.proposition.ultimate));

    // @ts-ignore
    this.values = this.values.filter(p => p.proposition.mapDisplay === this.mapDisplay || p.proposition.mapDisplay === null);

    if (this.currentProposition !== undefined) {
      this.setCurrentProposition(this.currentProposition);
    } else if (propositions.filter(p => p.display).length > 0) {
      this.setCurrentProposition(propositions.filter(p => p.display)[0].proposition);
    }
  }

  ngOnInit(): void {
  }

  createProposition() {
    const dialogRef = this.dialog.open(PromptDialogComponent, {
        width: '350px',
        data: {
          message: 'Ajout d\'une proposition',
          input: 'Nom'
        }
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.propositionSvc.create(result.input, this.project.id, this.mapDisplay).subscribe((e: any) => {
          const proposition = new Proposition().fromQuery(e.data.createProposition);
          this.project.propositions.push(proposition);
          this.abilitySvc.saveProject(this.project).then(() => {
            this.setCurrentProposition(proposition);
            this.values.push({proposition, display: true});
          });
        });
      }
    });
  }

  setCurrentProposition(proposition: Proposition) {
    this.currentProposition = proposition;
    this.changed.next(proposition);
    this.showMenu = this.values.filter((p) => p.display).length > 1;
  }

  destroy(value: { proposition: Proposition, display: boolean }) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Voulez-vous vraiment supprimer cette proposition ?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isDeleting = value.proposition.id;
        this.propositionSvc.delete(value.proposition.id).subscribe(() => {
          this.project.propositions.splice(this.project.propositions.indexOf(value.proposition), 1);
          this.abilitySvc.saveProject(this.project).then(() => {
            this.setCurrentProposition(this.project.propositions[0]);
            this.isDeleting = undefined;
            this.values.splice(this.values.indexOf(value), 1);
          });
        });
      }
    });
  }

  duplicate(elem: Proposition) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Voulez-vous vraiment dupliquer cette proposition ?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.propositionSvc.duplicate(elem.id).subscribe((e: any) => {
          const proposition = new Proposition().fromQuery(e.data.duplicateProposition);
          this.project.propositions.push(proposition);
          this.abilitySvc.saveProject(this.project).then(() => {
            this.setCurrentProposition(proposition);
            location.reload();
          });
          this.duplicated.next(proposition);
        });
      }
    });
  }

  update(elem: Proposition) {
    const dialogRef = this.dialog.open(PromptDialogComponent, {
        width: '350px',
        data: {
          message: 'Nom de la proposition',
          input: elem.name
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.propositionSvc.update({id: elem.id, name: result.input}).subscribe((e: any) => {
          location.reload();
        });
      }
    });
  }

}
