import {User} from './user';
import {Base} from './base';

export class ProjectUser extends Base {
  id: number;
  referent: boolean;
  subReferent: boolean;
  user: User;

  fromQuery(response: any) {
    Object.assign(this, response);
    this.user = response.user ? new User().fromQuery(response.user) : this.user;
    return this;
  }
}
