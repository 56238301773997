import {Component, OnDestroy, OnInit} from '@angular/core';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Project} from '../../_models/project';
import {DatePipe} from '@angular/common';
import {AbilityService} from '../../_services/ability.service';
import {ProjectService} from '../../_services/project.service';
import {OrderService} from '../../_services/order.service';
import {Order} from '../../_models/order';
import {QuotationCsvService} from '../../_exports/quotation-csv.service';
import {Router} from '@angular/router';

@AutoUnsubscribe()
@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit, OnDestroy {
  project: Project;
  ordersIsLoaded = false;
  orders: Order[];
  menuLoading = false;
  private order$;

  constructor(private orderSvc: OrderService,
              private datePipe: DatePipe,
              public abilitySvc: AbilityService,
              private projectSvc: ProjectService,
              private router: Router,
              private quotationCsvSvc: QuotationCsvService) {
  }

  ngOnInit(): void {
    this.abilitySvc.resolveProject();
    this.abilitySvc.project.subscribe((p) => {
      this.project = p;
      this.order$ = this.orderSvc.getForProject(this.project.id).refetch().then(({data}: any) => {
        if (data.ordersProject) {
          this.orders = data.ordersProject.map((q) => new Order().fromQuery(q)).filter(order => !order.preOrder);
          this.orders = this.orders.sort((a, b) => Date.parse(a.validatedAt) - Date.parse(b.validatedAt));
        }
        this.ordersIsLoaded = true;
      });
    });
  }

  quotationsTotal(quotations) {
    return quotations.reduce((a, b) => a + b.total, 0);
  }

  logisticianTotal(quotations) {
    return quotations.reduce((a, b) => a + b.logisticianTotal, 0);
  }

  generatePdf(order) {
    this.menuLoading = true;
    this.orderSvc.pdf(order.id).subscribe((e: any) => {
      const link = document.createElement('a');
      link.download = `${this.project.slug}${this.datePipe.transform(order.validatedAt, 'dd-MM-yyyy')}.pdf`;
      link.href = 'data:application/octet-stream;base64,' + e.data.pdfOrder.blob;
      link.click();
      this.menuLoading = false;
    });
  }

  generateCsv(quotations) {
    this.quotationCsvSvc.generateCsv(this.project, quotations);
  }

  orderAgain(order) {
    if (this.menuLoading) {
      return;
    }

    this.menuLoading = true;
    this.orderSvc.orderAgain(order.id).subscribe((e: any) => {
      this.router.navigate(['/project/quotation']);
    });
  }

  ngOnDestroy(): void {
  }

}
