import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Organization} from '../../_models/organization';
import {FormBuilder, Validators} from '@angular/forms';
import {OrganizationService} from '../../_services/organization.service';

@Component({
  selector: 'app-new-organization',
  templateUrl: './new-organization.component.html',
  styleUrls: ['./new-organization.component.scss']
})
export class NewOrganizationComponent implements OnInit {
  organizationForm;

  constructor(public dialogRef: MatDialogRef<NewOrganizationComponent>,
              @Inject(MAT_DIALOG_DATA) public organization: Organization,
              private formBuilder: FormBuilder,
              private organizationSvc: OrganizationService) {
    this.organizationForm = this.formBuilder.group({
      name: ['', Validators.required],
      logisticianBreakdown: [true],
      vatPrices: [false],
      file: ['', Validators.required],
      address: this.formBuilder.group({
        address_line_1: ['', [Validators.required]],
        address_line_2: [''],
        city: ['', [Validators.required]],
        postalCode: ['', Validators.required],
      })
    });
  }

  ngOnInit(): void {
  }

  setFile(file) {
    this.organizationForm.patchValue({file});
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.organizationForm.valid) {
      this.organizationSvc.create(this.organizationForm.value).subscribe((e: any) => {
        this.dialogRef.close({organization: new Organization().fromQuery(e.data.createOrganization)});
      });
    }
  }
}
