import {Component, ElementRef, Inject, OnDestroy, ViewChild} from '@angular/core';
import {AmbienceService} from '../../_services/ambience.service';
import {Ambience} from '../../_models/ambience';
import {Cart} from '../../_models/cart';
import {CartService} from '../../_services/cart.service';
import {FormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AbilityService} from '../../_services/ability.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {NewProductComponent} from '../../products/new-product/new-product.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ClusterService} from '../../_services/cluster.service';
import {DragulaService} from 'ng2-dragula';
import {Cluster} from '../../_models/cluster';
import {CarouselModalComponent} from '../../shared/carousel/carousel-modal/carousel-modal.component';

@AutoUnsubscribe()
@Component({
  selector: 'app-ambience-dialog',
  templateUrl: './ambience-dialog.component.html',
  styleUrls: ['./ambience-dialog.component.scss']
})
export class AmbienceDialogComponent implements OnDestroy {
  ambience: Ambience;
  cartForm;
  productForm;
  currentCluster = new Cluster();
  suggestionClusters: Cluster[];
  majorClusters: Cluster[];
  carts: Cart[];
  updatingImage = false;
  addingProduct = false;
  @ViewChild('breadcrumb') breadcrumb;
  @ViewChild('commentField') commentField;
  @ViewChild('nameField') nameField;
  dragCluster: string;
  private carts$;
  private dragulaHandler$;
  private dragulaGroup$;

  constructor(public dialogRef: MatDialogRef<AmbienceDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: FormBuilder,
              private ambienceSvc: AmbienceService,
              private cartSvc: CartService,
              private dialog: MatDialog,
              public abilitySvc: AbilityService,
              private snackBar: MatSnackBar,
              private clusterSvc: ClusterService,
              private dragulaSvc: DragulaService,
              private elemRef: ElementRef) {
    this.dragCluster = Math.random().toString(36).substring(7);
    this.ambience = data.ambience;
    this.splitClusters();
    this.cartForm = this.formBuilder.group({
      cart: ''
    });

    this.productForm = this.formBuilder.group({
      product: ''
    });

    if (this.abilitySvc.user.isAdmin()) {
      this.carts$ = this.cartSvc.get().valueChanges.subscribe((d: any) => {
        this.carts = d.data.carts.map((cart) => new Cart().fromQuery(cart)).filter(cart => cart.productCount > 0);
      });
    }

    this.dragulaGroup$ = this.dragulaSvc.createGroup(this.dragCluster, {
      moves: (el: any): any => !el.classList.contains('no-drag')
    });

    this.dragulaHandler$ = this.dragulaSvc.dropModel(this.dragCluster).subscribe(({item, targetIndex}) => {
      if (item && this.ambience.clusters[targetIndex]) {
        this.clusterSvc.setPosition(item.id, parseInt(String(this.ambience.clusters[targetIndex].position), 10)).subscribe((e: any) => {
          e.data.setPositionCluster.clusters.forEach((cluster) =>
            this.ambience.clusters.find((c) => c.id === cluster.id).position = cluster.position
          );
        });
      }
    });
  }

  updateImage(data: any) {
    this.updatingImage = true;
    this.ambienceSvc.update(this.ambience, undefined, data.file).subscribe((e: any) => {
      this.ambience = this.ambience.fromQuery(e.data.updateAmbience);
      this.updatingImage = false;
    });
  }

  updateComment(data: any) {
    this.ambienceSvc.update(this.ambience, undefined, undefined, data).subscribe((e: any) => {
      this.ambience = this.ambience.fromQuery(e.data.updateAmbience);
      this.commentField.hasSaved();
    });
  }

  updateName(data: any) {
    this.ambienceSvc.update(this.ambience, undefined, undefined, undefined, data).subscribe((e: any) => {
      this.ambience = this.ambience.fromQuery(e.data.updateAmbience);
      this.nameField.hasSaved();
    });
  }

  updateCluster(cluster) {
    const idx = this.ambience.clusters.findIndex((c) => c.id === cluster.id);
    this.ambience.clusters[idx] = cluster;
    this.updateClusterSplit(cluster);
  }

  setCluster(cluster) {
    if (cluster) {
      this.currentCluster = cluster;
      // @ts-ignore
      this.dialogRef._containerInstance._elementRef.nativeElement.scrollTo({
          top: this.breadcrumb.nativeElement.offsetTop + 100,
          left: 0,
          behavior: 'smooth'
        }
      );
    } else {
      this.currentCluster = new Cluster();
    }
  }

  removeCluster(cluster) {
    this.ambience.clusters.splice(this.ambience.clusters.indexOf(cluster), 1);
  }

  importCart(data: any) {
    this.addingProduct = true;
    const oldCount = this.ambience.clusters.length;
    if (data.cart.length > 0) {
      this.ambienceSvc.importCart(this.ambience, data.cart).subscribe((e: any) => {
        this.ambience = this.ambience.fromQuery(e.data.importCartAmbience);
        this.splitClusters();
        this.cartForm.reset();
        this.snackBar.open(this.ambience.clusters.length - oldCount + " produit(s) ajouté(s)");
        this.addingProduct = false;
      });
    }
  }

  importProduct(data: any) {
    this.addingProduct = true;
    if (data.product.length > 0) {
      this.ambienceSvc.importProduct(this.ambience, data.product).subscribe((e: any) => {
        try {
          this.ambience = this.ambience.fromQuery(e.data.importProductAmbience);
          this.splitClusters();
        } catch (e) {
          this.snackBar.open(`Aucun produit avec l'id ${data.product} n'existe sur BAP`, 'Ok');
        }
        this.productForm.reset();
        this.addingProduct = false;
      });
    }
  }

  createProduct() {
    const dialogRef = this.dialog.open(NewProductComponent, {data: {ambience: this.ambience}});
    dialogRef.afterClosed().subscribe(result => {
      const cluster = this.ambience.clusters.find((e) => e.id === result.cluster.id);
      if (cluster) {
        this.ambience.clusters[this.ambience.clusters.indexOf(cluster)] = result.cluster;
      } else {
        this.ambience.clusters.push(result.cluster);
      }
      this.splitClusters();
    });
  }

  zoom(image) {
    this.dialog.open(CarouselModalComponent, {
        width: '90vw',
        data: [image]
      }
    );
  }

  ngOnDestroy(): void {
  }

  private splitClusters() {
    this.majorClusters = this.ambience.clusters.filter((c) => !c.suggestion);
    this.suggestionClusters = this.ambience.clusters.filter((c) => c.suggestion);
  }

  private updateClusterSplit(cluster) {
    if (cluster.suggestion) {
      const idx = this.majorClusters.findIndex((c) => c.id === cluster.id);
      if (idx !== -1) {
        this.majorClusters.splice(idx, 1);
        this.suggestionClusters.push(cluster);
        this.scrollToCluster(cluster);
      }
    } else {
      const idx = this.suggestionClusters.findIndex((c) => c.id === cluster.id);
      if (idx !== -1) {
        this.suggestionClusters.splice(idx, 1);
        this.majorClusters.push(cluster);
        this.scrollToCluster(cluster);
      }
    }
  }

  private scrollToCluster(cluster) {
    // @ts-ignore
    this.dialogRef._containerInstance._elementRef.nativeElement.scrollTo({
      top: this.elemRef.nativeElement.querySelector(`[data-cluster-id="${cluster.id}"]`).offsetTop,
      left: 0
    });
  }
}
