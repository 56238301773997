<form (ngSubmit)="onSubmit()" [formGroup]="eventForm">
  <div mat-dialog-content>
    <div>
      <mat-form-field>
        <mat-label>Titre</mat-label>
        <input autofocus formControlName="title" matInput matNativeControl required="true" type="text"/>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <textarea formControlName="description" matInput type="text" rows="4" required="true"></textarea>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-label>Date de début</mat-label>
        <input formControlName="startDate" matInput matNativeControl required="true" type="date"/>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-label>Date de fin (laissez vide si l'événement ne dure qu'une journée)</mat-label>
        <input formControlName="endDate" matInput matNativeControl type="date"/>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button (click)="onNoClick()" mat-button type="button">Annuler</button>
    <button mat-button type="submit">Valider</button>
  </div>
</form>
