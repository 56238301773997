<form (ngSubmit)="onSubmit()" [formGroup]="productForm">
  <div mat-dialog-content>
    <div>
      <mat-form-field>
        <mat-label>Nom</mat-label>
        <input formControlName="label" matInput matNativeControl required type="text"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Quantité</mat-label>
        <input formControlName="quantity" matInput matNativeControl required step="1" type="number"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Prix H.T</mat-label>
        <input formControlName="price" matInput matNativeControl required step="0.01" type="number"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Marque</mat-label>
        <input formControlName="brand" matInput matNativeControl type="text"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Marchand</mat-label>
        <input formControlName="sellerLabel" matInput matNativeControl required type="text"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Référence Marchand</mat-label>
        <input formControlName="sellerReference" matInput matNativeControl type="text"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Lien fiche produit</mat-label>
        <input formControlName="url" matInput matNativeControl type="text"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Catégorie</mat-label>
        <input formControlName="category" matInput matNativeControl required type="text"/>
      </mat-form-field>
      <mat-form-field appearance="none">
        <input matInput style="display: none">
        <mat-checkbox color="primary" formControlName="suggestion">Ce produit est une suggestion alternative</mat-checkbox>
      </mat-form-field>
    </div>
    <span class="expand-spacer"></span>
    <div>
      <mat-form-field>
        <mat-label>Hauteur (cm)</mat-label>
        <input formControlName="height" matInput matNativeControl type="number"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Largeur (cm)</mat-label>
        <input formControlName="width" matInput matNativeControl type="number"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Longueur (cm)</mat-label>
        <input formControlName="depth" matInput matNativeControl type="number"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Date d'installation</mat-label>
        <input formControlName="installationAt" matInput matNativeControl type="date"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Pourcentage logisticien</mat-label>
        <input formControlName="logisticianPercentage" matInput matNativeControl required step="1" min="1" type="number"/>
      </mat-form-field>
      <div fxLayout="row wrap" style="width: 100%;">
        <ngx-dropzone (change)="addFile($event)" fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="8px">
          <ngx-dropzone-preview class="mat-elevation-z1" fxFlex *ngIf="files.length === 0">
            <ngx-dropzone-label>Glissez vos images ici</ngx-dropzone-label>
          </ngx-dropzone-preview>
          <ngx-dropzone-preview *ngFor="let file of files" [removable]="true" (removed)="deleteFile(file)" class="mat-elevation-z1" fxFlex="160px">
            <ngx-dropzone-label>
              <img [src]="file">
            </ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button type="submit">Valider</button>
    <button (click)="onNoClick()" mat-button>Annuler</button>
  </div>
</form>
