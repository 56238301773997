import {Component, OnDestroy, OnInit} from '@angular/core';
import {Project} from '../../_models/project';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {AbilityService} from '../../_services/ability.service';
import {MatDialog} from '@angular/material/dialog';
import {CreateEventComponent} from './create-event/create-event.component';
import {ConfirmationDialogComponent} from '../../shared/confirmation-dialog/confirmation-dialog.component';
import {EventService} from '../../_services/event.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, OnDestroy {
  project: Project;
  projectIsLoaded = false;
  private project$;

  constructor(public abilitySvc: AbilityService,
              private eventSvc: EventService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.project$ = this.abilitySvc.project.subscribe((p: Project) => {
      this.project = p;
      this.projectIsLoaded = true;
    });
  }

  ngOnDestroy() {
  }

  createEvent() {
    const dialogRef = this.dialog.open(CreateEventComponent, {
      width: '30vw',
      data: {projectId: this.project.id}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.project.events.push(result);
        this.project.events = this.project.events.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
      }
    });
  }

  deleteEvent(event) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Voulez-vous vraiment supprimer cet événement ?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.eventSvc.delete(event.id).subscribe(() => {
          this.project.events.splice(this.project.events.indexOf(event), 1);
        });
      }
    });
  }

}
