import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Event} from '../../../_models/event';
import {EventService} from '../../../_services/event.service';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit {
  eventForm;

  constructor(private dialogRef: MatDialogRef<CreateEventComponent>,
              private formBuilder: FormBuilder,
              private eventSvc: EventService,
              @Inject(MAT_DIALOG_DATA) public data: { projectId: number }) {
  }

  ngOnInit(): void {
    this.eventForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: [''],
      projectId: [this.data.projectId]
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.eventForm.valid) {
      this.eventSvc.create(this.eventForm.value).subscribe((e: any) => {
        this.dialogRef.close(new Event().fromQuery(e.data.createEvent));
      });
    }
  }

}
