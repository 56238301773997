<div class="container" fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.lt-sm="center top">
  <mat-card *ngIf="resetForm"
            class="mat-elevation-z4"
            fxFlex="30"
            fxFlex.lt-md="50" fxFlex.lt-sm="100">
    <mat-card-title>
      <img class="logo mat-elevation-z4" src="../../assets/images/logo.svg">
    </mat-card-title>
    <mat-card-content>
      <form (ngSubmit)="onSubmit()" [formGroup]="resetForm">
        <mat-form-field>
          <mat-label>Mot de passe</mat-label>
          <input formControlName="password" matInput placeholder="Mot de passe" required type="password"/>
          <mat-error *ngIf="f.password.errors && f.password.errors.required">Veuillez saisir un mot de passe</mat-error>
          <mat-error *ngIf="f.password.errors && f.password.errors.minlength">La longueur minimale est de 6 caractères
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Mot de passe (confirmation)</mat-label>
          <input formControlName="passwordConfirmation" matInput placeholder="Mot de passe (confirmation)"
                 required type="password"/>
          <mat-error *ngIf="f.passwordConfirmation.errors && f.passwordConfirmation.errors.required">Veuillez confirmer
            le mot de passe
          </mat-error>
          <mat-error *ngIf="f.passwordConfirmation.errors && f.passwordConfirmation.errors.mustMatch">Les mots de passes
            sont différents
          </mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
          <button color="accent" fxFlex mat-flat-button>Modifier mon mot de passe</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
