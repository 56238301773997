import {Injectable} from '@angular/core';
import gql from 'graphql-tag';
import {Apollo} from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {
  constructor(private apollo: Apollo) {
  }

  create(base64, filename) {
    return this.apollo.mutate({
      mutation: gql`
        mutation createAttachment ($input: AttachmentCreateInput) {
            createAttachment (input: $input) {
              filePath
            }
        }
      `,
      variables: {input: {base64, filename}}
    });
  }
}
