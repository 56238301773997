import * as simplify from 'simplify-js';
import {Ambience} from './ambience';

export class MapArea {
  id: number;
  name: string;
  fullName: string;
  ambienceImages: string[] = [];
  images: string[];
  coordinates: number[] = [];
  ratio = {height: undefined, width: undefined, ratio: undefined};
  ambiences: Ambience[] = [];
  color: string;

  fromQuery(response: any) {
    Object.assign(this, response);

    if (response.coordinates) {
      this.coordinates = response.coordinates.points;
      this.ratio = response.coordinates.ratio;
    }

    this.ambiences = response.ambiences ? response.ambiences.map((ambience) => new Ambience().fromQuery(ambience)) : null;
    return this;
  }

  addPoint(x, y) {
    this.coordinates.push(x, y);
    this.simplifyPoints();
  }

  simplifyPoints() {
    const arr = [];
    for (let i = 0, l = this.coordinates.length; i < l; i += 2) {
      arr.push(this.coordinates.slice(i, i + 2));
    }

    let obj = arr.map((e) => {
      return {x: e[0], y: e[1]};
    });

    obj = simplify(obj, 0.6, true);
    this.coordinates = [].concat.apply([], obj.map((e) => [e.x, e.y]));
  }

  toXY() {
    const arr = [];
    for (let i = 0, l = this.coordinates.length; i < l; i += 2) {
      arr.push(this.coordinates.slice(i, i + 2));
    }

    return arr.map((e) => {
      return {x: e[0], y: e[1]};
    });
  }

}
