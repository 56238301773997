import {Ability, AbilityBuilder} from '@casl/ability';
import {User} from '../_models/user';
import {Project} from '../_models/project';

export async function defineAbilityFor(user: User, project: Project) {
  const {rules, can, cannot} = AbilityBuilder.extract();

  if (project.isMember(user) || user.isAdmin()) {
    can('read', 'Project');

    if (user.isAdmin() || project.isReferent(user)) {
      can('update', 'Project');
    } else {
      cannot('update', 'Project');
    }

    if (user.isAdmin() || project.isReferent(user) || project.isSubReferent(user)) {
      can('fullRead', 'Project');
    } else {
      cannot('fullRead', 'Project');
    }

    if (user.isAdmin()) {
      can('administrate', 'Project');
    } else {
      cannot('administrate', 'Project');
    }

  } else {
    cannot('read', 'Project');
  }
  return new Ability(rules);
}
