import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, Injectable, LOCALE_ID, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SignInComponent} from './sign-in/sign-in.component';
import {Router, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './_guards/auth.guard';
import {ProjectsComponent} from './projects/projects.component';
import {HttpClientModule} from '@angular/common/http';
import {MaterialModule} from './core/material.module';
import {GraphQLModule} from './graphql.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserEditComponent} from './users/user-edit/user-edit.component';
import {TeamComponent} from './projects/team/team.component';
import {ConceptComponent} from './projects/concept/concept.component';
import {PropositionComponent} from './projects/proposition/proposition.component';
import {AbilityModule} from '@casl/angular';
import {ConfirmationDialogComponent} from './shared/confirmation-dialog/confirmation-dialog.component';
import {SafePipe} from './_pipes/safe.pipe';
import {MapComponent} from './projects/map/map.component';
import {ProjectGuard} from './_guards/project.guard';
import {PromptDialogComponent} from './shared/prompt-dialog/prompt-dialog.component';
import {MapRenderComponent} from './shared/map-render/map-render.component';
import {KonvaModule} from 'ng2-konva';
import {MapAreasComponent} from './projects/map/map-areas/map-areas.component';
import {AmbienceComponent} from './projects/proposition/ambience/ambience.component';
import {AmbienceDialogComponent} from './projects/ambience-dialog/ambience-dialog.component';
import {ProductComponent} from './shared/product/product.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CarouselComponent} from './shared/carousel/carousel.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DatePipe, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';

import * as Sentry from '@sentry/angular';
import {InlineEditComponent} from './shared/inline-edit/inline-edit.component';
import {QuotationComponent} from './projects/quotation/quotation.component';
import {VatPipe} from './_pipes/vat.pipe';
import {FileSelectComponent} from './shared/file-select/file-select.component';
import {JoyrideModule} from 'ngx-joyride';
import {EditOrganizationComponent} from './organizations/edit-organization/edit-organization.component';
import {NewOrganizationComponent} from './organizations/new-organization/new-organization.component';
import {NewProjectComponent} from './projects/new-project/new-project.component';
import {SpinnerComponent} from './shared/spinner/spinner.component';
import {SignUpComponent} from './sign-up/sign-up.component';
import {CardTableComponent} from './shared/card-table/card-table.component';
import {EditorComponent} from './shared/editor/editor.component';
import {ClusterComponent} from './shared/cluster/cluster.component';
import {NewProductComponent} from './products/new-product/new-product.component';
import {EditProductComponent} from './products/edit-product/edit-product.component';
import {PropositionMenuComponent} from './shared/proposition-menu/proposition-menu.component';
import {CreateAmbienceComponent} from './projects/proposition/create-ambience/create-ambience.component';
import {PreOrderComponent} from './projects/pre-order/pre-order.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import {OrderComponent} from './projects/order/order.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DragulaModule} from 'ng2-dragula';
import {AdminGuard} from './_guards/admin.guard';
import {OrdersComponent} from './orders/orders.component';
import {MatSortModule} from '@angular/material/sort';
import {ProductOrderComponent} from './orders/product-order/product-order.component';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {Ability} from '@casl/ability';
import {AsyncCanPipe} from './_pipes/async-can.pipe';
import {ProjectFullReadGuard} from './_guards/project-full-read.guard';
import {MatTableExporterModule} from 'mat-table-exporter';
import {CarouselModalComponent} from './shared/carousel/carousel-modal/carousel-modal.component';
import {QuotationSummaryComponent} from './projects/quotation/quotation-summary/quotation-summary.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TimelineComponent} from './projects/timeline/timeline.component';
import { MglTimelineModule } from 'angular-mgl-timeline';
import { CreateEventComponent } from './projects/timeline/create-event/create-event.component';
import {Environment} from '@angular/compiler-cli/src/ngtsc/typecheck/src/environment';
import {environment} from '../environments/environment';
import {Integrations} from '@sentry/tracing';
import { NewPasswordComponent } from './new-password/new-password.component';
import {EditProjectComponent} from './projects/edit-project/edit-project.component';
import {InlineProductOrderComponent} from './shared/inline-product-order/inline-product-order.component';

registerLocaleData(localeFr, 'fr', localeFrExtra);

const appRoutes: Routes = [
  {path: 'sign_in', component: SignInComponent},
  {path: 'sign_up/:invitationToken', component: SignUpComponent},
  {path: 'new_password/:passwordToken', component: NewPasswordComponent},
  {path: 'project', component: ProjectsComponent, canActivate: [AuthGuard]},
  {path: 'project/team', component: TeamComponent, canActivate: [AuthGuard, ProjectGuard]},
  {path: 'project/concept', component: ConceptComponent, canActivate: [AuthGuard, ProjectGuard]},
  {path: 'project/propositions', component: PropositionComponent, canActivate: [AuthGuard, ProjectGuard]},
  {path: 'project/map', component: MapComponent, canActivate: [AuthGuard, ProjectGuard]},
  {path: 'project/quotation', component: QuotationComponent, canActivate: [AuthGuard, ProjectGuard, ProjectFullReadGuard]},
  {path: 'project/pre_orders', component: PreOrderComponent, canActivate: [AuthGuard, ProjectGuard, ProjectFullReadGuard]},
  {path: 'project/orders', component: OrderComponent, canActivate: [AuthGuard, ProjectGuard, ProjectFullReadGuard]},
  {path: 'project/:organization', component: ProjectsComponent, canActivate: [AuthGuard, AdminGuard]},
  {path: 'order/:id', component: OrdersComponent, canActivate: [AuthGuard, AdminGuard]},
  {path: '', redirectTo: '/project', pathMatch: 'full'}
];

if (environment.sentry) {
  Sentry.init({
    dsn: environment.sentry,
    environment: environment.envName,
    release: "wano-front@" + process.env.npm_package_version,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    ProjectsComponent,
    UserEditComponent,
    TeamComponent,
    ConceptComponent,
    PropositionComponent,
    ConfirmationDialogComponent,
    PromptDialogComponent,
    SafePipe,
    MapComponent,
    MapRenderComponent,
    MapAreasComponent,
    AmbienceComponent,
    AmbienceDialogComponent,
    ProductComponent,
    ProductOrderComponent,
    InlineProductOrderComponent,
    CarouselComponent,
    InlineEditComponent,
    QuotationComponent,
    VatPipe,
    FileSelectComponent,
    EditOrganizationComponent,
    NewOrganizationComponent,
    NewProjectComponent,
    SpinnerComponent,
    SignUpComponent,
    CardTableComponent,
    EditorComponent,
    ClusterComponent,
    NewProductComponent,
    EditProductComponent,
    PropositionMenuComponent,
    CreateAmbienceComponent,
    OrderComponent,
    OrdersComponent,
    ProductOrderComponent,
    AsyncCanPipe,
    CarouselModalComponent,
    QuotationSummaryComponent,
    TimelineComponent,
    CreateEventComponent,
    NewPasswordComponent,
    EditProjectComponent,
    PreOrderComponent
  ],
  imports: [
    JoyrideModule.forRoot(),
    EditorModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
    DragulaModule.forRoot(),
    AbilityModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    GraphQLModule,
    ReactiveFormsModule,
    KonvaModule,
    FlexLayoutModule,
    FormsModule,
    DragDropModule,
    MatSortModule,
    NgxDropzoneModule,
    MatTableExporterModule,
    MatTooltipModule,
    MglTimelineModule
  ],
  providers: [MatSnackBar, DatePipe, VatPipe,
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR'
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr'
    },
    {provide: Ability, useValue: new Ability()},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
    provide: Sentry.TraceService,
    deps: [Router],
    useValue: undefined
},
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
