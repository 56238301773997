import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, Validators} from '@angular/forms';
import {ProductOrder} from '../../_models/product-order';
import {ProductOrderService} from '../../_services/product-order.service';

@Component({
  selector: 'app-product-order',
  templateUrl: './product-order.component.html',
  styleUrls: ['./product-order.component.scss']
})
export class ProductOrderComponent implements OnInit {
  productOrderForm;
  status = ProductOrder.STATUS;
  statusSort = () => 0;

  constructor(public dialogRef: MatDialogRef<ProductOrderComponent>,
              @Inject(MAT_DIALOG_DATA) public product: ProductOrder,
              private formBuilder: FormBuilder,
              private productOrderSvc: ProductOrderService) {
  }

  ngOnInit(): void {
    this.productOrderForm = this.formBuilder.group({
      id: [this.product.id],
      unitPrice: [this.product.unitPrice, Validators.required],
      internalUnitPrice: [this.product.internalUnitPrice, Validators.required],
      logisticianUnitPrice: [this.product.logisticianUnitPrice, Validators.required],
      //internalLogisticianPrice: [this.product.internalLogisticianPrice, Validators.required],
      //deliveryPrice: [this.product.deliveryPrice, Validators.required],
      //ecotax: [this.product.ecotax],
      quantity: [this.product.quantity, Validators.required],
      status: [this.product.status, Validators.required],
      sellerLabel: [this.product.sellerLabel, Validators.required],
      url: [this.product.url],
      sellerReference: [this.product.sellerReference, Validators.required],
      //orderReference: [this.product.orderReference],
      invoiceReference: [this.product.invoiceReference],
      trackingReference: [this.product.trackingReference],
      deliveryDate: [this.product.deliveryDate],
      installationAt: [this.product.installationAt],
      comment: [this.product.comment]
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.productOrderForm.valid) {
      this.productOrderSvc.update(this.productOrderForm.value).subscribe((e: any) => {
        this.dialogRef.close({productOrder: new ProductOrder().fromQuery(e.data.updateProductsOrder)});
      });
    }
  }

}
