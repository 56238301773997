import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Product} from '../../_models/product';
import {ProductService} from '../../_services/product.service';
import {Organization} from '../../_models/organization';
import {AbilityService} from '../../_services/ability.service';
import {MatDialog} from '@angular/material/dialog';
import {EditProductComponent} from '../../products/edit-product/edit-product.component';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {CarouselModalComponent} from '../carousel/carousel-modal/carousel-modal.component';
import {Project} from '../../_models/project';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  @Input() product: Product;
  @Input() organization: Organization;
  @Input() carousel = true;
  @Input() inline = false;
  @Input() static = false;
  @Input() star = null;
  @Output() update = new EventEmitter();
  @Output() destroy = new EventEmitter();
  @Output() selectedProduct = new EventEmitter();
  @Output() addToUltimate = new EventEmitter();

  @ViewChild('labelField') labelField;
  @ViewChild('priceField') priceField;
  @ViewChild('quantityField') quantityField;

  @ViewChild('inlineQuantityField') inlineQuantityField;

  orderingAgain = false;
  orderedAgain = false;
  successfulOrderingAgain = true;

  project: Project;

  constructor(private productSvc: ProductService,
              public abilitySvc: AbilityService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.abilitySvc.project.subscribe(p => {
        this.project = p;
      }
    );
  }

  like() {
    const userId = parseInt(String(this.abilitySvc.user.id), 10);
    const dIdx = this.product.usersVotes.dislikes.indexOf(userId);
    const lIdx = this.product.usersVotes.likes.indexOf(userId);

    if (dIdx > -1) {
      this.product.usersVotes.dislikes.splice(dIdx, 1);
    }

    if (lIdx > -1) {
      this.product.usersVotes.likes.splice(lIdx, 1);
    } else {
      this.product.usersVotes.likes.push(userId);
    }

    this.productSvc.vote(this.product.id, true).subscribe((p: any) => this.updateProduct(p.data.voteProduct));
  }

  dislike() {
    const userId = parseInt(String(this.abilitySvc.user.id), 10);
    const lIdx = this.product.usersVotes.likes.indexOf(userId);
    const dIdx = this.product.usersVotes.dislikes.indexOf(userId);

    if (lIdx > -1) {
      this.product.usersVotes.likes.splice(lIdx, 1);
    }

    if (dIdx > -1) {
      this.product.usersVotes.dislikes.splice(dIdx, 1);
    } else {
      this.product.usersVotes.dislikes.push(userId);
    }

    this.productSvc.vote(this.product.id, false).subscribe((p: any) => this.updateProduct(p.data.voteProduct));
  }

  toggleCart() {
    this.productSvc.update({
      id: this.product.id,
      toggleStatus: true
    }).subscribe((p: any) => this.updateProduct(p.data.updateProduct));
  }

  updateLabel(label) {
    this.productSvc.update({id: this.product.id, label}).subscribe((p: any) => {
      this.updateProduct(p.data.updateProduct);
      this.labelField.hasSaved();
    });
  }

  updatePrice(price) {
    this.productSvc.update({id: this.product.id, price: parseFloat(price)}).subscribe((p: any) => {
      this.updateProduct(p.data.updateProduct);
      this.priceField.hasSaved();
    });
  }

  delayPurchase() {
    this.productSvc.update({id: this.product.id, delayPurchase: true}).subscribe((p: any) => {
      this.updateProduct(p.data.updateProduct);
    });
  }

  undelayPurchase() {
    this.productSvc.update({id: this.product.id, delayPurchase: false}).subscribe((p: any) => {
      this.updateProduct(p.data.updateProduct);
    });
  }

  updateQuantity(quantity) {
    this.productSvc.update({
      id: this.product.id,
      quantity: parseInt(quantity, 10)
    }).subscribe((p: any) => {
      this.updateProduct(p.data.updateProduct);
      if (this.quantityField) {
        this.quantityField.hasSaved();
      }
      if (this.inlineQuantityField) {
        this.inlineQuantityField.hasSaved();
      }
    });
  }

  delete() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Voulez-vous vraiment supprimer ce produit ?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.productSvc.destroy(this.product.id).subscribe(() => {
          delete this.product;
          this.destroy.emit(true);
        });
      }
    });
  }

  editProduct() {
    const dialogRef = this.dialog.open(EditProductComponent, {data: {product: this.product}});
    dialogRef.afterClosed().subscribe((e) => {
      if (e && e.product) {
        this.updateProduct(e.product);
      }
    });
  }

  zoom() {
    this.dialog.open(CarouselModalComponent, {width: '90vw', data: [this.product.images[0]]});
  }

  orderAgain() {
    if (this.orderingAgain || this.orderedAgain) {
      return;
    }

    this.orderingAgain = true;
    this.productSvc.order_again(this.product.id).subscribe((p: any) => {
      if (p.data.orderAgainProduct.id)
      {
        this.orderingAgain = false;
        this.orderedAgain = true;
        this.successfulOrderingAgain = true;
      } else {
        this.orderingAgain = false;
        this.orderedAgain = true;
        this.successfulOrderingAgain = false;
      }
    }, error => {
      this.orderingAgain = false;
      this.orderedAgain = true;
      this.successfulOrderingAgain = false;
    });
  }

  private updateProduct(data) {
    this.product.fromQuery(data);
    this.update.next(this.product);

    if (this.inline && !this.product.isApproved()) {
      this.destroy.emit(true);
    }
  }

}
