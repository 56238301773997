<div class="zone-control" *ngIf="abilitySvc.user.editMode && ('Project' | asyncCan:'administrate' | async) && !staticDisplay">
  <button mat-raised-button color="primary" (click)="finishDrawing()" *ngIf="isDrawing">
    Terminer le tracé
  </button>
  <button mat-raised-button (click)="changingImage = !changingImage" color="accent">
    Modifier l'image
  </button>
  <button mat-raised-button (click)="deleteMap()" color="warn">
    Supprimer le plan
  </button>
</div>

<div class="image-changer" *ngIf="changingImage">
  <mat-card>
    <mat-card-content>
      <h2>Modifier le plan</h2>
      <h3>Toutes les zones seront supprimées</h3>
      <div class="file-select-button">
        <app-file-select (fileSubmited)="updateMap($event)"></app-file-select>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<ko-stage #stage
          (mousedown)="onMouseDown($event)"
          (mousemove)="onMouseMove()"
          (mouseup)="onMouseUp()"
          [config]="stageConf"
>
  <ko-layer #displayLayer>
    <ko-image [config]="imageConf"></ko-image>
  </ko-layer>
  <ko-layer #drawLayer>

  </ko-layer>
</ko-stage>
