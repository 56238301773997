import {Component, Inject} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {merge} from 'lodash';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent {
  promptForm;
  config = {
    message: undefined,
    input: undefined,
    color: undefined,
    select: {
      data: undefined,
      value: 'id',
      label: 'name'
    }
  };
  color = undefined;

  constructor(
    public dialogRef: MatDialogRef<PromptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private formBuilder: FormBuilder) {
    this.promptForm = this.formBuilder.group({
      input: '',
      select: ''
    });
    this.config = merge(this.config, data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(data: any) {
    this.dialogRef.close({input: data.input, color: this.color, select: data.select});
  }

  updateColor(e) {
    this.color = e;
  }
}
