import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class MapAreaService {

  constructor(private apollo: Apollo) {
  }

  create(mapArea) {
    return this.apollo.mutate({
      mutation: gql`
        mutation createMapArea ($mapId: ID!, $name: String!, $color: String!, $coordinates: JSON!) {
            createMapArea (mapId: $mapId, name: $name, color: $color, coordinates: $coordinates) {
              id
              name
              color
              coordinates
              images
            }
        }
      `,
      variables: {
        mapId: mapArea.map.id,
        name: mapArea.name,
        color: mapArea.color,
        coordinates: {points: mapArea.coordinates, ratio: mapArea.ratio}
      }
    });
  }

  update(mapArea, image?, deletedFile?) {
    return this.apollo.mutate({
      mutation: gql`
        mutation updateMapArea ($id: ID!, $name: String, $color: String, $coordinates: JSON, $file: String, $deletedFile: String) {
            updateMapArea (id: $id, name: $name, color: $color, coordinates: $coordinates, file: $file, deletedFile: $deletedFile) {
              id
              name
              coordinates
              color
              images
            }
        }
      `,
      variables: {
        id: mapArea.id,
        name: mapArea.name,
        color: mapArea.color,
        coordinates: {points: mapArea.coordinates, ratio: mapArea.ratio},
        file: image,
        deletedFile
      }
    });
  }

  delete(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation destroyMapArea ($id: ID!) {
            destroyMapArea (id: $id) {
              id
            }
        }
      `,
      variables: {id}
    });
  }
}
