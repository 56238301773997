import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Project} from '../_models/project';
import {ConfirmationDialogComponent} from '../shared/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ProjectService} from '../_services/project.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {AbilityService} from '../_services/ability.service';
import {JoyrideService} from 'ngx-joyride';
import {Organization} from '../_models/organization';
import {OrganizationService} from '../_services/organization.service';
import {EditOrganizationComponent} from '../organizations/edit-organization/edit-organization.component';
import {NewProjectComponent} from './new-project/new-project.component';
import {UserService} from '../_services/user.service';
import {User} from '../_models/user';
import {CardTableComponent} from '../shared/card-table/card-table.component';
import {UserEditComponent} from '../users/user-edit/user-edit.component';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {OrderService} from '../_services/order.service';
import {Order} from '../_models/order';
import {EditProjectComponent} from './edit-project/edit-project.component';

@AutoUnsubscribe()
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy {
  @ViewChild('organizationTable') organizationTable: CardTableComponent;
  @ViewChild('projectTable') projectTable: CardTableComponent;
  @ViewChild('userTable') userTable: CardTableComponent;
  project: Project;
  organization: Organization;
  projectCols = [
    {field: 'organizationName', title: 'Organisation'},
    {field: 'name', title: 'Nom'},
    {field: 'published', title: 'Publié', display: 'boolean'}
  ];
  projectMapper = ((e: any) => e.projects.map((project) => new Project().fromQuery(project)));
  projectPredicate = ((project, filter) => project.name.toLowerCase().includes(filter.toLowerCase()));
  projectScope = undefined;
  organizationCols = [
    {field: 'name', title: 'Nom'},
    {field: 'logisticianBreakdown', title: 'Ventilation Logisticien', display: 'boolean'},
    {field: 'vatPrices', title: 'TTC', display: 'boolean'}
  ];
  organizationMapper = ((e: any) => e.organizations.map((organization) => new Organization().fromQuery(organization)));
  organizationPredicate = ((organization, filter) => organization.name.toLowerCase().includes(filter.toLowerCase()));
  userCols = [
    {field: 'completeName', title: 'Nom'},
    {field: 'role', title: 'Rôle'},
    {field: 'lastSeenAt', title: 'Dernière connexion', display: 'date'}
  ];
  userMapper = ((e: any) => e.users.map((user) => new User().fromQuery(user)));
  userPredicate = ((user, filter) => user.completeName.toLowerCase().includes(filter.toLowerCase()));
  orderCols = [
    {field: 'name', title: 'Projet'},
    {field: 'validatedAt', title: 'Validée le', display: 'date'}
  ];
  orderMapper = ((e: any) => e.orders.map((order) => new Order().fromQuery(order)));
  orderPredicate = ((order, filter) => order.name.toLowerCase().includes(filter.toLowerCase()));
  private project$;
  private param$;

  constructor(private dialog: MatDialog,
              public abilitySvc: AbilityService,
              public projectSvc: ProjectService,
              public organizationSvc: OrganizationService,
              public orderSvc: OrderService,
              public userSvc: UserService,
              private joyrideSvc: JoyrideService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.project$ = this.abilitySvc.project.subscribe((p) => this.project = p);
    if (this.abilitySvc.user.isAdmin()) {
      this.param$ = this.route.paramMap.pipe(
        switchMap(params => {
          return params.getAll('organization') || [0];
        })
      ).subscribe((organization) => {
        this.selectOrganization(organization);
      });
    }
  }

  ngOnDestroy() {
  }

  selectOrganization(organization) {
    if (organization === 0) {
      this.projectScope = undefined;
      this.organization = undefined;
    } else {
      this.projectScope = ((project) => project.organization.id === organization);
      this.organization = new Organization().fromQuery({id: parseInt(organization, 10)});
    }
    setTimeout(() => this.projectTable.refresh(), 100);
  }

  selectProject(id) {
    this.abilitySvc.setProject(id);
    this.router.navigate(['/project/team']).then(() => {
      location.reload();
    });
  }

  createProject() {
    const dialogRef = this.dialog.open(NewProjectComponent, {
      width: '60vw',
      data: {organizations: this.organizationTable.data, currentOrganization: this.organization ? this.organization.id : 0}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.organizationTable.refresh();
      if (result) {
        this.projectTable.refresh();
        this.selectProject(result.project.id);
      }
    });
  }

  deleteProject(project: Project) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Voulez-vous vraiment supprimer ce projet ?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (project.id === this.abilitySvc.user.currentProject) {
          this.selectProject(undefined);
        }
        this.projectSvc.delete(project.id).subscribe(() => {
          this.projectTable.refresh();
        });
      }
    });
  }

  publishProject(project) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Voulez-vous vraiment publier ce projet ?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.projectSvc.publish(project.id).subscribe(() => {
          this.projectTable.refresh();
        });
      }
    });
  }

  editOrganization(organization: Organization) {
    const dialogRef = this.dialog.open(EditOrganizationComponent, {
      width: '40vw',
      data: organization
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.project.organization.id === organization.id) {
          this.abilitySvc.resolveProject();
        }
        this.organizationTable.refresh();
        this.projectTable.refresh();
      }
    });
  }

  editUser(user: User) {
    const dialogRef = this.dialog.open(UserEditComponent, {
      width: '40vw',
      data: user
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userTable.refresh();
      }
    });
  }

  editProject(project: Project) {
    const dialogRef = this.dialog.open(EditProjectComponent, {
      width: '40vw',
      data: project
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.project.id === project.id) {
          this.abilitySvc.resolveProject();
        }
        this.projectTable.refresh();
      }
    });
  }

  deleteOrganization(organization: Organization) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Voulez-vous vraiment supprimer cette organisation (et les projets associés) ?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.project && this.project.organization.id === organization.id) {
          this.selectProject(undefined);
        }
        this.organizationSvc.delete(organization.id).subscribe(() => {
          this.organizationTable.refresh();
          this.projectTable.refresh();
        });
      }
    });
  }

  showOrder(order) {
    this.router.navigate([`/order/${order.id}`]);
  }

  startTour() {
    this.joyrideSvc.startTour({
      steps: [
        'menu.team@project/team',
        'menu.concept@project/concept',
        'menu.map@project/map',
        'menu.propositions@project/propositions',
        'menu.quotation@project/quotation',
        'menu.orders@project/orders',
        'menu.edit-organization@project',
        'menu.user'
      ], waitingTime: 500
    });
  }

}
