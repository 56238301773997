import {Injectable} from '@angular/core';
import gql from 'graphql-tag';
import {Apollo} from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private apollo: Apollo) {
  }

  getAll() {
    return this.apollo
      .watchQuery({
        query: gql`
        query orders {
          orders {
            id
            name
            validatedAt
            preOrderValidatedAt
            preOrder
           }
        }
        `
      });
  }

  getForProject(projectId) {
    return this.apollo
      .watchQuery({
        query: gql`
          query ordersProject ($projectId: ID!) {
              ordersProject (projectId: $projectId) {
                id
                validatedAt
                preOrderValidatedAt
                preOrder
                productsOrders {
                  id
                  label
                  price
                  url
                  comment
                  bapId
                  logisticianPrice
                  sellerReference
                  orderReference
                  invoiceReference
                  trackingReference
                  sellerLabel
                  logisticianName
                  status
                  ambienceName
                  status
                  images
                  quantity
                  installationAt
                  deliveryDate
                  dimensions
                  unitPrice
                  internalUnitPrice
                  logisticianUnitPrice
                  internalLogisticianPrice
                  ecotax
                  deliveryPrice
                }
                quotations {
                  ambienceName
                  products {
                    id
                    label
                    price
                    logisticianPrice
                    status
                    images
                    quantity
                    bapId
                    installationAt
                    dimensions
                    url
                  }
                }
              }
          }
        `
        , variables: {projectId}
      });
  }

  get(id) {
    return this.apollo
      .watchQuery({
        query: gql`
          query order ($id: ID!) {
              order (id: $id) {
                id
                name
                validatedAt
                productsOrders {
                  id
                  label
                  price
                  url
                  comment
                  bapId
                  logisticianPrice
                  sellerReference
                  orderReference
                  invoiceReference
                  trackingReference
                  sellerLabel
                  logisticianName
                  status
                  ambienceName
                  status
                  images
                  quantity
                  installationAt
                  deliveryDate
                  dimensions
                  unitPrice
                  internalUnitPrice
                  logisticianUnitPrice
                  internalLogisticianPrice
                  ecotax
                  deliveryPrice
                }
              }
          }
        `
        , variables: {id}
      });
  }

  pdf(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation pdfOrder ($id: ID!) {
            pdfOrder (id: $id) {
              blob
            }
        }
      `,
      variables: {
        id
      }
    });
  }

  validate(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation validateOrder($id: ID!) {
          validateOrder(id: $id) {
            id
          }
        }
      `,
      variables: {
        id
      }
    });
  }

  rollback(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation rollbackOrder ($id: ID!) {
            rollbackOrder (id: $id) {
                id
            }
        }
      `,
      variables: {
        id
      }
    });
  }

  orderAgain(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation repeatOrder($id: ID!) {
          repeatOrder(id: $id) {
            id
          }
        }
      `,
      variables: {
        id
      }
    });
  }
}
