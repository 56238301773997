<app-spinner *ngIf="!projectIsLoaded"></app-spinner>
<mgl-timeline *ngIf="project.events.length > 0">
  <mgl-timeline-entry *ngFor="let event of project.events">
    <mgl-timeline-entry-header>
      {{event.title}}
      <span *ngIf="!event.endDate || event.startDate === event.endDate" class="mobile-date">{{event.startDate | date}}</span>
      <span *ngIf="event.endDate && event.startDate !== event.endDate" class="mobile-date">{{event.startDate | date}} - {{event.endDate | date}}</span>
      <button mat-icon-button (click)="deleteEvent(event)" *ngIf="('Project' | asyncCan: 'administrate' | async) && abilitySvc.user.editMode" class="delete-button">
        <mat-icon>clear</mat-icon>
      </button>
    </mgl-timeline-entry-header>
    <mgl-timeline-entry-content>{{event.description}}</mgl-timeline-entry-content>
    <mgl-timeline-entry-dot
      [class]="event.outdated ? 'accent' : 'primary'"
      [size]="event.outdated ? 45 : 55"
    ></mgl-timeline-entry-dot>
    <mgl-timeline-entry-side *ngIf="!event.endDate || event.startDate === event.endDate">{{event.startDate | date}}</mgl-timeline-entry-side>
    <mgl-timeline-entry-side *ngIf="event.endDate && event.startDate !== event.endDate">{{event.startDate | date}} - {{event.endDate | date}}</mgl-timeline-entry-side>
  </mgl-timeline-entry>
</mgl-timeline>

<div *ngIf="project.events.length == 0" style="text-align: center;">
  Aucun événement n'est prévu pour le moment
</div>

<button mat-flat-button color="primary" *ngIf="('Project' | asyncCan: 'administrate' | async) && abilitySvc.user.editMode" (click)="createEvent()">
  Créer un événement
</button>
