import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {HttpLink, HttpLinkModule} from 'apollo-angular-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {environment} from '../environments/environment';
import {User} from './_models/user';
import {ApolloLink} from 'apollo-link';
import {setContext} from 'apollo-link-context';


const uri = environment.apiUrl;

export function provideApollo(httpLink: HttpLink) {
  const user = new User().deserialize(JSON.parse(localStorage.getItem('currentUser')));
  let link;
  if (user && user.token) {
    const auth = setContext((operation, context) => ({
      headers: {
        'X-USER-EMAIL': user.email,
        'X-USER-TOKEN': user.token
      }
    }));
    link = ApolloLink.from([auth, httpLink.create({uri})]);
  } else {
    link = ApolloLink.from([httpLink.create({uri})]);
  }

  const cache = new InMemoryCache();

  return {
    link,
    cache,
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all'
      }
    }
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: provideApollo,
      deps: [HttpLink]
    }
  ]
})
export class GraphQLModule {
}
