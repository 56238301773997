import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {MapArea} from '../../../_models/map-area';

@Component({
  selector: 'app-create-ambience',
  templateUrl: './create-ambience.component.html',
  styleUrls: ['./create-ambience.component.scss']
})
export class CreateAmbienceComponent implements OnInit {
  form;
  values = [];

  constructor(public dialogRef: MatDialogRef<CreateAmbienceComponent>,
              @Inject(MAT_DIALOG_DATA) public data: [MapArea],
              private formBuilder: FormBuilder) {
    this.values = [...new Set(data.map(v => v.name))];
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      input: '',
      select: ''
    });
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(data: any) {
    if (data.select === '') {
      if (data.input !== '') {
        this.dialogRef.close({value: data.input});
      }
    } else {
      this.dialogRef.close({value: data.select});
    }
  }
}
