<form (ngSubmit)="onSubmit()" [formGroup]="organizationForm">
  <div mat-dialog-content>
    <div>
      <mat-form-field>
        <mat-label>Nom</mat-label>
        <input formControlName="name" matInput matNativeControl required type="text"/>
      </mat-form-field>

      <mat-form-field appearance="none">
        <input formControlName="logisticianBreakdown" matInput style="display: none">
        <mat-checkbox color="primary" formControlName="logisticianBreakdown">Activer la ventilation du coût
          logisticien
        </mat-checkbox>
      </mat-form-field>

      <mat-form-field appearance="none">
        <input formControlName="vatPrices" matInput style="display: none">
        <mat-checkbox color="primary" formControlName="vatPrices">Afficher les prix en TTC</mat-checkbox>
      </mat-form-field>

      <mat-form-field appearance="none">
        <mat-label>Logo</mat-label>
        <input formControlName="file" matInput required style="display: none">
        <app-file-select (fileSubmited)="setFile($event)" [showSubmit]="false"></app-file-select>
      </mat-form-field>
    </div>
    <span class="expand-spacer"></span>
    <div formGroupName="address">
      <mat-form-field>
        <mat-label>Adresse (Ligne 1)</mat-label>
        <input formControlName="address_line_1" matInput matNativeControl required type="text"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Adresse (Ligne 2)</mat-label>
        <input formControlName="address_line_2" matInput matNativeControl type="text"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Ville</mat-label>
        <input formControlName="city" matInput matNativeControl required type="text"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Code Postal</mat-label>
        <input formControlName="postalCode" matInput matNativeControl required type="text"/>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button type="submit">Valider</button>
    <button (click)="onNoClick()" mat-button>Annuler</button>
  </div>
</form>
