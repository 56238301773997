<mat-card *ngIf="product" class="product-card inline">
  <mat-card-content class="product-image">
    <img (error)="$event.target.src='assets/images/placeholders/produit.jpg'" [alt]="product.label"
         [attr.data-src]="product.images[0]" class="product-img lazyload blur-up" (click)="zoom()"/>
    <div class="product-link"
         *ngIf="('Project' | asyncCan:'administrate' | async) && abilitySvc.user.editMode && product.url != '' && product.url != null"
    >
      <a [href]="product.url" target="_blank"><mat-icon>link</mat-icon></a>
    </div>
    <div (click)="delete()"
         *ngIf="('Project' | asyncCan:'administrate' | async) && abilitySvc.user.editMode"
         class="product-destroy">
      <mat-icon>delete</mat-icon>
    </div>
  </mat-card-content>

  <mat-card-footer class="product-data">
    <div class="product-infos">
      <p class="product-name">{{product.label}}</p>
      <p *ngIf="product.dimensions">{{product.dimensions}}</p>
      <p *ngIf="organization.logisticianBreakdown">Livraison & Installation comprise</p>
      <p *ngIf="product.installationAt">Date d'installation prévisionnelle : {{product.installationAt}}</p>
    </div>
    <div class="product-quantity">
      <app-inline-edit #inlineQuantityField (fieldUpdate)="updateQuantity($event)" style="margin: 0 10%;"
                       [value]="product.quantity" [fieldType]="'number'" [forceEdit]="('Project' | asyncCan:'update' | async)"></app-inline-edit>
    </div>
    <div *ngIf="organization.vatPrices" [class.blurry]="!abilitySvc.user.showPrices" class="product-price">
      <p>{{product.price * 1.2 * product.quantity | currency}} TTC</p>
      <p>{{product.price * 1.2 | currency}} TTC / unité</p>
    </div>
    <div *ngIf="!organization.vatPrices" [class.blurry]="!abilitySvc.user.showPrices" class="product-price">
      <p>{{product.price * product.quantity | currency}} HT</p>
      <p>{{product.price | currency}} HT / unité</p>
    </div>
  </mat-card-footer>

</mat-card>
