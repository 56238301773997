import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Organization} from '../../_models/organization';
import {FormBuilder, Validators} from '@angular/forms';
import {OrganizationService} from '../../_services/organization.service';

@Component({
  selector: 'app-edit-organization',
  templateUrl: './edit-organization.component.html',
  styleUrls: ['./edit-organization.component.scss']
})
export class EditOrganizationComponent implements OnInit {
  organizationForm;

  constructor(public dialogRef: MatDialogRef<EditOrganizationComponent>,
              @Inject(MAT_DIALOG_DATA) public organization: Organization,
              private formBuilder: FormBuilder,
              private organizationSvc: OrganizationService) {
    this.organizationForm = this.formBuilder.group({
      id: [organization.id],
      name: [organization.name, Validators.required],
      logisticianBreakdown: [organization.logisticianBreakdown],
      vatPrices: [organization.vatPrices],
      file: [''],
      address: this.formBuilder.group({
        id: [organization.address.id],
        address_line_1: [organization.address.addressLine1, [Validators.required]],
        address_line_2: [organization.address.addressLine2],
        city: [organization.address.city, [Validators.required]],
        postalCode: [organization.address.postalCode, Validators.required],
      })
    });
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setFile(file) {
    this.organizationForm.patchValue({file});
  }

  onSubmit() {
    if (this.organizationForm.valid) {
      this.organizationSvc.update(this.organizationForm.value).subscribe((e: any) => {
        this.dialogRef.close({organization: this.organization.fromQuery(e)});
      });
    }
  }
}
