import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from './_services/auth.service';
import {User} from './_models/user';
import {AbilityService} from './_services/ability.service';
import {Project} from './_models/project';
import {NavigationEnd, Router} from '@angular/router';
import {ProjectService} from './_services/project.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {OrderService} from './_services/order.service';
import {Order} from './_models/order';

@AutoUnsubscribe()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  currentProject: Project;
  projects: Project[] = [];
  orders: Order[] = [];
  title = 'wano-front';
  projectMenu: boolean;
  private projects$;
  private orders$;
  private router$;

  constructor(public authSvc: AuthService,
              public abilitySvc: AbilityService,
              private projectSvc: ProjectService,
              private orderSvc: OrderService,
              private router: Router) {
    this.abilitySvc.project.subscribe((p) => this.currentProject = p);
  }

  ngOnInit() {
    if (this.abilitySvc.user.isAdmin()) {
      this.projects$ = this.projectSvc.getAll().refetch().then(({data}: any) => {
        this.projects = data.projects.map((p) => new Project().fromQuery(p));
      });

      this.orders$ = this.orderSvc.getAll().refetch().then(({data}: any) => {
        this.orders = data.orders.map((o) => new Order().fromQuery(o));
      });
    }

    this.router$ = this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.projectMenu = e.urlAfterRedirects.includes('project');
      }
    });
  }

  signOut() {
    this.authSvc.logout().subscribe((user: User) => {
        if (!user) {
          location.replace('/');
        }
      }
    );
  }

  ngOnDestroy(): void {
  }

  toggleUserDisplay(editMode: boolean, showPrices: boolean) {
    this.abilitySvc.setEditMode(editMode);
    this.abilitySvc.setShowPrices(showPrices);
  }

  goToHomePage(organization?: number) {
    if (organization) {
      this.router.navigate(['/project', {organization}]);
    } else {
      this.router.navigate(['/project']);
    }
  }

  selectProject(event) {
    this.abilitySvc.setProject(event.value);
    this.router.navigate(['/project/team']).then(() => {
      location.reload();
    });
  }

  selectOrder(event) {
    this.router.navigate([`/order/${event.value}`]).then(() => event.source.writeValue(null));
  }
}
