import {Injectable} from '@angular/core';
import gql from 'graphql-tag';
import {Apollo} from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private apollo: Apollo) {
  }

  get(id) {
    return this.apollo
      .watchQuery({
        query: gql`
          query project ($id: ID!) {
              project (id: $id) {
                id
                name
                publishedAt
                concept
                notes
                showQuotations
                showConcept
                showMaps
                showProducts
                logisticianPercentage
                address {
                    addressLine1
                    addressLine2
                    city
                    postalCode
                }
                mapAreas {
                  id
                  name
                  fullName
                }
                propositions {
                  id
                  name
                  ultimate
                  mapDisplay
                }
                projectUsers {
                  id
                  referent
                  subReferent
                  user {
                    id
                    completeName
                  }
                }
                organization {
                  id
                  name
                  logoPath
                  logisticianBreakdown
                  vatPrices
                  address {
                    addressLine1
                    addressLine2
                    city
                    postalCode
                  }
                }
                orders {
                  id
                  preOrder
                }
                events {
                  id
                  startDate
                  endDate
                  title
                  description
                }
              }
          }
        `
        , variables: {id}
        , fetchPolicy: 'no-cache'
      });
  }

  getAll() {
    return this.apollo
      .watchQuery({
        query: gql`
        query projects {
          projects {
            id
            name
            publishedAt
            logisticianPercentage
            showConcept
            showMaps
            showProducts
            organization {
              id
              name
            }
            address {
              addressLine1
              addressLine2
              city
              postalCode
            }
          }
        }
        `
      });
  }

  delete(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation destroyProject ($id: ID!) {
            destroyProject (id: $id) {
              id
            }
        }
      `,
      variables: {id}
    });
  }

  create(input) {
    return this.apollo.mutate({
      mutation: gql`
        mutation createProject ($input: ProjectCreateInput!) {
            createProject (input: $input) {
              id
              name
              publishedAt
              logisticianPercentage
              showConcept
              showMaps
              showProducts
              organization {
                id
                name
              }
              address {
                    addressLine1
                    addressLine2
                    city
                    postalCode
              }
            }
        }
      `,
      variables: {
        input
      }
    });
  }

  update(input) {
    return this.apollo.mutate({
      mutation: gql`
        mutation updateProject ($input: ProjectUpdateInput!) {
            updateProject (input: $input) {
                id
                name
                publishedAt
                concept
                notes
                showConcept
                showMaps
                showProducts
                logisticianPercentage
                address {
                    addressLine1
                    addressLine2
                    city
                    postalCode
                }
            }
        }
      `,
      variables: {
        input
      }
    });
  }

  publish(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation publishProject ($id: ID!) {
            publishProject (id: $id) {
              id
              publishedAt
            }
        }
      `,
      variables: {id}
    });
  }

  addProductsToUltimateProposition(id, products) {
    return this.apollo.mutate({
      mutation: gql`
        mutation addProductsToUltimatePropositionProject ($id: ID!, $products: [ID]) {
            addProductsToUltimatePropositionProject (id: $id, products: $products) {
                id
                name
                propositions {
                  id
                  name
                  ultimate
                  mapDisplay
                  quotations {
                    ambienceName
                    products {
                      id
                      label
                      price
                      sellerLabel
                      category
                      sellerReference
                      logisticianPrice
                      logisticianPercentage
                      status
                      images
                      delayPurchase
                      quantity
                      installationAt
                      dimensions
                    }
                  }
                }
            }
        }
      `,
      variables: {
        id,
        products
      }
    });
  }

}
