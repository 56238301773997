import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Proposition} from '../../../_models/proposition';
import {MatTableDataSource} from '@angular/material/table';
import {ProductOrder} from '../../../_models/product-order';
import {Product} from '../../../_models/product';
import {Quotation} from '../../../_models/quotation';
import {QuotationLogisticCsvService} from '../../../_exports/quotation-logistic-csv.service';

@Component({
  selector: 'app-quotation-summary',
  templateUrl: './quotation-summary.component.html',
  styleUrls: ['./quotation-summary.component.scss']
})
export class QuotationSummaryComponent implements OnInit {
  table: MatTableDataSource<Product> = new MatTableDataSource<Product>();
  products: Product[];
  cols: { field: string, title: string, display?: string }[] = [
    {field: 'image', title: '', display: 'image'},
    {field: 'ambienceName', title: 'Zone'},
    {field: 'label', title: 'Produit'},
    {field: 'quantity', title: 'Qté'},
    {field: 'sellerReference', title: 'Réf. Produit'},
    {field: 'sellerLabel', title: 'Marchand'},
    {field: 'installationAt', title: 'Date installation', display: 'date'},
    {field: 'rawPrice', title: 'PU HT', display: 'price'},
    {field: 'logisticianPrice', title: 'PU Log', display: 'price'},
    {field: 'total', title: 'Total HT', display: 'price'}
  ];
  colsNames = this.cols.map((e) => e.field);
  constructor(public dialogRef: MatDialogRef<QuotationSummaryComponent>,
              private quotationLogisticCsvSvc: QuotationLogisticCsvService,
              @Inject(MAT_DIALOG_DATA) public data: Proposition) { }

  ngOnInit(): void {
    if (this.data) {
      this.products = [].concat.apply([], this.data.quotations.map((e) => {
        e.products.forEach((p) => p.ambienceName = e.ambienceName);
        return e.products;
      }));
      this.sort();
    }
  }

  sort(e?) {
    this.products = this.products.sort(this.predicateFor(e && e.direction ? e : {active: 'ambienceName', direction: 'asc'}));
    this.table.data = this.products;
  }

  generateCsv() {
    this.quotationLogisticCsvSvc.generateCsv(this.data, this.products);
  }

  private predicateFor(e) {
    return e.direction === 'asc' ?
      typeof this.products[0][e.active] === 'string' ?
        (a, b) => a[e.active].localeCompare(b[e.active])
        : (a, b) => a[e.active] - b[e.active]
      : typeof this.products[0][e.active] === 'string' ?
        (a, b) => b[e.active].localeCompare(a[e.active])
        : (a, b) => b[e.active] - a[e.active];
  }

}
