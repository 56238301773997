import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Map} from '../../_models/map';
import {MapArea} from '../../_models/map-area';
import {BehaviorSubject} from 'rxjs';
import {KonvaComponent} from 'ng2-konva';
import Konva from 'konva';
import {PromptDialogComponent} from '../prompt-dialog/prompt-dialog.component';
import {MapAreaService} from '../../_services/map-area.service';
import {MatDialog} from '@angular/material/dialog';
import {AbilityService} from '../../_services/ability.service';
import {MapService} from '../../_services/map.service';
import {Router} from '@angular/router';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-map-render',
  templateUrl: './map-render.component.html',
  styleUrls: ['./map-render.component.scss']
})
export class MapRenderComponent implements OnInit {
  @ViewChild('stage') stage: KonvaComponent;
  @ViewChild('drawLayer') drawLayer: KonvaComponent;
  @ViewChild('displayLayer') displayLayer: KonvaComponent;
  @Input() map: Map;
  @Input() mapArea: MapArea;
  @Input() mapContainer: HTMLElement;
  @Input() staticDisplay = false;
  @Output() change: EventEmitter<MapArea> = new EventEmitter<MapArea>();
  @Output() delete: EventEmitter<Map> = new EventEmitter<Map>();
  @Output() changeImage: EventEmitter<Map> = new EventEmitter<Map>();

  changingImage = false;
  isDrawing = false;
  mouseDown = false;
  currentMapArea;
  currentShape;
  mapAreas: MapArea[] = [];
  ratio: number;
  image: any;
  mapId: number;
  public stageConf: BehaviorSubject<any> = new BehaviorSubject({
    width: 1000,
    height: 500,
  });
  public imageConf: EventEmitter<any> = new EventEmitter();
  private scale: number;

  constructor(private dialog: MatDialog,
              private mapAreaSvc: MapAreaService,
              private mapSvc: MapService,
              public abilitySvc: AbilityService,
              private router: Router) {
  }

  ngOnInit() {
    this.mapId = this.map.id;
    this.loadImg();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateDisplay();
  }

  loadImg() {
    this.image = new window.Image();
    this.image.src = this.map.imagePath;
    this.image.onload = () => {
      this.updateDisplay();
      this.setMapAreas();
    };
  }

  updateDisplay() {
    const newRatio = this.mapContainer.clientWidth / this.image.width;

    if (this.image.height * newRatio > window.innerHeight * 0.70) {
      this.scale = this.image.height / (window.innerHeight * 0.70) / 2;
    } else {
      this.scale = 1;
    }

    if (newRatio !== this.ratio) {
      this.ratio = newRatio;
      this.stageConf.next({
        width: this.mapContainer.clientWidth,
        height: this.image.height * this.ratio * this.scale,
        scale: ({x: this.scale, y: this.scale}),
        offsetX: (this.scale === 1 ? 0 : -this.mapContainer.clientWidth / 2)
      });

      const x = (this.mapContainer.clientWidth - this.image.width * this.ratio) / 2;
      this.imageConf.next({
        image: this.image,
        x: x > 0 ? x : 0,
        width: this.image.width * this.ratio,
        height: this.image.height * this.ratio,
      });

      this.displayLayer.getStage().getChildren(
        (e) => e.className === 'Line'
      ).each(
        (e) => e.destroy()
      );

      this.setMapAreas();
    }

  }

  onMouseDown(e) {
    this.mouseDown = true;

    if (!this.isDrawing) {
      this.startDrawing(e);
      return;
    }

    this.addNewPoints();
  }

  onMouseMove() {
    if (this.mouseDown) {
      this.addNewPoints();
    }
  }

  onMouseUp() {
    this.mouseDown = false;
  }

  updateMap(data: any) {
    this.mapSvc.update(this.mapId, data.file).subscribe((v: any) => {
      location.reload();
    });
  }

  startDrawing(e) {
    if (!this.abilitySvc.user.editMode || this.staticDisplay) {
      return;
    }
    this.currentMapArea = new MapArea();
    this.currentMapArea.ratio = {height: this.mapContainer.clientHeight, width: this.mapContainer.clientWidth};
    this.currentMapArea.map = this.map;
    this.toggleDrawing();
    this.currentShape = new Konva.Line({
        points: this.currentMapArea.coordinates,
        fill: 'black',
        closed: true,
        opacity: 0.6,
        globalCompositeOperation: 'source-out'
      }
    );
    this.drawLayer.getStage().add(this.currentShape);

    this.addNewPoints();
  }

  addNewPoints() {
    if (!this.isDrawing) {
      return;
    }
    const mousePos = this.stage.getStage().getPointerPosition();
    this.currentMapArea.addPoint(mousePos.x - this.mapContainer.clientWidth / 2 * this.scale, mousePos.y);
    this.currentShape.points(this.currentMapArea.coordinates.map((e) => e / this.scale));
    this.currentShape.draw();
  }

  setMapAreas() {
    if (this.mapArea) {
      this.addMapArea(this.mapArea);
    } else {
      this.mapAreas = this.map.mapAreas;
      this.mapAreas.forEach((mapArea) => {
        this.addMapArea(mapArea);
      });
    }
  }

  addMapArea(mapArea: MapArea) {
    const ratioX = (this.mapContainer.clientHeight / mapArea.ratio.height) * (this.staticDisplay ? 1.1 : 1);
    const shape = new Konva.Line({
      id: '' + mapArea.id,
      points: mapArea.coordinates.map((e) => e * ratioX / this.scale),
      fill: mapArea.color,
      closed: true,
    });

    shape.opacity(0.2);
    this.displayLayer.getStage().add(shape);
    this.displayLayer.getStage().draw();
  }

  finishDrawing() {
    if (!this.isDrawing) {
      return;
    }
    this.toggleDrawing();
    const dialogRef = this.dialog.open(PromptDialogComponent, {
        width: '350px',
        data: {
          message: 'Ajout d\'une zone',
          input: 'Nom',
          color: true
        }
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.currentMapArea.name = result.input;
        this.currentMapArea.color = result.color || '#FFFFFF';
        this.currentShape.destroy();
        this.drawLayer.getStage().draw();

        this.mapAreaSvc.create(this.currentMapArea).subscribe((d: any) => {
          const obj = new MapArea().fromQuery(d.data.createMapArea);
          this.mapAreas.push(obj);
          this.addMapArea(obj);
          this.change.next(obj);
        });
      } else {
        this.currentShape.destroy();
        this.drawLayer.getStage().draw();
      }
    });
  }

  deleteMap() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Voulez-vous vraiment supprimer ce plan ?'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.mapSvc.delete(this.map.id).subscribe((d: any) => {
        this.delete.next(this.map);
      });
    });
  }

  toggleDrawing() {
    this.isDrawing = !this.isDrawing;
  }
}
