import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../_models/user';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {AbilityService} from './ability.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentUser: Observable<User>;
  public currentUserSubject: BehaviorSubject<User>;

  constructor(private apollo: Apollo) {
    this.currentUserSubject = new BehaviorSubject<User>(new User().deserialize(JSON.parse(localStorage.getItem('currentUser'))));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  public save(user) {
    this.currentUserSubject.next(user);
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  login(email: string, password: string) {
    return this.apollo.mutate({
      mutation: gql`
        mutation signInUser ($email: String!, $password: String!) {
            signInUser (email: $email, password: $password) {
              id
              completeName
              email
              role
              currentProjectId
              token
            }
        }
      `,
      variables: {
        email,
        password
      }
    });
  }

  logout() {
    this.apollo.mutate({
      mutation: gql`
      mutation signOutUser {
        signOutUser {
          completeName
        }
      }
      `
    }).subscribe(result => {
      localStorage.removeItem('currentUser');
      this.currentUserSubject.next(null);
    });
    return this.currentUserSubject;
  }

  resetPassword(email) {
    return this.apollo.mutate({
      mutation: gql`
        mutation resetPasswordUser ($email: String!) {
          resetPasswordUser (email: $email) {
            id
          }
        }
      `,
      variables: {email}
    });
  }

  invitation(invitationToken) {
    return this.apollo
      .watchQuery({
        query: gql`
        query invitationUser($invitationToken: String!) {
            invitationUser(invitationToken: $invitationToken) {
              email
            }
        }
        `,
        variables: {invitationToken}
      });
  }

  ping() {
    return this.apollo
      .watchQuery({
        query: gql`
        query pingUser {
          pingUser {
            completeName
            visibleProjects {
              id
              name
            }
            role
          }
        }
        `
      });
  }

  signUp(input) {
    return this.apollo.mutate({
      mutation: gql`
        mutation signUpUser ($input: UserSignUpInput!) {
          signUpUser (input: $input) {
            id
            completeName
            email
            role
            currentProjectId
            token
          }
        }
      `,
      variables: {input}
    });
  }

  newPassword(input) {
    return this.apollo.mutate({
      mutation: gql`
        mutation newPasswordUser ($input: UserNewPasswordInput!) {
          newPasswordUser (input: $input) {
            id
            completeName
            email
            role
            currentProjectId
            token
          }
        }
      `,
      variables: {input}
    });
  }
}
