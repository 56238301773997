<app-spinner *ngIf="!quotationIsLoaded"></app-spinner>
<div fxLayout="row wrap" fxLayout.lt-sm="column">
  <app-proposition-menu (changed)="currentProposition = $event" [project]="project" [propositions]="propositions"
                        [static]="true"
                        fxFlex="100" #propositionMenu></app-proposition-menu>
  <div fxFlex="90" fxFlex.xs="100" fxFlexOffset="5" fxFlexOffset.xs="0">
    <div *ngIf="currentProposition"
         fxLayout="row wrap"
         fxLayoutAlign="center" fxLayoutGap="16px grid">
      <div *ngIf="quotationIsLoaded" class="quotation-layout" fxFlex="100" fxLayout.lt-md="column">
        <div class="ambiences" fxFlex="75" fxFlex.lt-md="100">
          <div *ngFor="let quotation of currentProposition.quotations">
            <div class="ambience-header mat-elevation-z4" *ngIf="quotation.validatedProducts.length > 0"
                 [style]="abilitySvc.user.isAdmin() && abilitySvc.user.editMode ? 'opacity: 0.8; background-color: ' + quotation.color : ''">
              <div class="ambience-name">
                <span fxFlex>{{quotation.ambienceName}}</span>
              </div>
              <div class="ambience-quantity">Quantité</div>
              <div class="ambience-price">Prix</div>
            </div>
            <app-product *ngFor="let product of quotation.validatedProducts"
                         [carousel]="false"
                         [inline]="true"
                         [organization]="project.organization"
                         [product]="product"
                         (destroy)="removeProduct(quotation, product)"
            ></app-product>
          </div>
          <div>
            <div class="ambience-header mat-elevation-z4">
              <div class="ambience-name">Produits mis de côté</div>
              <div class="ambience-quantity">Quantité</div>
              <div class="ambience-price">Prix</div>
            </div>
            <div *ngFor="let quotation of currentProposition.quotations">
              <app-product *ngFor="let product of quotation.delayedProducts"
                           [carousel]="false"
                           [inline]="true"
                           [organization]="project.organization"
                           [product]="product"
                           (destroy)="removeProduct(quotation, product)"
              ></app-product>
            </div>
          </div>
        </div>
        <div class="summary" fxFlex="20" fxFlex.lt-md="100">
          <mat-card class="mat-elevation-z4">
            <mat-card-title>Récapitulatif</mat-card-title>
            <mat-card-content>
              <app-spinner *ngIf="menuLoading"></app-spinner>
              <hr>
              <table class="summary-table">
                <tr *ngFor="let quotation of currentProposition.quotations">
                  <td>{{quotation.ambienceName}}</td>
                  <td
                    [class.blurry]="!abilitySvc.user.showPrices">{{quotation.total | vat:project.organization.vatPrices}}</td>
                  <td *ngIf="!currentProposition.ultimate && ('Project' | asyncCan:'update' | async)">
                    <button (click)="addQuotationToUltimate(quotation)"
                            color="primary" mat-icon-button fxFlex matTooltip="Ajouter la zone à la proposition finale">
                      <mat-icon>add_shopping_cart</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
              <hr>
              <div *ngIf="!project.organization.logisticianBreakdown">
                <table class="summary-table">
                  <tr>
                    <td>Sous-total</td>
                    <td
                      [class.blurry]="!abilitySvc.user.showPrices">{{quotationsTotal | vat:project.organization.vatPrices}}</td>
                  </tr>
                  <tr>
                    <td>Coût logisticien</td>
                    <td
                      [class.blurry]="!abilitySvc.user.showPrices">{{logisticianTotal | vat:project.organization.vatPrices}}</td>
                  </tr>
                </table>
                <hr>
              </div>
              <table class="summary-table">
                <tr *ngIf="!project.organization.vatPrices">
                  <td>Sous-total</td>
                  <td
                    [class.blurry]="!abilitySvc.user.showPrices">{{(project.organization.logisticianBreakdown ? quotationsTotal : quotationsTotal + logisticianTotal) | vat:false}}</td>
                </tr>
                <tr *ngIf="!project.organization.vatPrices">
                  <td>TVA</td>
                  <td
                    [class.blurry]="!abilitySvc.user.showPrices">{{(project.organization.logisticianBreakdown ? quotationsTotal : quotationsTotal + logisticianTotal) / 100 * 20 | currency}}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td
                    [class.blurry]="!abilitySvc.user.showPrices">{{(project.organization.logisticianBreakdown ? quotationsTotal : quotationsTotal + logisticianTotal) | vat: true}}</td>
                </tr>
                <tr *ngIf="project.organization.vatPrices">
                  <td>dont TVA</td>
                  <td
                    [class.blurry]="!abilitySvc.user.showPrices">{{(project.organization.logisticianBreakdown ? quotationsTotal : quotationsTotal + logisticianTotal) / 100 * 20 | currency}}</td>
                </tr>
              </table>
            </mat-card-content>
            <mat-card-actions>
              <div fxLayout="row wrap" fxLayoutAlign="center center">
                <button mat-flat-button [matMenuTriggerFor]="menu" aria-label="Export" color="accent" fxFlex="100">
                  Export
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="generatePdf()">
                    <mat-icon>picture_as_pdf</mat-icon>
                    <span>PDF</span>
                  </button>
                  <button mat-menu-item (click)="generateCsv()" *ngIf="('Project' | asyncCan:'administrate' | async) && abilitySvc.user.editMode">
                    <mat-icon>table_view</mat-icon>
                    <span>CSV</span>
                  </button>
                  <button mat-menu-item (click)="showSummary()"
                          *ngIf="quotationsTotal> 0 && ('Project' | asyncCan:'administrate' | async) && abilitySvc.user.editMode">
                    <mat-icon>widgets</mat-icon>
                    <span>Logistique</span>
                  </button>
                </mat-menu>
                <button (click)="confirmOrder()" *ngIf="currentProposition.ultimate && quotationsTotal > 0 && ('Project' | asyncCan:'update' | async)"
                        class="pull-right confirm-button" color="primary" mat-flat-button fxFlex="100">
                  Valider ce devis
                </button>
                <button (click)="addPropositionToUltimate(currentProposition)" *ngIf="!currentProposition.ultimate && quotationsTotal > 0 && ('Project' | asyncCan:'update' | async)"
                        class="pull-right confirm-button" color="primary" mat-flat-button fxFlex="100">
                  Ajouter cette proposition à la proposition finale
                </button>
              </div>
            </mat-card-actions>

          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
