import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Product} from '../../_models/product';
import {Cluster} from '../../_models/cluster';
import {ClusterService} from '../../_services/cluster.service';
import {AbilityService} from '../../_services/ability.service';
import {ProductService} from '../../_services/product.service';
import {DragulaService} from 'ng2-dragula';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-cluster',
  templateUrl: './cluster.component.html',
  styleUrls: ['./cluster.component.scss']
})
export class ClusterComponent implements OnInit, OnDestroy {
  @Input() cluster: Cluster;
  @Input() collapsed: boolean;
  @Output() selected: EventEmitter<Cluster> = new EventEmitter();
  @Output() deleted: EventEmitter<boolean> = new EventEmitter();
  @Output() updated: EventEmitter<Cluster> = new EventEmitter();
  displayProduct: Product;
  dragProduct: string;
  private dragulaHandler$;
  private dragulaGroup$;

  constructor(private clusterSvc: ClusterService,
              public abilitySvc: AbilityService,
              private productSvc: ProductService,
              private dragulaSvc: DragulaService) {
    this.dragProduct = Math.random().toString(36).substring(7);
    this.dragulaHandler$ = this.dragulaSvc.dropModel(this.dragProduct).subscribe(({item, targetIndex}) => {
      if (item && this.cluster.products[targetIndex]) {
        this.productSvc.setPosition(item.id, parseInt(String(this.cluster.products[targetIndex].position), 10)).subscribe((e: any) => {
          e.data.setPositionProduct.products.forEach((product) =>
            this.cluster.products.find((p) => p.id === product.id).position = product.position
          );
        });
      }
    });

    this.dragulaGroup$ = this.dragulaSvc.createGroup(this.dragProduct, {
      moves: (el: any): any => !el.classList.contains('no-drag')
    });
  }

  ngOnInit(): void {
    this.setDisplayProduct();
  }

  setSelectedProduct(product) {
    this.clusterSvc.setProduct(this.cluster.id, product.id).subscribe((e: any) => {
      this.cluster.fromQuery(e.data.setProductCluster);
    });
  }

  updateProduct(product) {
    const idx = this.cluster.products.findIndex((p) => p.id === product.id);
    this.cluster.products[idx] = product;
    this.cluster.suggestion = product.suggestion;
    this.setDisplayProduct();
  }

  setDisplayProduct() {
    this.displayProduct = this.cluster.products.filter((p: Product) => p.id === this.cluster.selectedProduct.id)[0];

    if (!this.displayProduct) {
      this.displayProduct = this.cluster.products[0];
    }

    // Empty cluster
    if (this.cluster.products.length === 0) {
      this.deleted.emit(true);
    } else {
      this.updated.emit(this.cluster);
    }
  }

  removeProduct(product: Product) {
    this.cluster.products.splice(this.cluster.products.indexOf(product), 1);
    this.setDisplayProduct();
  }

  ngOnDestroy(): void {
  }

}
