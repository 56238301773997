<div>
  <div *ngIf="(abilitySvc.user.editMode || forceEdit) && !static; else displayPrice">
    <mat-form-field>
      <mat-label *ngIf="field">{{field}}</mat-label>
      <input #input (input)="save(input.value)" [required]="required" [value]="value" [type]="fieldType" matInput *ngIf="!textarea">
      <textarea #textarea (input)="save(textarea.value)" [required]="required" [value]="value" matInput type="text" rows="4" *ngIf="textarea"></textarea>
      <mat-icon [class.spin]="saving"
                [color]="saved ? 'accent' : 'warn'" matSuffix>
        {{saving ? 'refresh' : 'save'}}
      </mat-icon>
      <mat-icon [color]="history.size > 1 ? null : 'accent'"
                [matMenuTriggerFor]="historyMenu"
                class="history-button"
                matSuffix>
        history
      </mat-icon>
      <mat-menu #historyMenu="matMenu">
        <button (click)="setValue(item.value)"
                *ngFor="let item of history | keyvalue"
                mat-menu-item
        >{{item.key | date:'short'}} - {{item.value}}</button>
      </mat-menu>
    </mat-form-field>
  </div>
  <ng-template #displayPrice>
    {{display || value}}
  </ng-template>
</div>
