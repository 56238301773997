import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class ProjectUserService {

  constructor(private apollo: Apollo) {
  }

  get(id) {
    return this.apollo
      .watchQuery({
        query: gql`
          query project ($id: ID!) {
              project (id: $id) {
                  id
                  notes
                  projectUsers {
                      id
                      referent
                      subReferent
                      user {
                          id
                          completeName
                          email
                          lastSeenAt
                      }
                  }
              }
          }
        `,
        variables: {
          id
        }
      });
  }

  setReferent(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation setReferentProjectUser ($id: ID!) {
            setReferentProjectUser (id: $id) {
              id
              referent
              subReferent
            }
        }
      `,
      variables: {
        id
      }
    });
  }

  setSubReferent(id, subReferent) {
    return this.apollo.mutate({
      mutation: gql`
        mutation setSubReferentProjectUser ($id: ID!, $subReferent: Boolean!) {
            setSubReferentProjectUser (id: $id, subReferent: $subReferent) {
              id
              referent
              subReferent
            }
        }
      `,
      variables: {
        id,
        subReferent
      }
    });
  }

  create(input) {
    return this.apollo.mutate({
      mutation: gql`
        mutation createProjectUser ($input: ProjectUserCreateInput!) {
            createProjectUser (input: $input) {
              id
              referent
              subReferent
              user {
                id
                completeName
                email
              }
            }
        }
      `,
      variables: {
        input
      }
    });
  }

  deleteUser(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation destroyProjectUser ($id: ID!) {
          destroyProjectUser (id: $id)
        }
      `,
      variables: {
        id
      }
    });
  }
}
