<form [formGroup]="form">
  <div mat-dialog-content>
    <p>
      Sélectionnez un nom de proposition dans la liste déroulante <b>ou</b> saisissez un nom
    </p>
    <mat-form-field>
      <mat-label>Zones</mat-label>
      <mat-select formControlName="select" id="select">
        <mat-option value="">Choisir une zone</mat-option>
        <mat-option
          *ngFor="let option of values" [value]="option">
          {{option}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="form.value.select === ''">
      <label><b>ou</b> nommez une zone</label>
      <input formControlName="input" id="input" matInput type="text"/>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button (click)="onNoClick()" mat-button>Annuler</button>
    <button (click)="onSubmit(form.value)" mat-button type="submit">Valider</button>
  </div>
</form>
