<form (ngSubmit)="onSubmit()" [formGroup]="projectForm">
  <div mat-dialog-content>
    <div>
      <mat-form-field>
        <mat-label>Organisation</mat-label>
        <input matInput matNativeControl [value]="project.organization.name" disabled type="text"/>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Nom</mat-label>
        <input autofocus formControlName="name" matInput matNativeControl required="true" type="text"/>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Pourcentage logistique</mat-label>
        <input autofocus formControlName="logisticianPercentage" matInput matNativeControl required="true" type="number"/>
        <mat-hint>Modifier cette valeur modifiera tous les produits du projet</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="none">
        <input matInput style="display: none">
        <mat-checkbox color="primary" formControlName="showConcept">Afficher la partie "Concept"</mat-checkbox>
      </mat-form-field>

      <mat-form-field appearance="none">
        <input matInput style="display: none">
        <mat-checkbox color="primary" formControlName="showMaps">Afficher la partie "Plan"</mat-checkbox>
      </mat-form-field>

      <mat-form-field appearance="none">
        <input matInput style="display: none">
        <mat-checkbox color="primary" formControlName="showProducts">Afficher les parties "Produits"</mat-checkbox>
      </mat-form-field>
    </div>
    <span class="expand-spacer"></span>
    <div formGroupName="address">
      <div>
        <mat-form-field>
          <mat-label>Adresse de livraison (Ligne 1)</mat-label>
          <input formControlName="address_line_1" matInput matNativeControl type="text"/>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Adresse de livraison (Ligne 2)</mat-label>
          <input formControlName="address_line_2" matInput matNativeControl type="text"/>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Ville de livraison</mat-label>
          <input formControlName="city" matInput matNativeControl type="text"/>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Code Postal de livraison</mat-label>
          <input formControlName="postalCode" matInput matNativeControl type="text"/>
        </mat-form-field>
      </div>
    </div>

  </div>
  <div mat-dialog-actions>
    <button mat-button type="submit">Valider</button>
    <button (click)="onNoClick()" mat-button>Annuler</button>
  </div>
</form>
