import {Base} from './base';
import {Project} from './project';
import {Product} from './product';

export class User extends Base {
  id: number;
  completeName: string;
  email: string;
  token: string;
  role: string;
  lastSeenAt: string;
  currentProject: number;
  editMode = false;
  showPrices = true;
  visibleProjects: Project[];

  isAdmin(): boolean {
    return this.role === 'admin';
  }

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  fromQuery(response: any) {
    Object.assign(this, response);
    this.currentProject = response.currentProjectId ? response.currentProjectId : this.currentProject;
    this.completeName = response.completeName ? response.completeName.trim() : null;
    this.assign(response, 'visibleProjects', 'visibleProjects', Project);
    return this;
  }
}
