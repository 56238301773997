import {Map} from './map';
import {Ambience} from './ambience';
import {Base} from './base';
import {Quotation} from './quotation';

export class Proposition extends Base {
  id: number;
  name: string;
  ultimate: boolean;
  mapDisplay: boolean;
  maps: Map[];
  map: Map;
  ambiences: Ambience[] = [];
  quotations: Quotation[] = [];

  get slug() {
    return this.name.replace(/[A-Z][a-z]*/g, (match) => `${match.toLowerCase()}-`);
  }

  fromQuery(response: any) {
    Object.assign(this, response);
    this.assign(response, 'maps', 'maps', Map);
    this.assign(response, 'ambiences', 'ambiences', Ambience);
    this.assign(response, 'quotations', 'quotations', Quotation);
    this.sort('ambiences', (a, b) => a.name.localeCompare(b.name));
    this.sort('quotations', (a, b) => a.ambienceName.localeCompare(b.ambienceName));
    if (this.maps) {
      this.map = this.maps[0];
    }
    return this;
  }
}
