<div mat-dialog-content>
  <app-spinner *ngIf="!data"></app-spinner>
  <button mat-menu-item (click)="generateCsv()">
    <mat-icon>table_view</mat-icon>
    <span>CSV</span>
  </button>
  <mat-table [dataSource]="table"
             mat-table
             matSort
             (matSortChange)="sort($event)"
             style="width: 100%">
    <ng-container *ngFor="let col of cols" [matColumnDef]="col.field">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{col.title}}</th>
      <td *matCellDef="let element"
          mat-cell>
        <span *ngIf="col.display === undefined">{{element[col.field]}}</span>
        <span *ngIf="col.display === 'boolean'"><mat-icon>{{element[col.field] ? 'done' : 'clear'}}</mat-icon></span>
        <span *ngIf="col.display === 'date'">{{element[col.field] | date}}</span>
        <span *ngIf="col.display === 'image'"><img class="image-thumb mat-elevation-z1"
                                                   (error)="$event.target.src='assets/images/placeholders/produit.jpg'"
                                                   [src]="element[col.field]"></span>
        <span *ngIf="col.display === 'price'">{{element[col.field] | currency}}</span>
      </td>
    </ng-container>
    <tr *matHeaderRowDef="colsNames; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: colsNames;" mat-row></tr>
    {{colsNames}}
  </mat-table>
</div>
