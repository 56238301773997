import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(private apollo: Apollo) {
  }

  get(id) {
    return this.apollo
      .watchQuery({
        query: gql`
        query project ($id: ID!) {
            project (id: $id) {
                id
                propositions {
                  id
                  name
                  ultimate
                  mapDisplay
                  maps {
                    id
                    imagePath
                    mapAreas {
                        id
                        name
                        coordinates
                        color
                        images
                    }
                  }
                }
            }
        }
        `,
        variables: {
          id
        }
      });
  }

  create(propositionId, file) {
    return this.apollo.mutate({
      mutation: gql`
        mutation createMap ($propositionId: ID!, $file: String!) {
            createMap (propositionId: $propositionId, file: $file) {
              id
              imagePath
            }
        }
      `,
      variables: {
        propositionId,
        file
      }
    });
  }

  update(mapId, file) {
    return this.apollo.mutate({
      mutation: gql`
        mutation updateMap ($mapId: ID!, $file: String!) {
            updateMap (mapId: $mapId, file: $file) {
              id
              imagePath
            }
        }
      `,
      variables: {
        mapId,
        file
      }
    });
  }

  delete(mapId) {
    return this.apollo.mutate({
      mutation: gql`
        mutation destroyMap($mapId: ID!) {
          destroyMap(mapId: $mapId)
        }
      `,
      variables: {
        mapId
      }
    });
  }
}
