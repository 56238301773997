<div *ngIf="abilitySvc.user.isAdmin()" [class.hidden]="!abilitySvc.user.editMode">
  <editor
    [init]="tinyMCEInit"
    [initialValue]="content"
    apiKey="brxyfuc7zyl2hzlcsj3z9v6ohyayfotqn4gibkofevgmzjr4"
    [class]="small ? 'editor--small' : 'editor'"
  >
  </editor>
</div>

<div *ngIf="!abilitySvc.user.editMode"
     [innerHTML]="content | safe:'html'" class="concept-content" fxLayout="column" fxLayoutAlign="center center">
</div>
