<div class="carousel">
  <ng-container *ngFor="let image of images; let i = index">
    <img
      (error)="$event.target.src='assets/images/placeholders/produit.jpg'"
      *ngIf="currentSlide == i"
      [attr.data-src]="image"
      (click)="zoom()"
      [class.zoom]="zoomOnClick"
      class="slide lazyload blur-up"
    />
    <button (click)="deleteElement(i, image)" *ngIf="showDelete && currentSlide == i" class="delete-button" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </ng-container>

  <div *ngIf="images.length > 1">
    <button (click)="onPreviousClick()" class="control prev">
      <span class="arrow left"></span>
    </button>
    <button (click)="onNextClick()" class="control next">
      <span class="arrow right"></span>
    </button>
  </div>
</div>
