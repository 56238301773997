import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Product} from '../../_models/product';
import {Organization} from '../../_models/organization';
import {ProductService} from '../../_services/product.service';
import {AbilityService} from '../../_services/ability.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {EditProductComponent} from '../../products/edit-product/edit-product.component';
import {CarouselModalComponent} from '../carousel/carousel-modal/carousel-modal.component';
import {ProductOrderComponent} from '../../orders/product-order/product-order.component';
import {ProductOrderService} from '../../_services/product-order.service';
import {OrderService} from '../../_services/order.service';

@Component({
  selector: 'app-inline-product-order',
  templateUrl: './inline-product-order.component.html',
  styleUrls: ['./inline-product-order.component.scss']
})
export class InlineProductOrderComponent implements OnInit {
  @Input() product: Product;
  @Input() organization: Organization;
  @Output() update = new EventEmitter();
  @Output() destroy = new EventEmitter();

  @ViewChild('labelField') labelField;
  @ViewChild('priceField') priceField;
  @ViewChild('quantityField') quantityField;

  @ViewChild('inlineQuantityField') inlineQuantityField;

  constructor(private productOrderSvc: ProductOrderService,
              public abilitySvc: AbilityService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  updateQuantity(quantity) {
    this.productOrderSvc.update({
      id: this.product.id,
      quantity: parseInt(quantity, 10)
    }).subscribe((p: any) => {
      this.updateProduct(p.data.updateProductsOrder);
      if (this.quantityField) {
        this.quantityField.hasSaved();
      }
      if (this.inlineQuantityField) {
        this.inlineQuantityField.hasSaved();
      }
    });
  }

  delete() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Voulez-vous vraiment supprimer ce produit ?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.productOrderSvc.destroy(this.product.id).subscribe(() => {
          delete this.product;
          this.destroy.emit(true);
        });
      }
    });
  }

  zoom() {
    this.dialog.open(CarouselModalComponent, {width: '90vw', data: [this.product.images[0]]});
  }

  private updateProduct(data) {
    this.product.fromQuery(data);
    this.update.next(this.product);
  }

}
