<app-spinner *ngIf="!mapIsLoaded"></app-spinner>
<app-proposition-menu (changed)="currentProposition = $event" [project]="project"
                      [propositions]="propositions" [currentProposition]="currentProposition" [mapDisplay]="true"></app-proposition-menu>
<div *ngFor="let proposition of project.propositions">
  <div *ngIf="proposition === currentProposition" fxLayout="row wrap">
    <div *ngIf="proposition.map" fxFlex="90" fxFlex.xs="100" fxFlexOffset="5" fxFlexOffset.xs="0">
      <app-map-areas (change)="updateMap(proposition, $event)" (selected)="scrollToMapArea($event)" (deleteMap)="deleteMap($event)"
                     [map]="proposition.map"></app-map-areas>
    </div>

    <div *ngIf="proposition.map && proposition.map.mapAreas" fxFlex="90" fxFlex.xs="100" fxFlexOffset="5"
         fxFlexOffset.xs="0">
      <app-map-areas (delete)="deleteMapArea(mapArea, proposition.map)" *ngFor="let mapArea of proposition.map.mapAreas"
                     [id]="'map-area-' + mapArea.id"
                     [mapArea]="mapArea"
                     [map]="proposition.map"
                     class="map-area-scroll" style="margin-top: 8px;"></app-map-areas>
    </div>

    <div *ngIf="abilitySvc.user.editMode && !proposition.map" fxFlex="90" fxFlex.xs="100" fxFlexOffset="5"
         fxFlexOffset.xs="0">
      <mat-card>
        <mat-card-content>
          <app-spinner *ngIf="!mapUploaded"></app-spinner>
          <h2>Ajouter un plan</h2>
          <div class="file-select-button">
            <app-file-select (fileSubmited)="createMap($event)"></app-file-select>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
