import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private apollo: Apollo) {
  }

  create(input) {
    return this.apollo.mutate({
      mutation: gql`
        mutation createProduct ($input: ProductCreateInput!) {
            createProduct (input: $input) {
                id
                name
                position
                suggestion
                product {
                  id
                }
                products {
                    id
                    label
                    quantity
                    logisticianPrice
                    logisticianPercentage
                    brand
                    sellerLabel
                    sellerReference
                    url
                    width
                    height
                    depth
                    price
                    status
                    images
                    dimensions
                    usersVotes
                    category
                    position
                }
            }
        }
      `,
      variables: {
        input
      }
    });
  }

  update(input) {
    return this.apollo.mutate({
      mutation: gql`
        mutation updateProduct ($input: ProductUpdateInput!) {
            updateProduct (input: $input) {
              id
              label
              quantity
              logisticianPrice
              logisticianPercentage
              brand
              sellerLabel
              sellerReference
              delayPurchase
              url
              width
              height
              depth
              price
              status
              images
              suggestion
              dimensions
              usersVotes
              category
              position
            }
        }
      `,
      variables: {input}
    });
  }

  vote(id, vote) {
    return this.apollo.mutate({
      mutation: gql`
        mutation voteProduct ($id: ID!, $vote: Boolean!) {
            voteProduct (id: $id, vote: $vote) {
              id
              usersVotes
            }
        }
      `,
      variables: {
        id, vote
      }
    });
  }

  order_again(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation orderAgainProduct ($id: ID!) {
            orderAgainProduct (id: $id) {
              id
            }
        }
      `,
      variables: {
        id
      }
    });
  }

  destroy(id) {
    return this.apollo.mutate({
      mutation: gql`
        mutation destroyProduct ($id: ID!) {
            destroyProduct (id: $id) {
              id
            }
        }
      `,
      variables: {
        id
      }
    });
  }

  setPosition(id, position) {
    return this.apollo.mutate({
      mutation: gql`
        mutation setPositionProduct ($id: ID!, $position: Int!) {
            setPositionProduct (id: $id, position: $position) {
              products {
                id
                position
              }
            }
        }
      `,
      variables: {
        id, position
      }
    });
  }
}
