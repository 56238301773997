<app-spinner *ngIf="!ordersIsLoaded"></app-spinner>
<div *ngFor="let order of orders" class="order-layout">
  <div class="ambiences">
    <div *ngFor="let quotation of order.quotations">
      <div class="ambience-header mat-elevation-z4">
        <div class="ambience-name">{{quotation.ambienceName}}</div>
        <div class="ambience-quantity">Quantité</div>
        <div class="ambience-price">Prix</div>
      </div>
      <app-product *ngFor="let product of quotation.products"
                   [carousel]="false"
                   [inline]="true"
                   [organization]="project.organization"
                   [product]="product"
                   [static]="true"
      ></app-product>
    </div>
  </div>
  <div class="summary">
    <mat-card class="mat-elevation-z4">
      <mat-card-title>Commande du {{order.validatedAt | date}}</mat-card-title>
      <mat-card-content>
        <app-spinner *ngIf="menuLoading"></app-spinner>
        <hr>
        <table class="summary-table">
          <tr *ngFor="let quotation of order.quotations">
            <td>{{quotation.ambienceName}}</td>
            <td
              [class.blurry]="!abilitySvc.user.showPrices">{{quotation.total | vat:project.organization.vatPrices}}</td>
          </tr>
        </table>
        <hr>
        <div *ngIf="!project.organization.logisticianBreakdown">
          <table class="summary-table">
            <tr>
              <td>Sous-total</td>
              <td
                [class.blurry]="!abilitySvc.user.showPrices">{{quotationsTotal(order.quotations) | vat:project.organization.vatPrices}}</td>
            </tr>
            <tr>
              <td>Coût logisticien</td>
              <td
                [class.blurry]="!abilitySvc.user.showPrices">{{logisticianTotal(order.quotations) | vat:project.organization.vatPrices}}</td>
            </tr>
          </table>
          <hr>
        </div>
        <table class="summary-table">
          <tr *ngIf="!project.organization.vatPrices">
            <td>Sous-total</td>
            <td
              [class.blurry]="!abilitySvc.user.showPrices">{{(project.organization.logisticianBreakdown ? quotationsTotal(order.quotations) : quotationsTotal(order.quotations) + logisticianTotal(order.quotations)) | vat:false}}</td>
          </tr>
          <tr *ngIf="!project.organization.vatPrices">
            <td>TVA</td>
            <td
              [class.blurry]="!abilitySvc.user.showPrices">{{(project.organization.logisticianBreakdown ? quotationsTotal(order.quotations) : quotationsTotal(order.quotations) + logisticianTotal(order.quotations)) / 100 * 20 | currency}}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td
              [class.blurry]="!abilitySvc.user.showPrices">{{(project.organization.logisticianBreakdown ? quotationsTotal(order.quotations) : quotationsTotal(order.quotations) + logisticianTotal(order.quotations)) | vat: true}}</td>
          </tr>
          <tr *ngIf="project.organization.vatPrices">
            <td>dont TVA</td>
            <td
              [class.blurry]="!abilitySvc.user.showPrices">{{(project.organization.logisticianBreakdown ? quotationsTotal(order.quotations) : quotationsTotal(order.quotations) + logisticianTotal(order.quotations)) / 100 * 20 | currency}}</td>
          </tr>
        </table>
      </mat-card-content>
      <mat-card-actions>
        <div fxLayout="row wrap" fxLayoutGap="8px" fxLayoutAlign="center center">
          <button (click)="generatePdf(order)" color="accent" mat-flat-button>
            PDF
          </button>
          <button (click)="generateCsv(order.quotations)" color="accent" mat-flat-button>CSV</button>
          <button (click)="orderAgain(order)" color="accent" mat-flat-button>
            Commander à nouveau
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
