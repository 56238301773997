import {Pipe, PipeTransform} from '@angular/core';
import {AbilityService} from '../_services/ability.service';
import {Observable} from 'rxjs';

@Pipe({
  name: 'asyncCan'
})
export class AsyncCanPipe implements PipeTransform {

  constructor(public abilitySvc: AbilityService) {
  }

  transform(value: string, action: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.abilitySvc.ability.subscribe((ability) => {
        observer.next(ability.can(action, value));
        observer.complete();
      });
    });
  }

}
