import {Product} from './product';
import {memoize} from '../_decorators/memoize';
import {Base} from './base';

export class Quotation extends Base {
  ambienceName: string;
  products: Product[];

  get total() {
    return this._total(this.validatedProducts);
  }

  get logisticianTotal() {
    return this._logisticianTotal(this.validatedProducts);
  }

  get delayedProducts() {
    return this.products.filter((p) => p.delayPurchase);
  }

  get validatedProducts() {
    return this.products.filter((p) => !p.delayPurchase);
  }

  fromQuery(response: any) {
    Object.assign(this, response);
    this.assign(response, 'products', 'products', Product);
    return this;
  }

  @memoize()
  private _total(products: Product[]) {
    return products.reduce((a, b) => a + b.price * b.quantity, 0);
  }

  @memoize()
  private _logisticianTotal(products: Product[]) {
    return products.reduce((a, b) => a + b.logisticianPrice * b.quantity, 0);
  }


  /* tslint:disable:no-bitwise */
  get color() {
    let hash = 0;
    for (let i = 0; i < this.ambienceName.length; i++) {
      hash = this.ambienceName.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }
  /* tslint:enable:no-bitwise */
}
