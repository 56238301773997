import {Product} from './product';

export class ProductOrder extends Product {
  static STATUS = {
    validated: 'Validé',
    quotation: 'Devis demandé',
    commanded: 'Commandé',
    sent_logistician: 'Envoyé Log.',
    received_logistician: 'Reçu Log.',
    sent_customer: 'Envoyé client',
    received_customer: 'Reçu client',
    setup_customer: 'Installation client',
    completed: 'Terminé',
    contact_css: 'SAV demandé',
    processing_css: 'SAV en cours',
    validated_css: 'SAV accepté',
    refused_css: 'SAV refusé',
    asset_supplier_css: 'SAV avoir fourn.',
    asset_customer_css: 'SAV avoir client',
    canceled: 'Annulé'
  };
  id: number;
  ambienceName: string;
  sellerReference: string;
  orderReference: string;
  invoiceReference: string;
  trackingReference: string;
  sellerLabel: string;
  bapId: string;
  status: string;
  logisticianName: string;
  deliveryDate: string;
  unitPrice: number;
  internalUnitPrice: number;
  logisticianUnitPrice: number;
  internalLogisticianPrice: number;
  ecotax: number;
  deliveryPrice: number;
  comment: string;

  get image() {
    return this.images[0];
  }

  get customerPrice() {
    return (this.unitPrice + this.logisticianUnitPrice) * this.quantity;
  }

  get wanoPrice() {
    return (this.internalUnitPrice + this.internalLogisticianPrice) * this.quantity + this.deliveryPrice;
  }

  get humanStatus() {
    return ProductOrder.STATUS[this.status];
  }

  get priceWarning() {
    return this.wanoPrice > this.customerPrice;
  }

  fromQuery(response: any): this {
    Object.assign(this, response);
    return this;
  }
}
